import React, { useState, useEffect } from "react";
import config from "../../../../config/config.json";
import { apiCall } from "../../../../_services/apiCall";
import * as XLSX from "xlsx";
import { Button, Form, Modal } from "react-bootstrap";

const dateMap = {
  "2024-04": "Apr 2024",
  "2024-05": "May 2024",
  "2024-06": "Jun 2024",
  "2024-07": "Jul 2024",
  "2024-08": "Aug 2024",
  "2024-09": "Sep 2024",
  "2024-10": "Oct 2024",
  "2024-11": "Nov 2024",
  "2024-12": "Dec 2024",
  "2025-01": "Jan 2025",
  "2025-02": "Feb 2025",
  "2025-03": "Mar 2025",
};

const formatValues = (data) => {
  if (Array.isArray(data)) {
    return data
      .map((item) => {
        if (Array.isArray(item)) {
          return formatValues(item);
        } else if (item === null || item === undefined || item === "") {
          return "-";
        } else {
          return String(item).replace(/<br>/g, "\n"); // Replace <br> with \n
        }
      })
      .join("\n"); // Join with new line
  } else if (typeof data === "object") {
    if (data.hasOwnProperty("readingValue")) {
      return String(data.readingValue).replace(/<br>/g, "\n");
    }
  }
  return "-";
};


const downloadExcel = (allAnswers, columns, sourceLabels, allQuestions) => {
  const workbook = XLSX.utils.book_new(); // Create a new workbook

  const groupedBySource = allAnswers.reduce((acc, item) => {
    let sourceId = item.sourceId;

    if(item.subLocationId){      
      sourceId = `${item.sourceId}-${item.subLocationId}`;
    }
    if (!acc[sourceId]) {
      acc[sourceId] = [];
    }
    acc[sourceId].push(item);
    return acc;
  }, {});
  const sourceIds = sourceLabels.map(label => label.id);
  console.log(groupedBySource,sourceIds)

  const filteredGroupedBySource = Object.keys(groupedBySource)
    .filter((sourceId) => sourceIds.includes(sourceId))
    .reduce((acc, sourceId) => {
      acc[sourceId] = groupedBySource[sourceId];
      return acc;
    }, {});

  Object.keys(filteredGroupedBySource).forEach((sourceId) => {
    const sourceData = filteredGroupedBySource[sourceId];
console.log(sourceData)
    const sheetData = allQuestions.flatMap((question) => {
      const row = { Question: question?.title.replace(/<br>/g, "\n") || "-" }; // Question title

      if (question?.questionType === 'tabular_question') {
        return question.details
          .filter(detail => detail.option_type === "column")
          .reverse()
          .map((detail, i) => {
            const row = { 
              Question: `${question?.title.replace(/<br>/g, "\n")} - ${detail.option}` || "-" 
            };

            columns.forEach((col) => {
              const datetime = Object.keys(dateMap).find(k => dateMap[k] === col);
              let answer = sourceData.find(item => item?.questionId === question.questionId && datetime === item.fromDate);

              // if(item?.subLocationId){
              //   let answer = sourceData.find(item => item?.questionId === question.questionId && datetime === item.fromDate && item.subLocationId == sourceIds);

              // }
              const parsedAnswer = answer?.answer ? JSON.parse(answer.answer) : undefined;

              row[col] = parsedAnswer?.[0]?.[i] || "No Data";
            });

            return row;
          });
      } else {
        columns.forEach((col) => {
          const datetime = Object.keys(dateMap).find(k => dateMap[k] === col);
          const answer = sourceData.find(item => item?.questionId === question.questionId && datetime === item.fromDate);

          let value = "No Answer";

          if (answer?.notApplicable) {
            value = "NA";
          } else if (question?.questionType === "yes_no") {

            try {
              value = JSON.parse(answer?.answer).answer || "Invalid";
              console.log(value)
            } catch {
              value = "Invalid";
            }
          } else if (answer) {
            const parsedAnswer = answer?.answer ? JSON.parse(answer.answer) : undefined;
            value = formatValues(parsedAnswer);
          }

          row[col] = value;
        });

        return row;
      }
    });

    const worksheet = XLSX.utils.json_to_sheet(sheetData);
    worksheet['!cols'] = [{ width: 50 }, ...columns.map(() => ({ width: 15 }))];

    const range = XLSX.utils.decode_range(worksheet['!ref']);

    for (let col = range.s.c; col <= range.e.c; col++) {
      const cellAddress = XLSX.utils.encode_cell({ r: 0, c: col });
      if (!worksheet[cellAddress]) continue;

      worksheet[cellAddress].s = {
        font: { bold: true, color: { rgb: "FFFFFF" } }, // White text color
        fill: {
          patternType: "solid",
          fgColor: { rgb: "00B050" } // Green background color
        },
        alignment: { horizontal: "center", vertical: "center" } // Center alignment
      };
    }
console.log(sourceLabels)
    const sourceLabel = sourceLabels.find(({ id }) => id === sourceId)?.label;
    XLSX.utils.book_append_sheet(workbook, worksheet, sourceLabel);
  });

  XLSX.writeFile(workbook, "all_locations_data.xlsx");
};

const Excel = ({ data, allQuestions, allAnswers, sourceLabels,selectedModuleName }) => {
  const [processedData, setProcessedData] = useState([]);
  const [columns, setColumns] = useState([]);
  const [groupedModules, setGroupedModules] = useState({}); // State to store grouped modules
  const [showModal, setShowModal] = useState(false);
  const [selectedSources, setSelectedSources] = useState([]);
  const [selectedColumns, setSelectedColumns] = useState([]);

  const handleDownload = () => {
    downloadExcel(allAnswers, selectedColumns, selectedSources, allQuestions);
    setShowModal(false);
  };
  const fixedColumns = [
    "Apr 2024", "May 2024", "Jun 2024", "Jul 2024", "Aug 2024", "Sep 2024",
    "Oct 2024", "Nov 2024", "Dec 2024", "Jan 2025", "Feb 2025", "Mar 2025"
  ];


const formatValue = (data) => {
  if (Array.isArray(data)) {
    return data
      .map((item) => {
        if (Array.isArray(item)) {
          return formatValue(item);
        } else if (item === null || item === undefined || item === "") {
          return "-";
        } else {
          const parsed = parseFloat(item);
          return item;
        }
      })
      .join("<br>");
  } else if (typeof data === "object") {
    if (data.hasOwnProperty("readingValue")) {
      const value =
        typeof data.readingValue === "string"
          ? data.readingValue.replace(/,/g, "")
          : String(data.readingValue).replace(/,/g, "");
      return data.readingValue;
    }
  }
  return "-";
};

  useEffect(() => {
    const processData = () => {
      const newData = [];

      allQuestions.forEach((question) => {
        const { questionId, title, moduleName, questionType } = question;
        const questionData = {
          questionId,
          title,
          moduleName,
          values: {},
        };

        fixedColumns.forEach((col) => {
          questionData.values[col] = "No Answer";
        });

        data.forEach((item) => {
          if (item?.questionId === questionId) {
            const { fromDate, notApplicable, answer } = item;
            let value = "No Answer";

            if (notApplicable) {
              value = "NA";
            } else if (questionType === "yes_no") {
             
              try {
                value = JSON.parse(answer).answer || "Invalid";
              } catch {
                value = "Invalid";
              }
            } else if (answer) {
              const parsedAnswer = JSON.parse(answer);
              value = formatValue(parsedAnswer);
            }

            const dateKey = dateMap[fromDate]; // Convert "04-2024" → "Apr 2024"
            if (dateKey) {
              questionData.values[dateKey] = value;
            }
          }
        });

        newData.push(questionData);
      });

      setProcessedData(newData);
      setColumns(fixedColumns); // Use fixed date columns
    };

    processData();
  }, [data, allQuestions]);

  useEffect(() => {
    const groupQuestionsByModule = () => {
      const moduleGroups = {};

      processedData.forEach((questionData) => {
        const { moduleName, questionId, title, values } = questionData;

        if (!moduleGroups[moduleName]) {
          moduleGroups[moduleName] = [];
        }

        moduleGroups[moduleName].push({
          questionId,
          title,
          values,
        });
      });

      setGroupedModules(moduleGroups); // Store the grouped data
    };

    groupQuestionsByModule();
  }, [processedData]);


  const validateAllLocationAnswers = async () => {
  
      const { isSuccess, data } = await apiCall(
        `${config.POSTLOGIN_API_URL_COMPANY}validateAllLocationAnswers`,
        {},
        { financialYearId: 30,locationId:selectedModuleName },
        "POST"
      );
      if (isSuccess) {
      }
  
  };

  
  const headerStyle = {
    background: "#f1f1f1",
    textAlign: "center",
    fontWeight: "bold",
    padding: "8px",
    border: "1px solid #ddd",
  };

  const cellStyle = {
    padding: "8px",
    border: "1px solid #ddd",
  };

  return (
    <div
      style={{
        overflowX: "auto",
        padding: "16px",
        background: "#f9fafc",
        borderRadius: "12px",
      }}
    >
      <button
        onClick={() => setShowModal(true)}
        style={{
          marginBottom: "12px",
          padding: "8px 16px",
          background: "#2a9d8f",
          color: "#ffffff",
          border: "none",
          borderRadius: "4px",
          cursor: "pointer",
          fontWeight: "bold",
        }}
      >
        Download Excel
      </button>
      <button
        onClick={() => validateAllLocationAnswers()}
        style={{
          marginBottom: "12px",
          padding: "8px 16px",
          background: "#2a9d8f",
          color: "#ffffff",
          border: "none",
          borderRadius: "4px",
          cursor: "pointer",
          fontWeight: "bold",
          marginLeft:"20px"
        }}
      >
        Accept
      </button>

      <table
        style={{
          borderCollapse: "collapse",
          width: "100%",
          fontFamily: "Roboto, sans-serif",
          tableLayout: "fixed", // Ensures fixed layout for consistent column widths
        }}
      >
        <thead>
          <tr>
            <th style={{ ...headerStyle, width: "5%" }}>#</th>
            <th style={{ ...headerStyle, width: "40%" }}>Question</th>
            {columns.map((col) => (
              <th key={col} style={{ ...headerStyle, width: "15%" }}>
                {col}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {Object.keys(groupedModules).map((moduleName) => (
            <>
              {/* Render the module header */}
              <tr
                key={`${moduleName}-header`}
                style={{ background: "#f1f5f9" }}
              >
                <td
                  colSpan={columns.length + 2}
                  style={{
                    ...cellStyle,
                    fontWeight: "bold",
                    fontSize: "16px",
                    color: "#2a4c32",
                    textAlign: "center",
                    background: "#d8f3dc",
                  }}
                >
                  {moduleName}
                </td>
              </tr>

              {/* Render each question in the module */}
              {groupedModules[moduleName].map((questionData, index) => (
                <tr key={questionData.questionId}>
                  <td
                    style={{
                      ...cellStyle,
                      textAlign: "center",
                      fontWeight: "bold",
                      width: "5%", // Fixed width for question number
                    }}
                  >
                    {index + 1}
                  </td>
                  <td
                    style={{
                      ...cellStyle,
                      color: "#1d3b1d",
                      fontWeight: "bold",
                      width: "40%", // Fixed width for question column
                    }}
                    dangerouslySetInnerHTML={{
                      __html: questionData.title || "-",
                    }}
                  ></td>
                  {columns.map((col) => (
                    <td
                      key={col}
                      style={{
                        ...cellStyle,
                        background: "#fff",
                        color: "#000000",
                        textAlign: "right",
                        width: "15%", // Fixed width for other data columns
                      }}
                      dangerouslySetInnerHTML={{
                        __html: questionData.values[col] ?? "-",
                      }}
                    ></td>
                  ))}
                </tr>
              ))}
            </>
          ))}
        </tbody>
      </table>
      <Modal show={showModal} onHide={() => setShowModal(false)}>
  <Modal.Header closeButton>
    <Modal.Title>Select Filters</Modal.Title>
  </Modal.Header>
  <Modal.Body>
    <Form>
      <div className="d-flex flex-wrap gap-4">
        {/* Source Labels */}
        <Form.Group className="flex-grow-1">
          <Form.Label>Select Location</Form.Label>
          <div style={{ maxHeight: '200px', overflowY: 'auto' }}>
            {Object.keys(sourceLabels).map((sourceId) => (
              <Form.Check
                key={sourceId}
                type="checkbox"
                label={sourceLabels[sourceId]}
                value={sourceId}
                onChange={(e) => {
                  const value = e.target.value;
                  setSelectedSources((prev) =>
                    prev.some((item) => item.id === value)
                      ? prev.filter((item) => item.id !== value)
                      : [...prev, { id: value, label: sourceLabels[value] }]
                  );
                }}
              />
            ))}
          </div>
        </Form.Group>

        {/* Columns */}
        <Form.Group className="flex-grow-1">
          <Form.Label>Select Periods</Form.Label>
          <div style={{ maxHeight: '200px', overflowY: 'auto' }}>
            {columns.map((col, index) => (
              <Form.Check
                key={index}
                type="checkbox"
                label={col}
                value={col}
                onChange={(e) => {
                  const value = e.target.value;
                  setSelectedColumns((prev) =>
                    prev.includes(value)
                      ? prev.filter((c) => c !== value)
                      : [...prev, value]
                  );
                }}
              />
            ))}
          </div>
        </Form.Group>
      </div>
    </Form>
  </Modal.Body>
  <Modal.Footer>
    <Button variant="secondary" onClick={() => setShowModal(false)}>
      Cancel
    </Button>
    <Button variant="primary" onClick={handleDownload}>
      Download Excel
    </Button>
  </Modal.Footer>
</Modal>
    </div>
  );
};

// Parent component that manages the filtering and state
const ParentExcel = ({ allAnswers, allQuestions }) => {
  const [sourceLabels, setUserLabels] = useState({});
  const [sourceIds, setSourceIds] = useState([]);
  const [selectedSourceId, setSelectedSourceId] = useState(null);
  const [selectedModuleName, setSelectedModuleName] = useState("ALL");

  const moduleNames = Array.from(
    new Set(allQuestions.map((item) => item.moduleName))
  );

  useEffect(() => {
    const getLocations = async () => {
      try {
        const { isSuccess, data } = await apiCall(
          `${config.POSTLOGIN_API_URL_COMPANY}getSource`,
          {},
          {},
          "GET"
        );

        if (isSuccess && data?.data) {
          const labels = data.data.reduce((acc, item) => {
            if (item.id) {
              if (item.subLocation && item.subLocation.length > 0) {
                item.subLocation.forEach((location) => {
                  const key = `${item.id}-${location.id}`;
                  const value = `${item.unitCode} - ${location.subLocation}`;
                  acc[key] = value;
                });
              }
              acc[item.id] = item.unitCode;
            }
            return acc;
          }, {});
          setUserLabels(labels);
        }
      } catch (error) {
        console.error("Error fetching source labels:", error);
      }
    };

    getLocations();
  }, []);

  useEffect(() => {
    setSourceIds(
      Array.from(
        new Set(
          allAnswers.flatMap((item) => {
            if (sourceLabels[item.sourceId]) {
              return Object.keys(sourceLabels).filter((key) =>
                key.startsWith(item.sourceId.toString())
              );
            }
            return item.sourceId;
          })
        )
      )
    );
  }, [sourceLabels, allAnswers]);

  const filteredData = allAnswers
    .filter((item) => {
      if (!selectedSourceId) return false;

      const currentQuestion = allQuestions?.find(
        (question) => question.questionId === item?.questionId
      );
      if (!currentQuestion) return false;

      let matchesSourceId = false;

      if (item.subLocationId) {
        const sourceId = `${item.sourceId}-${item.subLocationId}`;
        matchesSourceId =
          selectedSourceId === "ALL" || sourceId === selectedSourceId;
      } else {
        matchesSourceId =
          selectedSourceId === "ALL" ||
          item.sourceId == selectedSourceId ||
          item.sourceId === selectedSourceId;
      }

      const matchesModuleName =
        selectedModuleName === "ALL" ||
        currentQuestion.moduleName === selectedModuleName;

      if (matchesSourceId && matchesModuleName) {
        item.moduleName = currentQuestion.moduleName;
        return true;
      }
      return false;
    })
    .sort((a, b) => {
      if (selectedModuleName === "ALL") {
        const moduleA = a.moduleName || "";
        const moduleB = b.moduleName || "";
        return moduleA.localeCompare(moduleB);
      }
      return 0;
    });

  return (
    <div>
      <label style={{ marginRight: "8px" }}>Location:</label>
      <select
        value={selectedSourceId || ""}
        onChange={(e) => setSelectedSourceId(e.target.value)}
      >
        <option value="ALL">ALL</option>
        {sourceIds.map((id) => (
          <option key={id} value={id}>
            {sourceLabels[id] || `User ${id}`}
          </option>
        ))}
      </select>

      <label style={{ marginLeft: "16px", marginRight: "8px" }}>Module:</label>
      <select
        value={selectedModuleName || ""}
        onChange={(e) =>
          setSelectedModuleName(
            e.target.value ? (e.target.value === "ALL" ? "ALL" : e.target.value) : null
          )
        }
      >
        <option value="ALL">ALL</option>
        {moduleNames.map((name, index) => (
          <option key={index} value={name}>
            {name}
          </option>
        ))}
      </select>

      <div style={{ marginTop: "16px" }}>
        <Excel data={filteredData} allQuestions={allQuestions} allAnswers={allAnswers} sourceLabels={sourceLabels} selectedModuleName={selectedModuleName} />
      </div>
    </div>
  );
};

export default ParentExcel;
