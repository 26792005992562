import React from "react";
import VerticalBarComponentWater from "./FrameworkOne/VerticalBarComponentWater";
import VerticalBarWaterComponent from "./FrameworkFourtyEight/VerticalBarWaterComponent";
import ShowTotalValue from "./FrameworkFourtyEight/ShowTotalValue";

const AllLocAllTime = ({
  companyFramework,
  brief,
  matchedDataWater,
  matchedWaterDis,
}) => {
  return (
    <div>
      {companyFramework.includes(1) ? (
        <div className="d-flex flex-column flex-space-between">
          <div
            className="d-flex flex-row flex-space-between"
            style={{ marginBottom: "3%" }}
          >
            <div
              className="firsthalfprogressenergy container"
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
                width: "50%",
              }}
            >
              <VerticalBarComponentWater
                matchedDataWater={matchedDataWater}
                title={"Water Withdrawal"}
              />
            </div>
            <div
              className="secondhalfprogress container"
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
                width: "50%",
              }}
            >
              <VerticalBarComponentWater
                matchedDataWater={matchedWaterDis}
                title={"Water Discharged"}
              />
            </div>
          </div>
        </div>
      ) : (
        <div className="d-flex flex-row flex-space-between" style={{ marginBottom: "2%" }}>
          {/* <div
            className="firsthalfprogressenergy"
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
              width: "50%",
              marginTop: "10px",
            }}
          >
            <ShowTotalValue brief={brief} type="ALL" />
          </div> */}
        
          <div
            className="firsthalfprogressenergy"
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
              width: "50%",
              marginTop: "10px",
            }}
          >
            <VerticalBarWaterComponent brief={brief} type="FUEL" />
          </div>
        </div>
      )}
    </div>
  );
};

export default AllLocAllTime;