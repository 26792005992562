import React from "react";
import img from "../../../img/no.png";

const DiversityBarStackComponent = ({
  title,
  dataOne,
}) => {
  if (!dataOne || dataOne.length === 0) {
    return (
      <div className="container">
        <img
          src={img}
          alt="No Data Available"
          style={{
            width: "150px",
            height: "125px",
            display: "block",
            margin: "0 auto",
          }}
        />
      </div>
    );
  }

  const categories = dataOne.reduce((acc, item) => {
    if (item.question_details) {
      let filteredOptions = item.question_details
        .filter((detail) => detail.option_type === "column1")
        .map((detail) => detail.option);

      if (filteredOptions.length === 0) {
        filteredOptions = item.question_details
          .filter((detail) => detail.option_type === "column")
          .map((detail) => detail.option);
      }

      return acc.concat(filteredOptions);
    }

    return acc;
  }, []);

  const uniqueCategories = [...new Set(categories)].reverse();

  // Data structure to hold our data items
  const dataItems = [
    { name: "Permanent", answerIndex: 0 },
    { name: "Other-than-Permanent", answerIndex: 1 },
    { name: "Total Employees", answerIndex: null } // This will be calculated from the others
  ];

  // Get values for each type
  const getCategoryValues = (item) => {
    if (item.answerIndex === null) {
      return null; // We'll calculate total separately
    }

    return uniqueCategories.map((category, categoryIndex) => {
      const totalValue = dataOne.reduce((sum, dataItem) => {
        if (dataItem.question_details && dataItem.answer && dataItem.answer[item.answerIndex]) {
          const matchedDetail = dataItem.question_details.find(
            (detail) => detail.option === category
          );
          const answerValue = dataItem.answer[item.answerIndex][categoryIndex];
          if (matchedDetail && answerValue !== undefined) {
            return sum + (isNaN(Number(answerValue)) ? 0 : Number(answerValue));
          }
        }
        return sum;
      }, 0);

      return {
        category,
        totalValue,
      };
    });
  };

  // Get values for both types
  dataItems[0].values = getCategoryValues(dataItems[0]);
  dataItems[1].values = getCategoryValues(dataItems[1]);

  // Filter out categories with 0 or NaN values for both types
  dataItems[0].validValues = dataItems[0].values ? dataItems[0].values.filter(
    (item) => !isNaN(Number(item.totalValue)) && Number(item.totalValue) > 0
  ) : [];
  
  dataItems[1].validValues = dataItems[1].values ? dataItems[1].values.filter(
    (item) => !isNaN(Number(item.totalValue)) && Number(item.totalValue) > 0
  ) : [];

  // Process the data for each item and prepare for display
  dataItems.forEach(item => {
    if (item.answerIndex !== null) {
      // Find values for males, females, others
      const maleData = item.validValues.find(v => 
        v.category === "Number of Males" || 
        v.category === "Males"
      );
      
      const femaleData = item.validValues.find(v => 
        v.category === "Number of Females" || 
        v.category === "Females"
      );
      
      const otherData = item.validValues.find(v => 
        v.category === "Other" || 
        v.category === "Others" ||
        v.category === "Number of Others"
      );
      
      item.maleValue = maleData ? Number(maleData.totalValue) : 0;
      item.femaleValue = femaleData ? Number(femaleData.totalValue) : 0;
      item.otherValue = otherData ? Number(otherData.totalValue) : 0;
      item.totalValue = item.maleValue + item.femaleValue + item.otherValue;
    }
  });

  // Calculate the total values
  dataItems[2].maleValue = dataItems[0].maleValue + dataItems[1].maleValue;
  dataItems[2].femaleValue = dataItems[0].femaleValue + dataItems[1].femaleValue;
  dataItems[2].otherValue = dataItems[0].otherValue + dataItems[1].otherValue;
  dataItems[2].totalValue = dataItems[2].maleValue + dataItems[2].femaleValue + dataItems[2].otherValue;

  // Colors for male and female
  const maleColor = "#6fa8dc";  // blue
  const femaleColor = "#ffa9d0"; // pink
  const otherColor = "#cccccc";  // light gray

  // Chart dimensions
  const chartHeight = 300;
  const barWidth = 65; 
  const barSpacing = 150; // Increased spacing to fit label text
  
  // For vertical scaling - use total value as max for proper scaling
  const maxValue = Math.max(...dataItems.map(item => item.totalValue));

  // Calculate a nice round scale (better for display)
  const getScaleIncrement = (value) => {
    const magnitude = Math.pow(10, Math.floor(Math.log10(value)));
    if (value / magnitude < 2) return magnitude / 2;
    if (value / magnitude < 5) return magnitude;
    return magnitude * 2;
  };

  const increment = getScaleIncrement(maxValue / 4);
  const roundedMaxValue = Math.ceil(maxValue / increment) * increment;
  const yAxisSteps = roundedMaxValue / increment;

  return (
    <div className="container" style={{ padding: "15px", boxSizing: "border-box" }}>
      <div className="diversity-bar-header" style={{ 
        fontSize: "16px", 
        fontWeight: "bold", 
        marginBottom: "10px", 
        textAlign: "center" 
      }}>{title}</div>

      {maxValue === 0 ? (
        <img
          src={img}
          alt="No Data Available"
          style={{
            width: "150px",
            height: "125px",
            display: "block",
            margin: "0 auto",
          }}
        />
      ) : (
        <div style={{ 
          width: "100%", 
          display: "flex", 
          flexDirection: "column", 
          alignItems: "center"
        }}>
          {/* Chart container with Y-axis */}
          <div style={{ 
            display: "flex", 
            position: "relative",
            height: `${chartHeight + 60}px`, // Extra space for labels
            width: `${barSpacing * dataItems.length + 50}px`,
            marginLeft: "40px" // Space for Y-axis labels
          }}>
            {/* Background grid */}
            <div style={{
              position: "absolute",
              left: "0",
              bottom: "30px", // Moved up to make room for labels
              width: "100%",
              height: `${chartHeight}px`,
            }}>
              {Array.from({ length: yAxisSteps + 1 }).map((_, index) => {
                const value = Math.round((yAxisSteps - index) * increment);
                const positionY = index * (chartHeight / yAxisSteps);

                return (
                  <React.Fragment key={index}>
                    {/* Y-axis value */}
                    <div style={{ 
                      position: "absolute", 
                      left: "-40px", 
                      top: `${positionY - 10}px`, 
                      fontSize: "12px",
                      textAlign: "right",
                      width: "35px"
                    }}>
                      {value}
                    </div>
                    
                    {/* Horizontal grid line */}
                    <div style={{ 
                      position: "absolute", 
                      left: "0",
                      top: `${positionY}px`, 
                      width: "100%", 
                      borderTop: "1px dashed #ccc"
                    }}></div>
                  </React.Fragment>
                );
              })}
            </div>
            
            {/* Stacked bars */}
            {dataItems.map((item, index) => {
              // Calculate bar heights based on scale
              const maleHeight = Math.round(item.maleValue * (chartHeight / roundedMaxValue));
              const femaleHeight = Math.round(item.femaleValue * (chartHeight / roundedMaxValue));
              const otherHeight = Math.round(item.otherValue * (chartHeight / roundedMaxValue));
              
              return (
                <div key={index} style={{ position: "relative" }}>
                  {/* Male bar */}
                  <div 
                    style={{ 
                      position: "absolute",
                      bottom: "30px", // Moved up to make room for labels
                      left: `${index * barSpacing + (barSpacing - barWidth)/2}px`,
                      width: `${barWidth}px`,
                      height: `${maleHeight}px`,
                      backgroundColor: maleColor,
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center"
                    }}
                  >
                    {item.maleValue > 0 && (
                      <div style={{
                        color: "white",
                        fontWeight: "bold",
                        fontSize: "14px"
                      }}>
                        {item.maleValue}
                      </div>
                    )}
                  </div>
                  
                  {/* Female bar - stacked on top of male */}
                  <div 
                    style={{ 
                      position: "absolute",
                      bottom: `${maleHeight + 30}px`, // Adjusted position 
                      left: `${index * barSpacing + (barSpacing - barWidth)/2}px`,
                      width: `${barWidth}px`,
                      height: `${femaleHeight}px`,
                      backgroundColor: femaleColor,
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center"
                    }}
                  >
                    {item.femaleValue > 0 && (
                      <div style={{
                        color: "white",
                        fontWeight: "bold",
                        fontSize: "14px"
                      }}>
                        {item.femaleValue}
                      </div>
                    )}
                  </div>
                  
                  {/* Other bar - stacked on top of female */}
                  <div 
                    style={{ 
                      position: "absolute",
                      bottom: `${maleHeight + femaleHeight + 30}px`, // Adjusted position
                      left: `${index * barSpacing + (barSpacing - barWidth)/2}px`,
                      width: `${barWidth}px`,
                      height: `${otherHeight}px`,
                      backgroundColor: otherColor,
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center"
                    }}
                  >
                    {item.otherValue > 0 && (
                      <div style={{
                        color: "white",
                        fontWeight: "bold",
                        fontSize: "14px"
                      }}>
                        {item.otherValue}
                      </div>
                    )}
                  </div>
                  
                  {/* X-axis baseline */}
                  <div style={{
                    position: "absolute",
                    bottom: "30px", // Aligned with bottom of bars
                    left: `${index * barSpacing}px`,
                    width: `${barSpacing}px`,
                    borderTop: "1px solid #aaa"
                  }}></div>
                  
                  {/* X-axis labels */}
                  <div style={{ 
                    position: "absolute",
                    bottom: "0", 
                    left: `${index * barSpacing}px`, 
                    width: `${barSpacing}px`, 
                    textAlign: "center",
                    fontSize: "12px",
                    fontWeight: item.name === "Total Employees" ? "bold" : "normal",
                  }}>
                    {item.name === "Other-than-Permanent" ? (
                      "Other-than-Permanent"
                    ) : (
                      item.name
                    )}
                  </div>
                </div>
              );
            })}
          </div>
          
          {/* Legend */}
          <div style={{
            display: "flex",
            justifyContent: "center",
            marginTop: "20px"
          }}>
            <div style={{ display: "flex", alignItems: "center", marginRight: "20px" }}>
              <div style={{ width: "12px", height: "12px", backgroundColor: maleColor, marginRight: "5px" }}></div>
              <div style={{ fontSize: "12px" }}>Male</div>
            </div>
            <div style={{ display: "flex", alignItems: "center", marginRight: "20px" }}>
              <div style={{ width: "12px", height: "12px", backgroundColor: femaleColor, marginRight: "5px" }}></div>
              <div style={{ fontSize: "12px" }}>Female</div>
            </div>
            <div style={{ display: "flex", alignItems: "center" }}>
              <div style={{ width: "12px", height: "12px", backgroundColor: otherColor, marginRight: "5px" }}></div>
              <div style={{ fontSize: "12px" }}>Other</div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default DiversityBarStackComponent;