import React from "react";
import Chart from "react-apexcharts";
import no from "../../../img/no.png";

const DiversityMultipleBarComponent = ({ data }) => {
  console.log(data);
  if (!data || data.length === 0) {
    return (
      <div className="container">
        <img
          src={no} // Replace with the actual image path or URL
          alt="No Data Available"
          style={{
            width: "150px",
            height: "125px",
            display: "block",
            margin: "0 auto",
          }}
        />
      </div>
    );
  }
  
  // Extract the rows for x-axis categories (e.g., "Number of board members", "Number of Key Management Personnel")
  const categories = data[0].question_details
    .filter((detail) => detail.option_type === "row")
    .map((detail) => {
      if (detail.option === "Number of Key Management Personnel") {
        return "Key Management"; // Replace the specific category name
      } else if (detail.option === "Number of board members") {
        return "Board"; // Replace the specific category name
      }
      return detail.option; // Return other options unchanged
    });

  // Extract the columns (e.g., "Female", "Male") for the stack series
  const stackCategories = data[0].question_details
    .filter((detail) => detail.option_type === "column")
    .map((detail) => detail.option);

  // Reverse the answer array structure to match the rows and columns
  const seriesData = stackCategories.map((column, colIndex) => {
    return {
      name: column, // Name of the stack (e.g., "Female", "Male")
      data: data[0].answer.map((answer) =>
        answer[colIndex] !== "" ? Number(answer[colIndex]) : 0
      ), // Data for each row
    };
  });

  // Chart options
  const options = {
    chart: {
      type: "bar",
      stacked: true,
      height: 350,
      toolbar: {
        show: false, // Hide the toolbar
      },
      fontFamily: 'inherit',
    },
    title: {
      text: "Diversity in Leadership",
      align: 'left',
      style: {
        fontSize: '18px',
        fontWeight: 'bold',
      }
    },
    plotOptions: {
      bar: {
        horizontal: false, // Changed to vertical bars
        columnWidth: '70px', // Fixed width of 70px for bars
        distributed: false,
      },
    },
    xaxis: {
      categories: categories, // X-axis will have the categories
      labels: {
        rotate: 0, // No rotation for the labels as in the example
        style: {
          fontSize: '12px'
        }
      },
      axisBorder: {
        show: false
      },
      axisTicks: {
        show: false
      }
    },
    yaxis: {
      title: {
        text: "Count",
      },
      max: 8, // Set maximum value to 8 to match the example
      tickAmount: 4, // Show 4 tick marks (0, 2, 4, 6, 8)
    },
    tooltip: {
      y: {
        formatter: (val) => `${val}`,
      },
    },
    dataLabels: {
      enabled: true, // Enable data labels
      style: {
        fontSize: "12px", // Font size for the labels
        fontWeight: "bold", // Make the labels bold
        colors: ["#fff"], // Make label text white for contrast
      },
      dropShadow: {
        enabled: true, // Enable shadow for better readability
        top: 1,
        left: 1,
        blur: 3,
        opacity: 0.5,
      },
      formatter: function (val) {
        return val !== 0 ? `${val}` : ''; // Hide 0 values from being displayed
      },
    },
    fill: {
      opacity: 1,
    },
    legend: {
      show: true,
      markers: {
        width: 12, // Custom legend marker width
        height: 12, // Custom legend marker height
        borderRadius: 2, // Square markers to match the example
      },
      position: "bottom", // Position at the bottom
      horizontalAlign: "center", // Center align the legend
      offsetY: 10, // Add some space from the chart
      itemMargin: {
        horizontal: 15, // More space between legend items
        vertical: 0,
      },
      formatter: function (seriesName, opts) {
        return seriesName; // Simple label without custom styling
      },
    },
    colors: ["#90EE90", "#b0b0b0", "#11546f"], // Custom colors for the bars: Male, Female, Others
    grid: {
      padding: {
        top: 10, // Add some padding at the top for the labels
      },
      show: true,
      borderColor: '#f1f1f1',
      strokeDashArray: 0,
      position: 'back',
      xaxis: {
        lines: {
          show: false
        }
      },
      yaxis: {
        lines: {
          show: true
        }
      }
    }
  };

  // Adjust the container to ensure proper spacing for the legend
  return (
    <div className="container">
      <Chart 
        options={options} 
        series={seriesData} 
        type="bar" 
        height={400} // Increased height to accommodate the legend
      />
    </div>
  );
};

export default DiversityMultipleBarComponent;