import React from "react";
import TotalEnergySingLocMultTime from "../Energy/FrameworkOne/TotalEnergySingLocMultTime";
import CombinedBarWaterDynamic from "./FrameworkOne/CombinedBarWater";
import RenewableAndNonRenewable from "../Energy/FrameworkOne/RenewableAndNonRenewable";
import TotalWasteDisposedMulti from "../Waste/FrameworkOne/TotalWasteDisposedMulti";
import FourtyEightTotalEnergy from "../Energy/Framework48/FourtyEightTotalEnergy";
import SingleLocMultTimeBar from "./FrameworkFourtyEight/SingleLocMultTime";
import ProductWiseFourtyEight from "../Energy/Framework48/ProductWiseFourtyEight";
import MultipleBarWater from "../Water/FrameworkOne/MultipleBarWater";
import TotalWasteGeneratedMukt from "../Waste/FrameworkOne/TotalWasteGeneratedMukt";
import WaterHorizontalBar from "../Water/FrameworkFourtyEight/WaterHorizontalBar";
import WasteGenMultLoc from "../Waste/FrameworkFourtyEight/WasteMultiLocMultiTimeGen";

const EnvironmentSingleLocMultipleTime = ({
  companyFramework,
  timePeriods,
  matchedDataWater,
  wasteDisposal,
  renewableEnergy,
  nonRenewableEnergy,
  matchedWaterDis,
  briefEnergy,
  briefWaste,
  briefWater,
  locationOption,
  timePeriodValues,
  matchedDataWaste
}) => {
  return companyFramework.includes(1) ? (
    <div className="d-flex flex-column flex-space-between">
      <div
        className="d-flex flex-row flex-space-between"
        style={{ height: "50vh", marginBottom: "3%" }}
      >
        <div
          className="firsthalfprogressenergy"
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            width: "50%",
          }}
        >
          <div style={{ height: "100%" }}>
            <TotalEnergySingLocMultTime
              timePeriodValues={timePeriodValues}
              locationOption={locationOption}
              timePeriods={timePeriods}
              renewableEnergy={renewableEnergy}
              nonRenewableEnergy={nonRenewableEnergy}
            />
          </div>
        </div>
        <div
          className="secondhalfprogress"
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            width: "50%",
          }}
        >
          
          <div style={{ height: "100%" }}>
            {/* <CombinedBarWaterDynamic
              title={"Total Water Withdrawn"}
              matchedDataWater={matchedDataWater}
              unit={"KL"}
              timePeriods={timePeriods}
            /> */}
              <MultipleBarWater locationOption={locationOption} title={"Water Consumption"} timePeriodValues={timePeriodValues} timePeriods={timePeriods} matchedDataWater={matchedDataWater} />

          </div>
          {/* <div style={{ height: "48%" }}>
            <CombinedBarWaterDynamic
              title={"Total Water Disposed"}
              unit={"KL"}
              matchedDataWater={matchedWaterDis}
              timePeriods={timePeriods}
            />
          </div> */}
        </div>
      </div>
      <div
        className="d-flex flex-row flex-space-between"
        style={{ height: "70vh", marginBottom: "3%" }}
      >
        <div
          className="firsthalfprogressenergy"
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            width: "50%",
          }}
        >
          <div style={{ height: "100%" }}>
          <TotalWasteGeneratedMukt
            timePeriods={timePeriods}
            locationOption={locationOption}
            timePeriodValues={timePeriodValues}
            title={"Total Waste Generated"}
            matchedDataWaste={matchedDataWaste}
          />
          </div>
         
        </div>
        <div
          className="secondhalfprogress"
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            width: "50%",
          }}
        >
          <div style={{ height: "100%" }}>
            <RenewableAndNonRenewable
              timePeriodValues={timePeriodValues}
              locationOption={locationOption}
              timePeriods={timePeriods}
              renewableEnergy={renewableEnergy}
              nonRenewableEnergy={nonRenewableEnergy}
            />
          </div>
        </div>
      </div>
    </div>
  ) : (
    <div className="d-flex flex-column flex-space-between">
      <div
        className="d-flex flex-row flex-space-between"
        style={{ height: "50vh", marginBottom: "3%" }}
      >
        <div
          className="firsthalfprogressenergy"
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            width: "50%",
          }}
        >
          <div style={{ height: "100%" }}>
          {briefEnergy &&  <FourtyEightTotalEnergy
              brief={briefEnergy}
              locationOption={locationOption}
              timePeriods={timePeriods}
              timePeriodValues={timePeriodValues}
            />
            
            }
          </div>
        </div>
       <div
          className="secondhalfprogress"
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            width: "50%",
          }}
        >
          <div style={{ height: "100%" }}>
          <WaterHorizontalBar brief={briefWater} locationOption={locationOption} timePeriods={timePeriods} timePeriodValues={timePeriodValues} />

          </div>
         
        </div>
      </div>
      <div
        className="d-flex flex-row flex-space-between"
        style={{ height: "60vh", marginBottom: "3%" }}
      >
        <div
          className="firsthalfprogressenergy"
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            width: "50%",
          }}
        >
          <div style={{ height: "100%" }}>
          <WasteGenMultLoc
              brief={briefWaste}
              timePeriods={timePeriods}
              timePeriodValues={timePeriodValues}
              locationOption={locationOption}
            />
          </div>

          
        </div>
        <div
          className="secondhalfprogress"
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            width: "50%",
          }}
        >
          <div style={{ height: "100%" }}>
            <ProductWiseFourtyEight
              timePeriodValues={timePeriodValues}
              locationOption={locationOption}
              brief={briefEnergy}
              timePeriods={timePeriods}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default EnvironmentSingleLocMultipleTime;
