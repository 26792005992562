// import React from "react";
// import TotalEnergySingLocMultTime from "./TotalEnergySingLocMultTime";
// import ProductWiseStacked from "./ProductWiseStacked";

// const SingleLocMultTime = ({
//   companyFramework,
//   timePeriods,
//   brief,
//   locationOption,
//   renewableEnergy,
//   nonRenewableEnergy,
//   timePeriodValues,
// }) => {

//   if (!companyFramework.includes(1)) {
//     return null; // Return null if companyFramework doesn't include 1
//   }

//   return (
//     <div className="d-flex flex-column flex-space-between">
//       {/* First Row */}
//       <div
//         className="d-flex flex-row flex-space-between"
//         style={{ height: "120vh", marginBottom: "3%" }}
//       >
//         <div
//           className="firsthalfprogressenergy"
//           style={{
//             display: "flex",
//             flexDirection: "column",
//             justifyContent: "space-between",
//             width: "50%",
//           }}
//         >
//           <div style={{ height: "48%" }}>
//             <TotalEnergySingLocMultTime
//               timePeriodValues={timePeriodValues}
//               locationOption={locationOption}
//               timePeriods={timePeriods}
//               renewableEnergy={renewableEnergy}
//               nonRenewableEnergy={nonRenewableEnergy}
//             />
//           </div>
//         </div>

//         <div
//           className="secondhalfprogress"
//           style={{
//             display: "flex",
//             flexDirection: "column",
//             justifyContent: "space-between",
//             width: "50%",
//           }}
//         >
//           <div style={{ height: "48%" }}>
//             <ProductWiseStacked
//               title={"Product Wise Emission"}
//               timePeriodValues={timePeriodValues}
//               locationOption={locationOption}
//               timePeriods={timePeriods}
//               product={nonRenewableEnergy}
//             />
//           </div>
//         </div>
//       </div>
//     </div>
//   );
// };

// export default SingleLocMultTime;

import React from "react";
import FourtyEightTotalEnergy from "./Framework48/FourtyEightTotalEnergy";
import ProductWiseFourtyEight from "./Framework48/ProductWiseFourtyEight";
import ProductWiseStacked from "./FrameworkOne/ProductWiseStacked";
import RenewableAndNonRenewable from "./FrameworkOne/RenewableAndNonRenewable";
import TotalEnergySingLocMultTime from "./FrameworkOne/TotalEnergySingLocMultTime";

const SingleLocMultTime = ({
  companyFramework,
  timePeriods,
  brief,
  locationOption,
  renewableEnergy,
  nonRenewableEnergy,
  timePeriodValues,
  scope1,
  scope2
}) => {
  return companyFramework.includes(1) ? (
    <div className="d-flex flex-column flex-space-between">
      {/* First Row */}
      <div
        className="d-flex flex-row flex-space-between"
        style={{ height: "120vh", marginBottom: "3%" }}
      >
        <div
          className="firsthalfprogressenergy"
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            width: "50%",
          }}
        >
          <div style={{ height: "48%" }}>
            <TotalEnergySingLocMultTime
              timePeriodValues={timePeriodValues}
              locationOption={locationOption}
              timePeriods={timePeriods}
              renewableEnergy={renewableEnergy}
              nonRenewableEnergy={nonRenewableEnergy}
            />
          </div>

          <div style={{ height: "48%" }}>
            <ProductWiseStacked
              title={"Product Wise Scope1"}
              timePeriodValues={timePeriodValues}
              locationOption={locationOption}
              timePeriods={timePeriods}
              product={renewableEnergy}
            />
          </div>
        </div>

        <div
          className="secondhalfprogress"
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            width: "50%",
          }}
        >
          <div style={{ height: "48%" }}>
            <RenewableAndNonRenewable
              timePeriodValues={timePeriodValues}
              locationOption={locationOption}
              timePeriods={timePeriods}
              renewableEnergy={renewableEnergy}
              nonRenewableEnergy={nonRenewableEnergy}
            />
          </div>
          <div style={{ height: "48%" }}>
            <ProductWiseStacked
              title={"Product Wise Scope2"}
              timePeriodValues={timePeriodValues}
              locationOption={locationOption}
              timePeriods={timePeriods}
              product={nonRenewableEnergy}
            />
          </div>
        </div>
      </div>

  
    </div>
  ) : (
    <div className="d-flex flex-column flex-space-between">
      <div
        className="d-flex flex-row flex-space-between"
        style={{ height: "60vh", marginBottom: "3%" }}
      >
        <div
          className="firsthalfprogressenergy"
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            width: "50%",
          }}
        >
          <div style={{ height: "100%" }}>
          <ProductWiseFourtyEight
              timePeriodValues={timePeriodValues}
              locationOption={locationOption}
              brief={scope1}
              timePeriods={timePeriods}
              renewableEnergy={renewableEnergy}
              nonRenewableEnergy={nonRenewableEnergy}
              heading = "Product Wise scope1 emission"
            />
          </div>

          <div style={{ height: "0%" }}>
            {/* <ProductWiseStacked   timePeriodValues={timePeriodValues} locationOption={locationOption}  timePeriods={timePeriods} product={renewableEnergy}/> */}
          </div>
        </div>
        <div
          className="secondhalfprogress"
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            width: "50%",
          }}
        >
          <div style={{ height: "100%" }}>
            {/* <RenewableAndNonRenewable timePeriodValues={timePeriodValues} locationOption={locationOption}  timePeriods={timePeriods} renewableEnergy={renewableEnergy} nonRenewableEnergy={nonRenewableEnergy}/> */}
            <ProductWiseFourtyEight
              timePeriodValues={timePeriodValues}
              locationOption={locationOption}
              brief={scope2}
              timePeriods={timePeriods}
              renewableEnergy={renewableEnergy}
              nonRenewableEnergy={nonRenewableEnergy}
              heading = "Product Wise scope2 emission"

            />
          </div>
          <div style={{ height: "0%" }}>
            {/* <ProductWiseStacked  timePeriodValues={timePeriodValues} locationOption={locationOption} timePeriods={timePeriods} product={nonRenewableEnergy} /> */}
          </div>
        </div>
      </div>
      {/* <div
      className="d-flex flex-row flex-space-between"
      style={{ height: "22vh", marginBottom: "3%" }}
    ></div> */}
    </div>
  );
};

export default SingleLocMultTime;

