// import React from "react";

// import TotalWasteGeneratedByTpe from "./FrameworkOne/TotalWasteGeneratedByTpe";
// import WasteRecyPie from "./FrameworkOne/WasteRecyPie";
// import WasteGeneratedFourtyEight from "./FrameworkFourtyEight/WasteGeneratedFourtyEight";
// import WasteDonutFourtyEight from "./FrameworkFourtyEight/WasteDonutFourtyEight";

// const SingleLocSingleTime = ({
//   companyFramework,
//   sectorQuestionAnswerDataForGraph,
//   timePeriods,
//   brief,
//   graphData,
//   matchedDataWaste,
//   wasteDisposal,
//   wasteRecovered,
//   locationOption,

//   keyTab,
//   timePeriodValues,

// }) => {
//   return companyFramework.includes(1) ?
//     (
//       <div className="d-flex flex-column flex-space-between">
//         <div
//           className="d-flex flex-row flex-space-between"
//           style={{ height: "60vh", marginBottom: "3%" }}
//         >
//           <div className="firsthalfprogressenergy" style={{ display: "flex", flexDirection: "column", justifyContent: "space-between", width: "50%" }}>
//             <div style={{ height: "48%" }}>
//               <TotalWasteGeneratedByTpe title={"Total Waste Generated"} timePeriods={timePeriods} timePeriodValues={timePeriodValues} locationOption={locationOption} matchedDataWaste={matchedDataWaste} />

//             </div>

//             <div style={{ height: "48%" }}>
//               <TotalWasteGeneratedByTpe title={"Total Waste Disposed"} timePeriods={timePeriods} timePeriodValues={timePeriodValues} locationOption={locationOption} matchedDataWaste={wasteDisposal} />


//             </div>


//           </div>
//           <div className="secondhalfprogress" style={{ display: "flex", flexDirection: "column", justifyContent: "space-between", width: "50%" }}>
//             <WasteRecyPie timePeriods={timePeriods} timePeriodValues={timePeriodValues} locationOption={locationOption} matchedDataWaste={wasteRecovered} />


//           </div>
//         </div>
//         {/* <div
//         className="d-flex flex-row flex-space-between"
//         style={{ height: "22vh", marginBottom: "3%" }}
//       >
      
//       </div> */}
//       </div>
//     ) : (
//       <>
//         <div className="d-flex flex-column flex-space-between">
//           <div
//             className="d-flex flex-row flex-space-between"
//             style={{ height: "60vh", marginBottom: "3%" }}
//           >
//             <div className="firsthalfprogressenergy" style={{ display: "flex", flexDirection: "column", justifyContent: "space-between", width: "50%" }}>
//               <div style={{ height: "55%" }}>
//                 <WasteGeneratedFourtyEight timePeriods={timePeriods} timePeriodValues={timePeriodValues} locationOption={locationOption} brief={brief} />

//               </div>

//               <div style={{ height: "0%" }}>
//                 {/* <EnergyConsumptionChart totalConsumptionRenewable={totalConsumptionRenewable} totalConsumptionNonRenewable={totalConsumptionNonRenewable} /> */}


//               </div>


//             </div>
//             <div className="secondhalfprogress" style={{ display: "flex", flexDirection: "column", justifyContent: "space-between", width: "55%" }}>
//               <div style={{ height: "100%" }}>
//                 <WasteDonutFourtyEight brief={brief} />

//               </div>
//               <div style={{ height: "0%" }}>
//                 {/* <CommonBarComponent
//             renewableEnergy={renewableEnergy}
//             nonRenewableEnergy={nonRenewableEnergy}
//             com={"non"}
// /> */}

//               </div>
//             </div>
//           </div>
//           {/* <div
//         className="d-flex flex-row flex-space-between"
//         style={{ height: "22vh", marginBottom: "3%" }}
//       >
    
//       </div> */}
//         </div>


//       </>
//     );
// };

// export default SingleLocSingleTime;

import React from "react";
import WasteConsumptionFourtyEight from "./FrameworkFourtyEight/WasteConsumptionFourtyEight";
import VerticalWasteBarComponent from "./FrameworkFourtyEight/VerticalWasteBarComponent";
import TotalWasteGeneratedByVertical from "./FrameworkOne/TotalWasteGeneratedByVertical";

const SingleLocSingleTime = ({
  companyFramework,
  timePeriods,
  brief,
  timePeriodValues,
  locationOption,
  matchedDataWaste,
  wasteDisposal,
  wasteRecovered,
  bioMedicalBrief,
}) => {
  return (
    <div>
      {companyFramework.includes(1) ? (
        <div className="d-flex flex-column flex-space-between">
          <div
            className="d-flex flex-row flex-space-between"
            style={{ marginBottom: "3%" }}
          >
            <div
              className="firsthalfprogressenergy"
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
                width: "50%",
              }}
            >
              <div className="my-3 container" style={{ height: "100%" }}>
                <TotalWasteGeneratedByVertical
                  title={"Total Waste Generated"}
                  timePeriods={timePeriods}
                  timePeriodValues={timePeriodValues}
                  locationOption={locationOption}
                  matchedDataWaste={matchedDataWaste}
                />
              </div>

              <div className="my-3 container" style={{ height: "100%" }}>
                <TotalWasteGeneratedByVertical
                  title={"Total Waste Disposed"}
                  timePeriods={timePeriods}
                  timePeriodValues={timePeriodValues}
                  locationOption={locationOption}
                  matchedDataWaste={wasteDisposal}
                />
              </div>
            </div>
            <div
              className="secondhalfprogress"
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
                width: "50%",
              }}
            >
              <div className="my-3 container" style={{ height: "49%" }}>
                <TotalWasteGeneratedByVertical
                  title={"Total Waste Recovered"}
                  timePeriods={timePeriods}
                  timePeriodValues={timePeriodValues}
                  locationOption={locationOption}                  
                  matchedDataWaste={wasteRecovered}
                />
              </div>
            </div>
          </div>
        </div>
      ) : (
        <>
          <div className="d-flex flex-column flex-space-between">
            <div
              className="d-flex flex-row flex-space-between"
              style={{ marginBottom: "2%" }}
            >
              <div
                className="firsthalfprogressenergy"
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-between",
                  width: "50%",
                  marginTop: "10px",
                }}
              >
                <div style={{ height: "65%" }} className="my-2">
                  <WasteConsumptionFourtyEight
                    timePeriodValues={timePeriodValues}
                    brief={brief}
                    timePeriods={timePeriods}
                    type="GEN"
                  />
                </div>

                <div style={{ height: "65%" }} className="my-2">
                  <WasteConsumptionFourtyEight
                    timePeriodValues={timePeriodValues}
                    brief={bioMedicalBrief}
                    timePeriods={timePeriods}
                    type="BIO"
                  />
                </div>
              </div>
              <div
                className="secondhalfprogress"
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-between",
                  width: "50%",
                  marginTop: "10px",
                }}
              >
                <div style={{ height: "65%" }} className="my-2">
                  <WasteConsumptionFourtyEight
                    timePeriodValues={timePeriodValues}
                    brief={brief}
                    timePeriods={timePeriods}
                    type="DIS"
                  />
                </div>
              </div>
            </div>
            <div
              className="d-flex flex-row flex-space-between"
              style={{ height: "60vh", marginBottom: "3%" }}
            >
              <div
                className="firsthalfprogressenergy"
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-between",
                  width: "50%",
                }}
              >
                <div className="container" style={{
                  marginBottom: "20px",
                }}>
                  <VerticalWasteBarComponent
                    timePeriods={timePeriods}
                    timePeriodValues={timePeriodValues}
                    locationOption={locationOption}
                    brief={brief}
                    type="GEN"
                  />
                </div>

                <div
                  className="container"
                  style={{
                    marginBottom: "20px",
                  }}
                >
                  <VerticalWasteBarComponent
                    timePeriods={timePeriods}
                    timePeriodValues={timePeriodValues}
                    locationOption={locationOption}
                    brief={bioMedicalBrief}
                    type="BIO"
                  />
                </div>
              </div>
              <div
                className="secondhalfprogress"
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-between",
                  width: "55%",
                }}
              >
                <div className="container" style={{
                  marginBottom: "20px",
                }}>
                  <VerticalWasteBarComponent
                    timePeriods={timePeriods}
                    timePeriodValues={timePeriodValues}
                    locationOption={locationOption}
                    brief={brief}
                    type="DIS"
                  />
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default SingleLocSingleTime;
