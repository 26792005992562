import React, { useEffect, useState } from 'react';
import Chart from 'react-apexcharts';
import img from "../../../img/no.png"

const WaterHorizontalBar = ({ timePeriods, timePeriodValues, locationOption, brief }) => {
  const [chartOptions, setChartOptions] = useState(null); // Start with null
  const [series, setSeries] = useState([]);

  const categories = ["Total Groundwater consumption* ( in KL)", "Total Tanker Water Consumption* (in KL)","Total surface water consumption (this includes municipal supply water)* ( in KL)"];

  useEffect(() => {
    const locationPeriod = Object.keys(brief.time); // Assuming these are your locations
    const time = Object.keys(brief.location);

    // Prepare data for the chart based on conditions
    let seriesData = [];

    if (locationPeriod.length > 1 && timePeriodValues.length === 1) {
      // Case 1: Multiple locations and single time period
      locationPeriod.forEach(location => {
        const locationData = {};
        categories.forEach(category => {
          if (brief.time[location][category]) {
            // Take the first element of the array for the value
            locationData[category] = brief.time[location][category][0];
          }
        });
        seriesData.push(locationData);
      });
    } else if (locationPeriod.length === 1 && timePeriodValues.length > 1) {
      // Case 2: Single location and multiple time periods
      time.forEach(timeO => {
        const locationData = {};
        categories.forEach(category => {
          if (brief.location[timeO][category]) {
            // Take the first element of the array for the value
            locationData[category] = brief.location[timeO][category][0];
          }
        });
        seriesData.push(locationData);
      });
    } else if (locationPeriod.length > 1 && timePeriodValues.length > 1) {
      // Case 3: Multiple locations and multiple time periods
      time.forEach(timeO => {
        const locationData = {};
        categories.forEach(category => {
          if (brief.location[timeO][category]) {
            // Sum all the elements in the array for the value
            const sumValue = brief.location[timeO][category].reduce((acc, val) => acc + Number(val), 0);
            locationData[category] = sumValue;
          }
        });
        seriesData.push(locationData);
      });
    }

    // Convert seriesData into the format ApexCharts expects
    const chartSeries = categories.map(category => ({
      name: category,
      data: seriesData.map(item => item[category] || 0), // Fallback to 0 if no data
    }));

    // Update the state with the new series data
    setSeries(chartSeries);

    const options = {
      chart: {
        type: "bar",
        stacked: true,
        toolbar: {
          show: false, // Hide the three lines toolbar (download options)
        },
        animations: {
          enabled: false
        },
      },
      dataLabels: {
        enabled: true, // Enable data labels
        formatter: function (val) {
          return val; // Show the actual value
        },
        style: {
          fontSize: "12px",
          colors: ["#fff"], // Set text color to white for better contrast
        },
      },
      xaxis: {
        categories:
          locationPeriod.length > 1 && timePeriodValues.length === 1
            ? locationPeriod // Using locations as X-axis categories for this condition
            : time, // Using time periods as X-axis categories for the other condition
        labels: {
          rotate: -45,
          style: {
            fontSize: "12px",
            fontWeight: 500,
          },
        },
      },
      plotOptions: {
        bar: {
          horizontal: false, 
          columnWidth: "65PX",
          distributed: false,
          dataLabels: {
            position: 'center'
          },
        },
      },
      yaxis: {
        title: {
          text: '( in KL )',
          style: {
            fontSize: '14px',
            fontWeight: 500,
          },
        },
        labels: {
          style: {
            fontSize: "12px",
            fontWeight: 500,
          },
          formatter: function(val) {
            return val.toFixed(0);
          }
        },
      },
      title: {
        show: false,
        text: "",
      },
      legend: {
        show: false,
      },
      fill: {
        opacity: 1,
      },
      colors: [
        "#E6594D", // Petrol (Red)
  "#3F822B", // CNG (Green)
  "#1212F1", // LPG (Blue)
  "#EEC27F", // PNG (Orange)
  "#A14D49", // Briquette (Brown)
  "#791E80"  // Total (Purple)
      ],
      grid: {
        padding: {
          left: 0,
          right: 0
        },
        xaxis: {
          lines: {
            show: false
          }
        },
        yaxis: {
          lines: {
            show: true
          }
        }
      },
    };

    setChartOptions(options);
  }, [brief, locationOption, timePeriods, timePeriodValues]);

  // Check if all series values are zero
  const allValuesZero = series.every(item => item.data.every(value => value === 0));

  if (!brief || !brief.time) {
    return <p>No data available.</p>;
  }

  return (
    <div className="container">
      <div
        style={{
          height: "10%",
          fontSize: "20px",
          fontWeight: 600,
          color: "#011627",
        }}
      >
        Total Water Consumption
      </div>

      <div style={{ height: "300px", width: "100%" }}>
        {allValuesZero ? (
          <div style={{ height: "100%", width: "100%", display: "flex", alignItems: 'center', justifyContent: 'center' }}>
            <img src={img} style={{ height: "90px", width: "170px" }} alt="No data" />
          </div>
        ) : (
          chartOptions && (
            <Chart
              options={chartOptions}
              series={series}
              type="bar"
              height="100%"
            />
          )
        )}
      </div>

      {/* Custom Legends Container */}
      {chartOptions && (
        <div style={{ marginTop: "20px", width: "100%" }}>
          <div style={{ display: "flex", flexWrap: "wrap" }}>
            {categories.map((category, index) => (
              <div
                key={index}
                style={{
                  display: "flex",
                  alignItems: "center",
                  marginRight: "15px",
                  marginBottom: "10px",
                  width: "30%",
                }}
              >
                <div
                  style={{
                    width: "15px",
                    height: "15px",
                    borderRadius: "50%",
                    backgroundColor: chartOptions.colors[index % chartOptions.colors.length],
                    marginRight: "5px",
                  }}
                />
                <span style={{ fontWeight: 300, fontSize: "12px" }}>
                  {category}
                </span>
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default WaterHorizontalBar;