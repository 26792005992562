import React from "react";
import DiversityBarComponent from "./FrameworkOne/DiversityBarComponent";
import DiversityMultipleBarComponent from "./FrameworkOne/DiversityMultipleBarComponet";
import DiversityBarComponentTwo from "./FrameworkOne/DiversityBarComponentTwo";
import DiversityMenAndWomen from "./FrameworkFourtyEight/DiversityMenAndWomen";
import DiversityBarStackComponent from "./FrameworkOne/DiversityBarStackComponent";
import DiversityBarPieComponent from "./FrameworkOne/DiversityBarPieComponent";

const DiversitySingleTimeSingleLoc = ({
  companyFramework,
  timePeriods,
  dataOne,
  titleOne,
  dataTwo,
  titleTwo,
  diversity,
  titleFour,
  titleFive,
  brief,
  locationOption,
  timePeriodValues,
  titleseven,
  titlesix
}) => {
  return companyFramework.includes(1) ? (
    <div className="d-flex flex-column flex-space-between">
      <div
        className="d-flex flex-row flex-space-between"
        style={{ marginBottom: "3%" }}
      >
        <div
          className="firsthalfprogressenergy"
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            width: "50%",
          }}
        >
          <div style={{ height: "100%" }}>
            <DiversityBarStackComponent
              title={titlesix}
              dataOne={dataOne}
              timePeriodValues={timePeriodValues}
              timePeriods={timePeriods}
              locationOption={locationOption}
            />
          </div>
        </div>
        <div
          className="secondhalfprogress"
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            width: "50%",
          }}
        >
          <div style={{ height: "100%" }}>
            <DiversityBarPieComponent
              title={titleseven}
              dataOne={dataOne}
              timePeriodValues={timePeriodValues}
              timePeriods={timePeriods}
              locationOption={locationOption}
            />
          </div>
        </div>
      </div>
      <div
        className="d-flex flex-row flex-space-between"
        style={{ height: "100vh", marginBottom: "3%" }}
      >
        <div
          className="firsthalfprogressenergy"
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            width: "50%",
          }}
        >
          <div style={{ height: "48%" }}>
            <DiversityBarComponent
              title={titleOne}
              dataOne={dataOne}
              timePeriodValues={timePeriodValues}
              timePeriods={timePeriods}
              locationOption={locationOption}
            />
          </div>

          <div style={{ height: "48%" }}>
            <DiversityBarComponentTwo
              title={titleFive}
              dataOne={dataTwo}
              timePeriodValues={timePeriodValues}
              timePeriods={timePeriods}
              locationOption={locationOption}
            />
          </div>
        </div>
        <div
          className="secondhalfprogress"
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            width: "50%",
          }}
        >
          <div style={{ height: "48%" }}>
            <DiversityBarComponentTwo
              title={titleFour}
              dataOne={dataOne}
              timePeriodValues={timePeriodValues}
              timePeriods={timePeriods}
              locationOption={locationOption}
            />
          </div>

          <div style={{ height: "48%" }}>
            <DiversityBarComponent
              title={titleTwo}
              dataOne={dataTwo}
              timePeriodValues={timePeriodValues}
              timePeriods={timePeriods}
              locationOption={locationOption}
            />
          </div>
        </div>
      </div>
      <div
        className="d-flex flex-row flex-space-between"
        style={{ height: "50vh", marginBottom: "3%" }}
      >
        <div
          className="firsthalfprogressenergy"
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            width: "50%",
          }}
        >
          <DiversityMultipleBarComponent data={diversity} />
        </div>
        <div
          className="secondhalfprogress"
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            width: "55%",
          }}
        ></div>
      </div>
    </div>
  ) : (
    <>
      <div className="d-flex flex-column flex-space-between">
        <div
          className="d-flex flex-row flex-space-between"
          style={{ marginBottom: "3%" }}
        >
          <div
            className="firsthalfprogressenergy"
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
              width: "45%",
            }}
          >
            <div>
              <DiversityMenAndWomen
                timePeriods={timePeriods}
                timePeriodValues={timePeriodValues}
                locationOption={locationOption}
                brief={brief}
                categories={[
                  "Males out of total employees",
                  "Females out of total employees",
                ]}
                shortenedMap={{
                  "Males out of total employees": "Males",
                  "Females out of total employees": "Females",
                }}
                title="Current employees by Gender"
              />
            </div>

            <div style={{ height: "0%" }}></div>
          </div>
          <div
            className="secondhalfprogress"
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
              width: "55%",
            }}
          >
            <div>
              <DiversityMenAndWomen
                timePeriods={timePeriods}
                timePeriodValues={timePeriodValues}
                locationOption={locationOption}
                brief={brief}
                categories={[
                  "Employees less than 30 years of age",
                  "Employees between 30-50 years of age",
                  "Employees more than 50 years of age",
                ]}
                shortenedMap={{
                  "Employees less than 30 years of age": "<30",
                  "Employees between 30-50 years of age": "30-50",
                  "Employees more than 50 years of age": ">50",
                }}
                title="Age Wise Distribution of Current Employees"
              />
            </div>
            <div style={{ height: "0%" }}></div>
          </div>
        </div>
        <div
          className="d-flex flex-row flex-space-between"
          style={{ marginBottom: "3%" }}
        >
          <div
            className="firsthalfprogressenergy"
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
              width: "45%",
            }}
          >
            <div>
              <DiversityMenAndWomen
                timePeriods={timePeriods}
                timePeriodValues={timePeriodValues}
                locationOption={locationOption}
                brief={brief}
                categories={[
                  "New hires by Gender Male",
                  "New hires by Gender Female",
                ]}
                shortenedMap={{
                  "New hires by Gender Male": "Males",
                  "New hires by Gender Female": "Females",
                }}
                title="New hires by Gender"
              />
            </div>

            <div style={{ height: "0%" }}></div>
          </div>
          <div
            className="secondhalfprogress"
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
              width: "55%",
            }}
          >
            <div>
              <DiversityMenAndWomen
                timePeriods={timePeriods}
                timePeriodValues={timePeriodValues}
                locationOption={locationOption}
                brief={brief}
                categories={[
                  "New hires by age groups (<30)",
                  "New hires by age groups (30-50)",
                  "New hires by age groups (>50)",
                ]}
                shortenedMap={{
                  "New hires by age groups (<30)": "<30",
                  "New hires by age groups (30-50)": "30-50",
                  "New hires by age groups (>50)": ">50",
                }}
                title="Age Wise Distribution of New Hire Employees"
              />
            </div>
            <div style={{ height: "0%" }}></div>
          </div>
        </div>
      </div>
    </>
  );
};

export default DiversitySingleTimeSingleLoc;
