import React from "react";
import SafetyBarFourtyEight from "./FrameworkFourtyEight/SafetyBarFourtyEight";
import SafetyBar from "./FrameworkOne/SafetyBar";
import { StackedSafety } from "./FrameworkOne/StackedSafety";
import { StackedSafetyNonPerm } from "./FrameworkOne/StackedSafetyNonPermanant";
import SafetyBarForRetirement from "./FrameworkOne/SafetyBarForRetirement";
import SafetyBarComp from "./FrameworkOne/SafetyBarComp";

const SafetySingleLocSingleTime = ({
  companyFramework,
  dataOne,
  titleOne,
  dataThree,
  dataTwo,
  titleTwo,
  titleThree,
  brief,
  type,
  datafour,
  titlefour,
  datafive,
  titlefive
}) => {
  return companyFramework.includes(1) ? (
    <div className="d-flex flex-column flex-space-between">
      <div
        className="d-flex flex-row flex-space-between"
        style={{ height: "50vh", marginBottom: "3%" }}
      >
        <div style={{ width: "100%" }}>
          <StackedSafety title={titleOne} data={dataOne} />
        </div>
      </div>
      <div
        className="d-flex flex-row flex-space-between"
        style={{ height: "50vh", marginBottom: "3%" }}
      >
        <div style={{ width: "100%" }}>
          <StackedSafetyNonPerm title={titleTwo} data={dataTwo} />
        </div>
      </div>
      <div
        className="d-flex flex-row justify-content-between"
        style={{ marginBottom: "3%" }}
      >
        <div className="w-50">
          <SafetyBar
            type={type}
            title={titleThree}
            matchedDataWaste={dataThree}
          />
        </div>
        <div className="w-50" style={{marginLeft:"20px"}}>
          <SafetyBarForRetirement
            type={type}
            title={titlefour}
            matchedDataWaste={datafour}
          />
        </div>
      </div>
      <div
        className="d-flex flex-row justify-content-between"
        style={{ marginBottom: "3%" }}
      >
        <div className="w-50">
          <SafetyBarComp
            type={type}
            title={titlefive}
            matchedDataWaste={datafive}
          />
        </div>
    
      </div>
    </div>
  ) : (
    <>
      <div className="d-flex flex-column flex-space-between">
        <div
          className="d-flex flex-row flex-space-between"
          style={{ height: "30vh", marginBottom: "3%" }}
        >
          <div
            className="firsthalfprogressenergy"
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
              width: "45%",
            }}
          >
            <div style={{ height: "100%" }}>
              <SafetyBarFourtyEight brief={brief} />
            </div>

            <div style={{ height: "0%" }}></div>
          </div>
          <div
            className="secondhalfprogress"
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
              width: "55%",
            }}
          >
            <div style={{ height: "65%" }}></div>
            <div style={{ height: "0%" }}></div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SafetySingleLocSingleTime;
