import React from "react";
import EnvBarComponent from "./FrameworkOne/EnvBarComponent";
import WasteRecyPie from "../Waste/FrameworkOne/WasteRecyPie";
import BarComponent from "../Energy/Framework48/BarComponent";
import WaterBarFourtyEight from "../Water/FrameworkFourtyEight/WaterBarFourtyEight";
import WasteDonutFourtyEight from "../Waste/FrameworkFourtyEight/WasteDonutFourtyEight";
import EnergyConsumptionChart from "../Energy/FrameworkOne/EnergyConsumptionChart";
import TotalWasteGeneratedByVertical from "../Waste/FrameworkOne/TotalWasteGeneratedByVertical";
import VerticalBarComponentWater from "../Water/FrameworkOne/VerticalBarComponentWater";
import VerticalBarComponent from "../Energy/Framework48/VerticalBarComponent";
import VerticalBarWaterComponent from "../Water/FrameworkFourtyEight/VerticalBarWaterComponent";
import VerticalWasteBarComponent from "../Waste/FrameworkFourtyEight/VerticalWasteBarComponent";

const EnvironmentSingleLocSingleTime = ({
  companyFramework,
  sectorQuestionAnswerDataForGraph,
  timePeriods,
  briefEnergy,
  briefWater,
  briefWaste,
  graphData,
  totalConsumption,
  totalConsumptionTwo,
  totalConsumptionRenewable,
  totalConsumptionNonRenewable,
  locationOption,
  wasteDisposal,
  renewableEnergy,
  nonRenewableEnergy,
  keyTab,
  timePeriodValues,
  matchedDataWater,
  matchedDataWaste
}) => {
  return companyFramework.includes(1) ? (
    <div className="d-flex flex-column flex-space-between">
      <div
        className="d-flex flex-row flex-space-between"
        style={{ marginBottom: "3%" }}
      >
        <div
          className="firsthalfprogressenergy"
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            width: "50%",
          }}
        >
          <div style={{ height: "55vh", marginBottom: "20px" }}>
            <EnergyConsumptionChart
              totalConsumptionRenewable={totalConsumptionRenewable}
              totalConsumptionNonRenewable={totalConsumptionNonRenewable}
            />
          </div>
          <div style={{ height: "55vh" }} className="container">
          <VerticalBarComponentWater
                matchedDataWater={matchedDataWater}
                title={"Water Withdrawal"}
              />
          </div>
        </div>
        <div
          className="secondhalfprogress"
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            width: "50%",
          }}
        >
          <div style={{ height: "55vh" }} className="container">
          <TotalWasteGeneratedByVertical
                  title={"Total Waste Revered"}
                  timePeriods={timePeriods}
                  timePeriodValues={timePeriodValues}
                  locationOption={locationOption}
                  matchedDataWaste={wasteDisposal}
                />
          </div>
          <div style={{ height: "55vh" }} className="container">
          <TotalWasteGeneratedByVertical
                  title={"Total Waste Generated"}
                  timePeriods={timePeriods}
                  timePeriodValues={timePeriodValues}
                  locationOption={locationOption}
                  matchedDataWaste={matchedDataWaste}
                />
          </div>
        </div>
      </div>
    </div>
  ) : (
    // <div className="d-flex flex-column flex-space-between">
    //   <div
    //     className="d-flex flex-row flex-space-between"
    //     style={{ height: "55vh", marginBottom: "3%" }}
    //   >
    //     <div
    //       className="firsthalfprogressenergy"
    //       style={{
    //         display: "flex",
    //         flexDirection: "column",
    //         justifyContent: "space-between",
    //         width: "45%",
    //       }}
    //     >
    //       <div style={{ height: "48%" }}>
    //         <EnvBarComponent
    //           type="energy"
    //           totalConsumptionRenewable={totalConsumptionRenewable}
    //           totalConsumptionNonRenewable={totalConsumptionNonRenewable}
    //         />
    //       </div>

    //       <div style={{ height: "48%" }}>
    //         <EnvBarComponent
    //           type="water"
    //           totalConsumptionRenewable={totalConsumption}
    //           totalConsumptionNonRenewable={totalConsumptionTwo}
    //         />
    //       </div>
    //     </div>
    //     <div
    //       className="secondhalfprogress"
    //       style={{
    //         display: "flex",
    //         flexDirection: "column",
    //         justifyContent: "space-between",
    //         width: "55%",
    //       }}
    //     >
    //       <WasteRecyPie
    //         timePeriods={timePeriods}
    //         timePeriodValues={timePeriodValues}
    //         locationOption={locationOption}
    //         matchedDataWaste={wasteDisposal}
    //       />
    //     </div>
    //   </div>
    //   {/* <div
    //   className="d-flex flex-row flex-space-between"
    //   style={{ height: "22vh", marginBottom: "3%" }}
    // >

    // </div> */}
    // </div>
    <>
      <div className="d-flex flex-column flex-space-between">
        <div
          className="d-flex flex-row flex-space-between"
          style={{  marginBottom: "3%" }}
        >
          <div
            className="firsthalfprogressenergy"
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
              width: "50%",
            }}
          >
            <div style={{ height: "100%" }} className="container">
            <VerticalBarComponent
                    timePeriodValues={timePeriodValues}
                    brief={briefEnergy}
                    timePeriods={timePeriods}
                    type="FUEL"
                  />
            </div>

            <div style={{ height: "100%" }} className="container mt-3">
            <VerticalBarComponent
                    timePeriodValues={timePeriodValues}
                    brief={briefEnergy}
                    timePeriods={timePeriods}
                    type="ELE"
                    />
            </div>
          </div>
          <div
            className="secondhalfprogress"
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
              width: "55%",
            }}
          >
             <div style={{ height: "100%" }} className="container">
             <VerticalBarWaterComponent brief={briefWater} type="FUEL" />
            </div>

            <div style={{ height: "100%" }} className="container mt-3">
            <VerticalWasteBarComponent
                    timePeriods={timePeriods}
                    timePeriodValues={timePeriodValues}
                    locationOption={locationOption}
                    brief={briefWaste}
                    type="GEN"
                  />
            </div>
            {/* <div style={{ height: "100%" }}>
              <WasteDonutFourtyEight brief={briefWaste} />
            </div>
            <div style={{ height: "0%" }}>
       
            </div> */}
          </div>
        </div>
        {/* <div
        className="d-flex flex-row flex-space-between"
        style={{ height: "22vh", marginBottom: "3%" }}
      >
    
      </div> */}
      </div>
    </>
  );
};

export default EnvironmentSingleLocSingleTime;
