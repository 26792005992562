import React, { useEffect, useState } from "react";
import Sidebar from "../../../sidebar/sidebar";
import Header from "../../../header/header";
import TopicDetailComponent from "./components/TopicDetail";
import axios from "axios";
import config from "../../../../config/config.json";
import { Row, Col, Table, Form, Accordion, ListGroup, Button, Card } from "react-bootstrap";
import { Modal } from "react-bootstrap";
import swal from "sweetalert";
import { apiCall } from "../../../../_services/apiCall";
import { useRef } from "react";
import { sweetAlert } from "../../../../utils/UniversalFunction";
import { authenticationService } from "../../../../_services/authentication";
import Review from "../../../../img/sector/reviewing.png";
import Verified from "../../../../img/sector/accept.png";
import Reject from "../../../../img/sector/decline.png";
import NotAnswered from "../../../../img/sector/notAnswer.png";
import { USER_TYPE_CODE_MAPPING } from "../../../../_constants/constants";
import QualitativeQuestionType from "../Sector Questions/QualitativeQuestionType";
import TabularQuestionType from "../Sector Questions/TabularQuestionType";
import YesNoType from "../Sector Questions/YesNoType";
import QuantitativeTrendsType from "../Sector Questions/QuantitativeTrendsType";
import TopComponentIndex from "./components/TopComponentIndex";
import '../../../../component/ProgressBySector/sectorprogress.css'

const Index = (props) => {
  // const questionsIds = props.location?.state?.questionIds
  const [questionList, setQuestionList] = useState();
  const [questionsIds, setQuestionsIds] = useState([]);
  const [quesWidth, setQuesWidth] = useState(6);
  const [ansWidth, setAnsWidth] = useState(6);
  const [hideCol, setHideCol] = useState(false);
  const [sectorQuestionType, setSectorQuestionType] = useState();
  const [uploadItem, setUploadItem] = useState([]);
  const [url, setUrl] = useState("");
  const [loading, setLoading] = useState(false);
  const [questionLoading, setQuestionLoading] = useState(false);
  const [entity, setEntity] = useState([]);
  const [entityList, setEntityList] = useState([]);
  const [menuList, setMenuList] = useState([]);
  const [answeredChanges, setAnsweredChanges] = useState(false);
  const [activeTab, setActiveTab] = useState(0);



  const [AllQuestionList, setAllAllQuestionList] = useState({
    quantitative: [],
    tabular_question: [],
    qualitative: [],
    yes_no: [],
    quantitative_trends: [],
  });
  const [auditStatus, setAuditStatus] = useState({});
  const [questionData, setQuestionData] = useState({
    title: "",
    id: "",
    questionType: "",
    frameworkId: "",
    topicId: "",
    kpiId: "",
    title: "",
    question_detail: [],
    audit_status: "",
    add_row: 0,
    formula: null,
    yes_no_option: null,
    add_note_options: null,
  });

  const [subQuestionData, setSubQuestionData] = useState({
    title: "",
    id: "",
  });

  const [filterQuestionList, setFilterQuestionList] = useState([]);
  const [data, setData] = useState([]);
  const [datas, setDatas] = useState(false);
  const [companyEsgData, setCompanyEsgData] = useState();
  const [answers, setAnswers] = useState([]);
  const [assignedDetails, setAssignedDetails] = useState([]);
  const [financialYearId, setFinancialYearId] = useState(0);
  const [financialYear, setFinancialYear] = useState([]);
  const authValue = JSON.parse(localStorage.getItem("currentUser"));
  const [initalValue, setInitialValue] = useState({});
  const user_Is_head = authValue?.is_head;
  const [answerChangedData, setAnswerAnswerChangedData] = useState([]);
  const [meterList, setMeterList] = useState([]);
  const [processList, setProcessList] = useState([]);
  const ref = useRef();
  const current_role = localStorage.getItem("role");
  const current_user_type_code = USER_TYPE_CODE_MAPPING[current_role];

  let changedData = [];
  useEffect(() => {
    if (entity[0]) {
      getFinancialYear();
    }
  }, [entity[0]]);
  useEffect(() => {
    getFinancialYear();
  }, []);
  useEffect(() => {
    if (filterQuestionList) {
      setQuestionData(filterQuestionList[0]);
      setSelectedRow(filterQuestionList[0]?.id);
      setIneerRowSelectedRow(false);
    }
  }, [filterQuestionList]);

  useEffect(() => {
    if (financialYearId && entity) fetchStoredData();
  }, [financialYearId, entity]);
  const getFinancialYear = async () => {
    const { isSuccess, data } = await apiCall(
      `${config.POSTLOGIN_API_URL_COMPANY}getFinancialYear`,
      {},
      {}
    );

    if (isSuccess) {
      setFinancialYear(data.data);
      if (data?.data?.length) {
        setFinancialYearId(data.data[data?.data?.length - 1].id);
        if (financialYearId && entity) {
          fetchStoredData();
        }
      }
    }
  };
  useEffect(() => {
    setInitialValue({
      starting_month: JSON.parse(localStorage.getItem("currentUser"))
        .starting_month,
      frequency: JSON.parse(localStorage.getItem("currentUser")).frequency,
    });
  }, []);

  const answerWidth = () => {

    setQuesWidth(0);
    setAnsWidth(12);
    setHideCol(true);
  };

  const questionWidth = () => {
    setQuesWidth(6);
    setAnsWidth(6);
    setHideCol(false);
  };



  const settingEntities = async () => {
    if (current_user_type_code === "company") {
      setEntity([{ name: "company", id: 1 }]);

      setEntityList([
        { name: "company", id: 1 },
        { name: "supplier", id: 3 },
      ]);
    }
    if (current_user_type_code === "supplier") {
      const { isSuccess, data, error } = await getSupplierEntities();
      if (isSuccess) {
        setEntityList(
          (data?.entities).map((entity) => ({
            name: entity.register_company_name,
            id: entity.companyId,
          }))
        );

      }
      if (error) {
        swal({
          icon: "error",
          title: "Could not get entity companies!",
          timer: 1000,
        });
      }
    }
  };
  useEffect(() => {
    settingEntities();

  }, []);


  const setQuestionDataFunction = (e, item) => {
    setQuestionData({
      title: item.title,
      id: item.id,
      frameworkId: item?.frameworkId,
      topicId: item?.topicId,
      kpiId: item.kpiId,
      questionType: item.questionType,
      question_detail: item.question_detail,
      qualitativeAnswerBy: item.qualitativeAnswerBy,
      qualitativeStatus: item.qualitativeStatus,
      qualitativeRemark: item.qualitativeRemark,
      add_row: item?.add_row,
      formula: item?.formula,
      yes_no_option: item?.yes_no_option,
      add_note_options: item?.add_note_options,
    });
  };

  const setSubQuestionDataFunction = (e, item) => {
    setSubQuestionData({
      title: item.option,
      id: item.id,
    });
  };

  const getSupplierEntities = async () => {
    return await apiCall(
      `${config.API_URL}getSupplierEntities`,
      {},
      {
        supplier_id:
          current_role === "SUPPLIER" ? authValue.id : authValue.parent_id,
      }
    );
  };

  const setEntityValue = (value) => {
    let entityName = entityList?.filter((entity) => {
      if (entity.id === parseInt(value)) {
        return entity;
      }
    });

    setEntity([{ name: entityName[0]?.name, id: parseInt(value) }]);
  };

  const fetchStoredData = async () => {
    if (financialYearId) {
      const { isSuccess, data } = await apiCall(
        `${config.POSTLOGIN_API_URL_COMPANY}getESGReport`,
        {},
        {
          type: "SQ",
          financial_year_id: financialYearId ? financialYearId : 6,
        },
        "GET"
      );
      if (isSuccess) {
        const responseData = data?.data;
        if (data?.mainCompany && responseData.length === 0) {
        } else {
          if (!responseData?.mainCompany) {
            getSectorQuestion([], [], []);
          }
          const storeData = responseData[0]?.frameworkTopicKpi || "{}";
          setCompanyEsgData(storeData);
          if (!storeData.frameworkId || storeData.frameworkId.length === 0) {
          } else {
            if (responseData.length === 0 && responseData?.mainCompany) {
            } else {
              getSectorQuestion(
                storeData.frameworkId,
                storeData.mandatoryTopicsId
                  .concat(storeData.voluntaryTopicsId)
                  .concat(storeData.customTopicsId),
                storeData.mandatoryKpiId
                  .concat(storeData.voluntaryKpiId)
                  .concat(storeData.customKpiId)
              );
            }
          }
        }
      }
    } else {
    }
  };
  const getSectorQuestion = (
    frameworkIds,
    topicIds,
    kpiIds,
    selectedDesignationId,
    selectedUserId,
    selectedLocationId,
    fromDate,
    toDate,
    selectedStatusId
  ) => {
    setLoading(true);
    let questionId = props.location?.state?.questionIds;
    if (questionId) {
      setFinancialYearId(6);
    }
    const locatStorageIds = localStorage.getItem("questionIds");
    if (locatStorageIds) {
      questionId = [locatStorageIds];
    }
    axios
      .get(
        `${config.POSTLOGIN_API_URL_COMPANY
        }getSectorQuestion?type=CUSTOM&financialYearId=${financialYearId ? financialYearId : 6
        }&questionnaireType=SQ&frameworkIds=[${frameworkIds}]&topicIds=[${topicIds}]&kpiIds=[${kpiIds}]${questionId ? `&questionIds=[${questionId}]` : "&questionIds=undefined"
        }& roleIds=[${selectedDesignationId}]&userIds=[${selectedUserId}]&locationIds=[${selectedLocationId}]&fromDate=${fromDate}&toDate=${toDate}`,
        {
          headers: {
            userId: JSON.parse(localStorage.getItem("currentUser")).id,
            Authorization: `Bearer ${localStorage.getItem("token")}`,
            "Content-Type": "application/json",
          },
        }
      )
      .then(async (response) => {
        setLoading(false);
        getSource();
        getProcess();
        localStorage.removeItem("questionIds");
        if (
          response &&
          response.data &&
          response.data.data &&
          response.data.data.length > 0
        ) {
          setDatas(true);
          const groupedTopicsData = response.data.data.reduce((acc, obj) => {
            const { report_id, topic_name, ...rest } = obj;
            if (!acc[topic_name]) {
              acc[topic_name] = [];
            }
            acc[topic_name].push({ report_id, topic_name, ...rest });
            return acc;
          }, {});
          setData([1]);
          setGroupedTopicsData(groupedTopicsData);


          setAnswers(response.data.answers);
          setAssignedDetails(response?.data?.assignedDetails);
        } else {
          setData([]);
          setQuestionLoading(false);
          setQuestionList([]);
          setFilterQuestionList([]);
          setAnswers([]);
          setAssignedDetails([]);
          setLoading(false);
          setFilterQuestionList([]);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const getSource = async () => {
    const { isSuccess, data } = await apiCall(
      `${config.POSTLOGIN_API_URL_COMPANY}getSource`,
      {},
      {},
      "GET"
    );
    if (isSuccess) {
      const locationArray = data?.data?.reverse().map((item) => ({
        id: item.id,
        location: `${item?.location?.area}, ${item?.location?.city}, ${item?.location?.state}, ${item?.location?.country}, ${item?.location?.zipCode}`,
      }));
      setMeterList(locationArray);
    }
  };
  const getProcess = async () => {
    const { isSuccess, data } = await apiCall(
      `${config.POSTLOGIN_API_URL_COMPANY}getProcess`,
      {},
      { type: "ALL" },
      "GET"
    );
    if (isSuccess) {
      setProcessList(data?.data?.reverse());
    }
  };
  const uploadFile = (files) => {
    setAnsweredChanges(true);
    let tempAnswers = [...answers];
    const selectedFile = files.target.files[0];
    const timestamp = new Date().getTime();
    const fileName = `${timestamp}_${selectedFile.name}`;
    var formdata = new FormData();
    formdata.append("file", selectedFile);
    formdata.append("fileName", fileName);
    formdata.append("filePath", "yasil/");

    var requestOptions = {
      header: {
        "Content-Type": "multipart/form-data",
      },
      method: "POST",
      body: formdata,
      redirect: "follow",
    };

    fetch(
      `${config.AUTH_API_URL_COMPANY
      }uploadFile?current_role=${localStorage.getItem("role")}`,
      requestOptions
    )
      .then((response) => response.text())
      .then(async (result) => {
        let index = uploadItem.index;
        let item = uploadItem.item;
        let url = JSON.parse(result);

        if (tempAnswers.length) {
          let check = tempAnswers.findIndex(
            (value) => value.questionId == item.id
          );

          if (check !== -1) {
            // If proofDocument already exists, update it
            let existingProofDocuments =
              tempAnswers[check]["proofDocument"] || [];
            existingProofDocuments.push(url.url); // Push the new URL
            tempAnswers[check]["proofDocument"] = existingProofDocuments;
            tempAnswers[check]["status"] = true;

            setAnswers(tempAnswers);
          } else {
            // If it's a new proofDocument, create a new entry
            let tempObj = {
              questionId: item?.id,
              questionType: item?.questionType ?? item?.questionType,
              answer_id: -1,
              status: true,
              proofDocument: [url.url], // Store the URL in an array
              frameworkId: item?.frameworkId,
              topicId: item?.topicId,
              kpiId: item?.kpiId,
              title: item?.title,
            };
            tempAnswers.push(tempObj);

            setAnswers(tempAnswers);
          }
        }
      })
      .catch((error) => console.log("error", error));
  };
  const handleAnswers = (item, event, questionType, source, value, performed) => {
    setAnsweredChanges(true);
    const tempAnswers = [...answers];
    const index = tempAnswers.findIndex((obj) => obj?.questionId === item?.id);
    item["questionType"] = questionType;

    switch (questionType) {
      case "tabular_question": {
        handleTabularAnswers(item, event, index, source, value, performed);
        break;
      }
      case "quantitative_trends": {
        handleQunatativeTreds(item, event, index);
        break;
      }
      case "quantitative": {
        handlequantitativeTypes(item, event, index, value);
        break;
      }
      case "yes_no": {
        handleYesNoTypes(item, event, index, source);
        break;
      }
      default: {
        handleOtherTypes(item, event, index);
      }
    }
  };

  const handleAssignedDetails = async () => {
    const { isSuccess, data } = await apiCall(
      `${config.POSTLOGIN_API_URL_COMPANY}getAssignedDetails`,
      {},
      { financialYearId: financialYearId },
      "GET"
    );
    if (isSuccess) {
      setAssignedDetails(data?.assignedDetails);
    }
  };
  const handleTabularAnswers = (item, event, index, source, qValue, performed) => {
    let tempAnswers = answers ? [...answers] : [];
    if (qValue) {
      const d1 = new Date(
        tempAnswers[index]["answer"][item?.indexRow][item?.indexCol - 1]
      );
      const d2 = new Date(event?.target?.value);
      if (d1 > d2) {
        sweetAlert(
          "error",
          `You need to select end date greater than  ${tempAnswers[index]["answer"][item?.indexRow][item?.indexCol - 1]
          }`
        );
        return;
      }
    }
    if (performed === "performed") {
      if (index >= 0) {
        tempAnswers[index]["performed"] = event;
        setAnswers(tempAnswers);
      } else {
        const tempObj = {
          questionId: item?.id,
          questionType: item?.questionType ?? item?.questionType,
          status: true,
          answer: true,
          performed: event,
          source_id: source,
          frameworkId: item?.frameworkId,
          topicId: item?.topicId,
          kpiId: item?.kpiId,
          title: item?.title,
        };
        tempAnswers.push(tempObj);
        setAnswers(tempAnswers);
      }

    } else {
      const isContact =
        typeof event === "string" &&
        (event.includes("yes") || event.includes("no"));
      const targetValue = isContact ? event : event?.target?.value || "";

      if (index >= 0) {
        if (tempAnswers[index] &&
          tempAnswers[index]["combinedAnswers"] &&
          tempAnswers[index]["combinedAnswers"].length > 0 && tempAnswers[index]["combinedAnswers"].find(item => item.sourceId == source)?.answer) {
          tempAnswers[index]["answer"] = tempAnswers[index]["combinedAnswers"].find(item => item.sourceId == source)?.answer;
        }
        //upadte value if index exist
        if (
          tempAnswers[index] &&
          tempAnswers[index]["answer"] &&
          tempAnswers[index]["answer"].length > 0
        ) {
          const value =
            tempAnswers[index]["answer"] &&
            tempAnswers[index]["answer"][item?.indexRow] &&
            tempAnswers[index]["answer"][item?.indexRow][item?.indexCol];
          if (value === 0 || value === "" || value) {
            tempAnswers[index]["answer"][item?.indexRow][item?.indexCol] =
              targetValue;
          } else {
            alert("unexpected question type for answer");
          }
        } else {
          const answer2D = get2DArrayAnswer(item, event);
          tempAnswers[index]["answer"] = answer2D;
          tempAnswers[index]["source_id"] = source;
        }
        const id = tempAnswers[index]["questionId"];
        tempAnswers[index]["questionId"] = id || -1;
        tempAnswers[index]["status"] = true;
        tempAnswers[index]["source_id"] = source;
        tempAnswers[index]["questionType"] = item?.questionType;
        tempAnswers[index]["frameworkId"] = item?.frameworkId;
        tempAnswers[index]["topicId"] = item?.topicId;
        tempAnswers[index]["kpiId"] = item?.kpiId;
        tempAnswers[index]["title"] = item?.title;
        setAnswers(tempAnswers);
      } else {

        const answer2D = get2DArrayAnswer(item, event);

        const tempObj = {
          questionId: item?.id,
          questionType: item?.questionType ?? item?.questionType,
          // answer_id: -1,
          status: true,
          answer: answer2D,
          source_id: source,
          frameworkId: item?.frameworkId,
          topicId: item?.topicId,
          kpiId: item?.kpiId,
          title: item?.title,
          // combinedAnswers : [{sourceId:source,answer:answer2D}],
        };
        tempAnswers.push(tempObj);
        setAnswers(tempAnswers);
      }
    }

  };

  const handleQunatativeTreds = (item, trendsAns, index, formula) => {
    let tempAnswers = (answers && answers.length > 0 && [...answers]) || [];
    const changes = changedData?.filter((it) => it !== item?.title);
    changedData = changes;
    setAnswerAnswerChangedData(changes);
    if (trendsAns === "N.A") {
      let tempObj = {
        questionId: item?.id,
        questionType: item?.questionType ?? item?.questionType,
        answer_id: -1,
        status: true,
        answer: null,
        notApplicable: trendsAns,
        frameworkId: item?.frameworkId,
        topicId: item?.topicId,
        kpiId: item?.kpiId,
        title: item?.title,
      };
      tempAnswers.push(tempObj);
      setAnswers(tempAnswers);
    } else if (index >= 0) {
      tempAnswers[index]["answer"] = trendsAns;
      const id = tempAnswers[index]["id"];
      tempAnswers[index]["answer_id"] = id || -1;
      tempAnswers[index]["status"] = true;
      tempAnswers[index]["questionType"] = item?.questionType;
      tempAnswers[index]["frameworkId"] = item?.frameworkId;
      tempAnswers[index]["topicId"] = item?.topicId;
      tempAnswers[index]["kpiId"] = item?.kpiId;
      tempAnswers[index]["title"] = item?.title;
      setAnswers(tempAnswers);
      if (!formula) {
        sweetAlert("success", `${item?.title} Successfully saved`);
      }
    } else {
      let tempObj = {
        questionId: item?.id,
        questionType: item?.questionType ?? item?.questionType,
        answer_id: -1,
        status: true,
        answer: trendsAns,
        frameworkId: item?.frameworkId,
        topicId: item?.topicId,
        kpiId: item?.kpiId,
        title: item?.title,
      };
      tempAnswers.push(tempObj);
      setAnswers(tempAnswers);
      sweetAlert("success", `${item?.title} Successfully saved`);
    }
  };
  const submitHandler = async (e) => {
    e.preventDefault();
    if (changedData?.length > 0) {
      sweetAlert("error", `You need to save ${changedData}`);
    } else if (answerChangedData?.length > 0) {
      sweetAlert("error", `You need to save ${answerChangedData}`);
    } else {
      let updatedAnswers = answers.filter((obj) => obj.status === true);
      const { isSuccess, data, error } = await apiCall(
        `${config.POSTLOGIN_API_URL_COMPANY}saveAnswerSectorQuestion`,
        {},
        {
          financialYearId: Number(financialYearId),
          data: updatedAnswers,
        },
        "POST"
      );

      if (isSuccess) {
        setAnswers(data?.answers);
      }
      if (error) {
      }
    }
  };
  const handlequantitativeTypes = (item, event, index, qValue) => {
    let tempAnswers = (answers && answers.length > 0 && [...answers]) || [];
    let value = qValue || event?.target?.value;

    if (index >= 0) {
      //upadte value if index exist
      tempAnswers[index]["answer"] = value;
      const id = tempAnswers[index]["id"];
      tempAnswers[index]["answer_id"] = id || -1;
      tempAnswers[index]["status"] = true;
      tempAnswers[index]["frameworkId"] = item?.frameworkId;
      tempAnswers[index]["topicId"] = item?.topicId;
      tempAnswers[index]["kpiId"] = item?.kpiId;
      tempAnswers[index]["title"] = item?.title;
      setAnswers(tempAnswers);
    } else {
      //update value if index donesn't exist
      let tempObj = {
        questionId: item?.id,
        questionType: item?.questionType,
        answer_id: -1,
        status: true,
        answer: value,
        frameworkId: item?.frameworkId,
        topicId: item?.topicId,
        kpiId: item?.kpiId,
        title: item?.title,
      };
      tempAnswers.push(tempObj);
      setAnswers(tempAnswers);
    }
  };
  const handleYesNoTypes = (item, event, index, values) => {
    let tempAnswers = (answers && answers.length > 0 && [...answers]) || [];
    if (index >= 0) {
      const tempAnswer = JSON.parse(tempAnswers[index]["answer"]);
      // const safd= values;
      const updatedAnswers = { ...tempAnswer, [values]: event?.target?.value };
      tempAnswers[index]["answer"] = JSON.stringify(updatedAnswers);
      const id = tempAnswers[index]["id"];
      tempAnswers[index]["answer_id"] = id || -1;
      tempAnswers[index]["status"] = true;
      tempAnswers[index]["frameworkId"] = item?.frameworkId;
      tempAnswers[index]["topicId"] = item?.topicId;
      tempAnswers[index]["kpiId"] = item?.kpiId;
      tempAnswers[index]["title"] = item?.title;
      setAnswers(tempAnswers);
    } else {
      const answers = {
        answer: '', details: '', weblink: ''
      }
      const updatedAnswers = { ...answers, [values]: event?.target?.value };
      let tempObj = {
        questionId: item?.id,
        questionType: item?.questionType,
        answer_id: -1,
        status: true,
        answer: JSON.stringify(updatedAnswers),
        frameworkId: item?.frameworkId,
        topicId: item?.topicId,
        kpiId: item?.kpiId,
        title: item?.title,
      };
      tempAnswers.push(tempObj);
      setAnswers(tempAnswers);
    }

  };
  const handleOtherTypes = (item, event, index) => {
    let tempAnswers = (answers && answers.length > 0 && [...answers]) || [];
    if (index >= 0) {
      //upadte value if index exist
      tempAnswers[index]["answer"] = event?.target?.value;
      const id = tempAnswers[index]["id"];
      tempAnswers[index]["answer_id"] = id || -1;
      tempAnswers[index]["status"] = true;
      tempAnswers[index]["frameworkId"] = item?.frameworkId;
      tempAnswers[index]["topicId"] = item?.topicId;
      tempAnswers[index]["kpiId"] = item?.kpiId;
      tempAnswers[index]["title"] = item?.title;
      setAnswers(tempAnswers);
    } else {
      //update value if index donesn't exist
      let tempObj = {
        questionId: item?.id,
        questionType: item?.questionType,
        answer_id: -1,
        status: true,
        answer: event?.target?.value,
        frameworkId: item?.frameworkId,
        topicId: item?.topicId,
        kpiId: item?.kpiId,
        title: item?.title,
      };
      tempAnswers.push(tempObj);
      setAnswers(tempAnswers);
    }
  };
  const [groupData, setGroupData] = useState([]);
  const [groupedTopicsData, setGroupedTopicsData] = useState(null);
  const [groupQuestions, setGroupQuestions] = useState([]);
  const [activeIndex, setActiveIndex] = useState(null);
  const [activeInnerIndex, setActiveInnerIndex] = useState(null);
  const [questionsDataList, setQuestionsDataList] = useState([]);
  const handleInnerAccordionToggle = async (index, headingName) => {
    setActiveInnerIndex(activeInnerIndex === index ? null : index);
    const questionData = groupData[headingName];
    const groupedQuestions = questionData.reduce((acc, question) => {
      const { report_id } = question;
      if (!acc[report_id]) {
        acc[report_id] = [];
      }
      acc[report_id].push(question);
      return acc;
    }, {});
    const groupedData = questionData.reduce((acc, obj) => {
      if (!acc[obj.report_id]) {
        acc[obj.report_id] = { ...obj };
        acc[obj.report_id].subQuestions = [];
        acc[obj.report_id].subQuestions.push(obj);
      } else {
        if (!acc[obj.report_id].subQuestions) {
          acc[obj.report_id].subQuestions = [];
        }
        acc[obj.report_id].subQuestions.push(obj);
      }
      return acc;
    }, {});

    // Convert the grouped object back to an array
    const result = Object.values(groupedData);
    setGroupQuestions(groupedQuestions);
    setQuestionsDataList(result);
    // setData(questionData);
    setAllAllQuestionList({
      quantitative: [],
      tabular_question: [],
      qualitative: [],
      yes_no: [],
      quantitative_trends: [],
    });
    setQuestionList(result);
    result?.map((item) => {
      // setReviewStatus(item)
      answers.length &&
        answers.filter((answer) => {
          if (answer.questionId === item.id) {
            setAuditStatus((auditStatus) => ({
              ...auditStatus,
              [item.id]: answer.status,
            }));
          }
        });
    });
    setFilterQuestionList(result);
    const data = questionData;
    const newData = {};
    for (let i = 0; i < data.length; i++) {
      const item = data[i];
      const id = item.id;
      newData[id] = {
        question_id: item?.id,
        questionType: item?.questionType ?? item?.questionType,
        answer: "",
        status: false,
      };
      if (item.questionType === "tabular_question") {
        const numberofRow = item.question_detail.filter(
          (d) => d.option_type === "row"
        ).length;
        const numberofColumn = item.question_detail.filter(
          (d) => d.option_type === "column"
        ).length;
        if (item && item.answer && item.answer.length > 0) {
          // Perform any necessary operations with item.answer
        }
        const array2D = Array.from({ length: numberofRow }, () =>
          Array.from({ length: numberofColumn }, () => 0)
        );
        newData[item.id]["answer"] = array2D;
        setAllAllQuestionList((prevState) => ({
          ...prevState,
          tabular_question: [...prevState.tabular_question, item],
        }));
      } else if (item.questionType === "quantitative") {
        setAllAllQuestionList((prevState) => ({
          ...prevState,
          quantitative: [...prevState.quantitative, item],
        }));
      } else if (item.questionType === "qualitative") {
        setAllAllQuestionList((prevState) => ({
          ...prevState,
          qualitative: [...prevState.qualitative, item],
        }));
      } else if (item.questionType === "yes_no") {
        setAllAllQuestionList((prevState) => ({
          ...prevState,
          yes_no: [...prevState.yes_no, item],
        }));
      } else if (item.questionType === "quantitative_trends") {
        newData[item.id]["answer"] = [];
        let i = {
          from_date: "",
          to_date: "",
          meter_id: "",
          process: "",
          reading_value: "",
          note: "",
        };
        newData[item.id]["answer"].push(i);
        setAllAllQuestionList((prevState) => ({
          ...prevState,
          quantitative_trends: [...prevState.quantitative_trends, item],
        }));
      }
    }
  };
  const handleAccordionToggle = async (index, topicName) => {
    setActiveIndex(activeIndex === index ? null : index);
    const questionData = groupedTopicsData[topicName];
    const groupedData = questionData.reduce((acc, obj) => {
      const { report_id, heading, ...rest } = obj;
      if (!acc[heading]) {
        acc[heading] = [];
      }
      acc[heading].push({ report_id, heading, ...rest });
      return acc;
    }, {});
    setGroupData(groupedData);
  };
  const getAuditStatusImage = (id) => {
    switch (auditStatus[id]) {
      case 'IN_VERIFICATION':
        return (
          <img
            src={Review}
            alt="In Verification"
            style={{ width: '80px' }}
            className="notAnsered_question w-25"
            title="In Verification"
          />
        );
      case 'ANSWERED':
        return (
          <img
            src={Review}
            alt="In Review"
            style={{ width: '45%' }}
            title="In Review"
          />
        );
      case 'ACCEPTED':
        return (
          <img
            src={Verified}
            alt="Verified"
            style={{ width: '45%' }}
            title="Verified"
          />
        );
      case 'REJECTED':
        return (
          <img
            src={Reject}
            alt="Rejected"
            style={{ width: '45%' }}
            title="Rejected"
          />
        );
      default:
        return (
          <img
            className="notAnsered_question"
            src={NotAnswered}
            alt="Not Answered"
            title="Not Answered"
          />
        );
    }
  };
  const [submitShow, setSubmitShow] = useState(false);
  const [assignedDetail, setAssignedDetail] = useState(null);
  // const [filterAnswer, setFilterAnswer] = useState(null);
  // const [answersValue, setAnswersValue] = useState(null);
  useEffect(() => {
    setQuestionsDataList(filterQuestionList);
  }, [filterQuestionList]);
  useEffect(() => {
    const currentUser = authenticationService?.currentUserSubject?.getValue();
    const settingsMenu = JSON.parse(localStorage.getItem("menu")).find(
      (item) => item?.url === "sector_questions"
    );
    setMenuList(settingsMenu?.permissions);
  }, []);
  useEffect(() => {
    if (financialYearId) fetchStoredData();
  }, [entity]);
  useEffect(() => {
    if (questionData) {
      const assignedDetail = assignedDetails && assignedDetails?.filter((obj) => obj.questionId == questionData.id);
      setAssignedDetail(assignedDetail && assignedDetail[0]);
      const assignedTo = assignedDetail && assignedDetail[0]?.assignedTo;
      //  const filterAnswer =  answers && answers?.filter((obj) => obj.questionId == questionData.id);   
      //  setAnswersValue((filterAnswer && filterAnswer[0] && filterAnswer[0]["answer"]) || "");
      //  setFilterAnswer(filterAnswer);
      setSubmitShow(assignedTo && assignedTo.includes(String(JSON.parse(localStorage.getItem("currentUser")).id)));
    }
  }, [questionData, answers, answers?.length]);

  useEffect(() => {
    setQuestionsIds(props.location);
    const locatStorageIds = localStorage.getItem("questionIds");
    if (locatStorageIds || props.location?.state?.questionIds) {
      setFinancialYear([
        {
          id: 6,
          financial_year_value: "2023-2024",
        },
      ]);

      setFinancialYearId(6);
      if (6) fetchStoredData();
    }
  }, [props.location?.state?.questionIds]);
  const get2DArrayAnswer = (item, event, qValue) => {
    const isContact =
      typeof event === "string" &&
      (event.includes("yes") || event.includes("no"));

    const numberofRow = item.question_detail.filter(
      (d) => d.option_type === "row"
    ).length;
    const numberofColumn = item.question_detail.filter(
      (d) => d.option_type === "column"
    ).length;
    let answer2D = Array.from({ length: numberofRow }, () =>
      Array.from({ length: numberofColumn }, () => "")
    );
    answer2D[item?.indexRow][item?.indexCol] =
      qValue || isContact ? event : event?.target?.value || "";
    return answer2D;
  };

  const idToLetter = (id) => {
    let base = 97;
    const total = 26;
    const ascii = base + (id % total);

    let char = String.fromCharCode(ascii);
    return char;
  };

  const handleFilterHandler = (
    selectedFrameworkId,
    selectedTopicId,
    selectedKpiId,
    selectedDesignationId,
    selectedUserId,
    selectedLocationId,
    fromDate,
    toDate
  ) => {
    getSectorQuestion(
      selectedFrameworkId,
      selectedTopicId,
      selectedKpiId,
      selectedDesignationId,
      selectedUserId,
      selectedLocationId,
      fromDate,
      toDate
    );
  };
  const [show, setShow] = useState(false);
  const [selectedHeading, setSelectedHeading] = useState(null);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [reassignShow, setReassignShow] = useState(false);
  const handleReassignClose = () => setReassignShow(false);
  const handleReassignShow = () => setReassignShow(true);
  const [selectedRow, setSelectedRow] = useState(null);
  const [selectedSection, setSelectedSection] = useState();
  const [availableSection, setAvailableSection] = useState([]);

  const handleRowClick = (index) => {

    setAnsweredChanges(false);
    setSelectedRow(index);
    setIneerRowSelectedRow(false);
  };



  useEffect(() => {

    if (groupedTopicsData)
      setAvailableSection(Object.keys(groupedTopicsData))
  }, [groupedTopicsData])

  const [searchTerm, setSearchTerm] = useState('');


  const groupedByHeading = selectedSection
    ? groupedTopicsData[selectedSection].reduce((acc, topic) => {
      if (!acc[topic.heading]) {
        acc[topic.heading] = [];
      }
      acc[topic.heading].push(topic);
      return acc;
    }, {})
    : {};

  const [showModalTopic, setShowModalTopic] = useState(false);

  const headingContainerRef = useRef(null);
  const [selectedInnerRow, setIneerRowSelectedRow] = useState(null);

  const [activeIndexAccordion, setActiveIndexAccordion] = useState(null);

  const handleReplaceSection = (section) => {
    setSelectedSection(section);
    setSelectedHeading(null);
    setShowModalTopic(false);
  };

  const filteredItems = groupedByHeading[selectedHeading]
    ? groupedByHeading[selectedHeading].filter((item) => {
      return item?.title?.toLowerCase().includes(searchTerm?.toLowerCase());
    })
    : [];


  const matchedItems = filteredItems.map((item) => {
    const correspondingAnswer = answers.find(
      (answer) => answer.questionId === item.id
    );
    return {
      ...item,
      combinedAnswers: correspondingAnswer ? correspondingAnswer.combinedAnswers : "No Combined",  // Include all properties of the filtered item
      answer: item?.questionType == "quantitative_trends" ? correspondingAnswer ? correspondingAnswer.combinedAnswers : "No Combined" : correspondingAnswer ? correspondingAnswer.answer : 'No Answer'
    };
  });



  const handleHeadingClick = (heading) => {
    setSelectedHeading(selectedHeading === heading ? null : heading);
  };

  const handleInnerRowClick = (index) => {
    setAnsweredChanges(false);
    setSelectedRow(index);
    setIneerRowSelectedRow(index);
  };

  const handleAccordionClick = (index) => {
    setActiveIndexAccordion(activeIndexAccordion === index ? null : index);
  };

  const renderQuestionComponent = (item) => {
    switch (item.questionType) {
      case 'qualitative':
        return <QualitativeQuestionType title={item.title} answer={item.answer} />;
      case 'tabular_question':
        return <TabularQuestionType combinedAnswers={item.combinedAnswers} question_detail={item.question_detail} title={item.title} answer={item.answer} />;
      case 'quantitative':
        return <QualitativeQuestionType title={item.title} answer={item.answer} />;
      case 'yes_no':
        return <YesNoType title={item.title} answer={item.answer} />
      case 'quantitative_trends':
        return <QuantitativeTrendsType title={item.title} answer={item.answer} />
      default:
        return <p>Unknown question type</p>;
    }
  };
    const [sidebarExpanded, setSidebarExpanded] = useState(true);
  
  const handleSidebarToggle = (isOpen) => {
    setSidebarExpanded(isOpen);
  };
  const renderUI = () => {
    const activeTopicKey = Object.keys(groupedTopicsData)[activeTab];
    const activeTopicData = groupedTopicsData[activeTopicKey];
    return <TopicDetailComponent topicData={activeTopicData} answers={answers} assignedDetails={assignedDetails} />;
  };
  return (
    <div
      className="d-flex flex-row mainclass"
      style={{ height: "100vh", overflow: "auto" }}
    >
      <div style={{ flex: sidebarExpanded ? "0 0 21%" : "0 0 60px", position: "sticky", top: 0, zIndex: 999,transition: "flex 0.3s ease" }}>
        <Sidebar dataFromParent={props.location.pathname} />
      </div>
      <div style={{ flex: sidebarExpanded ? "1 1 79%" : "1 1 calc(100% - 60px)",
          transition: "flex 0.3s ease"  }}>
        <div style={{ position: "sticky", top: 0, zIndex: 999 }}>
          <Header />
        </div>
        <div className="main_wrapper p-3" style={{ width: sidebarExpanded ? "79vw" : "calc(100% - 60px)" }}>
          <div className="white-container p-3 scroll-container" style={{ background: "transparent", width: "100%", display: "flex", alignItems: "flex-start", flexDirection: "column", borderRadius: "10px" }}>
            <TopComponentIndex groupedTopicsData={groupedTopicsData} setActiveTab={setActiveTab} />
          </div>
          <div style={{width: sidebarExpanded ? "79vw" : "calc(100% - 60px)"  }} className="p-3">
            {groupedTopicsData && renderUI()}
          </div>

        </div>
      </div>
    </div>
  );
};

export default Index;
