import React, { useEffect, useState } from "react";
import { Form, Row, Col, Dropdown, Button, Modal } from "react-bootstrap";
import RowComponent from "./RowComponent";
import { apiCall } from "../../../../_services/apiCall";
import { FaEdit } from "react-icons/fa";
import swal from "sweetalert";
import config from "../../../../config/config.json";
import { DetailModal } from "./DetailModal";
const TabularComponent = ({
  sourceData,
  getAuditListing,
  setAnsId,
  auditItem,
  financeObject,
  currentUserId,
  assignedToDetails,
  answer,
  apiData,
  setAnswer,
  menu,
  item,
  toDate,
  fromDate,
  sourceChange,
  singleItem,
  selectedSubLocationId
}) => {
  const [selectedSource, setSelectedSource] = useState("");
  const [note, setNote] = useState(answer?.note || [[""]]);
  const [answerIdReal, setAnswerIdReal] = useState();
  const [auditButton, setAuditButton] = useState(false);
  const [confirmTarget, setConfirmTarget] = useState(false);
  const [ro, setRo] = useState();
  const [co, setCo] = useState();
  const [letestAnswer, setLetestAnswer] = useState([]);
  const [targetValue, setTargetValue] = useState([]);

  const [sourceName, setSourceName] = useState("");
  const [remark, setRemark] = useState("");
  const [currentPage, setCurrentPage] = useState(0);

  const columns = item?.details
    ?.filter((detail) => /^column\d*$/.test(detail?.option_type))
    .reverse() || [item?.details];

  const [rows, setRows] = useState(
    item?.details
      ?.filter((detail) => detail?.option_type === "row")
      .reverse() || []
  );
  const [heading, setHeading] = useState(
    item?.details
      ?.filter((detail) => detail?.option_type === "heading")
      .reverse() || []
  );
  const [matrixData, setMatrixData] = useState([]);
  const [emission, setEmissionData] = useState([]);
  const [updatedRows, setUpdatedRows] = useState();
  const [meterList, setMeterList] = useState([]);
  const [proofDocument, setProofDocument] = useState([[]]);
  const [radioValue, setRadioValue] = useState("");
  const [check, setCheck] = useState(false);
  const [edit, setEdit] = useState(true);
  const [status, setStatus] = useState();
  const [showData, setShowData] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [commentModal, setCommentModal] = useState(false);
  const [comment, setComment] = useState([[]]);
  const [file, setFile] = useState("");
  const [exceedMessage, setExceedMessage] = useState("");
  const [target, setTarget] = useState();


  const handleRemarkChange = (e) => {
    const newRemark = e.target.value;
    setRemark(newRemark);
  };

  const cancelTarget = () => {
    setConfirmTarget(false);
  };

  const initialize2DArray = (data) => {
    // Initialize an empty object to track column counts per row
    let columnCounts = {};

    // Iterate through data to determine maximum columns for each row
    data.forEach((item) => {
      if (item.option_type.startsWith("column")) {
        let rowNumber = parseInt(item.option_type.replace("column", ""));
        columnCounts[rowNumber] = (columnCounts[rowNumber] || 0) + 1;
      }
    });

    // Find maximum columns from columnCounts
    let maxColumns = Math.max(...Object.values(columnCounts));

    // Initialize an empty 2D array
    let result = [];

    // Create each row with the determined number of columns
    for (let i = 0; i < Object.keys(columnCounts).length; i++) {
      let row = new Array(maxColumns).fill("");
      result.push(row);
    }

    return result;
  };

  const initializeArray = (numRows, numCols) => {
    const newArray = [];
    for (let i = 0; i < numRows; i++) {
      const row = [];
      for (let j = 0; j < numCols; j++) {
        row.push(""); // Initialize each cell with an empty string or any default value
      }
      newArray.push(row);
    }
    return newArray;
  };

  useEffect(() => {
    if (sourceData) {
      const locationArray = sourceData?.reverse().map((item) => ({
        id: item.id,
        location: `${item?.location?.area}, ${item?.location?.city}, ${item?.location?.state}, ${item?.location?.country}, ${item?.location?.zipCode}`,
      }));
      if (locationArray && locationArray.length) {
        setMeterList(locationArray);
        setSelectedSource(locationArray[0]?.id);
      }
    }
  }, [sourceData]);

  const formatNote = (note) => {
    // Check if note is a string
    if (typeof note === "string") {
      return [[note]]; // Wrap the string in a 2D array
    }

    // Check if note is a 2D array
    if (Array.isArray(note) && Array.isArray(note[0])) {
      return note; // Use it as is
    }

    // If note is not a string or a 2D array, return an empty 2D array
    return [[""]];
  };

  const initializeAnswer = () => {
    if (item && item?.questionId && letestAnswer && letestAnswer.length) {
      let matchedAnswer;
      if (item?.frequency == "CUSTOM") {
        matchedAnswer = selectedSubLocationId ? letestAnswer.find(
          (savedAnswer) =>
            savedAnswer.questionId == item?.questionId &&
            savedAnswer.toDate == toDate &&
            savedAnswer.fromDate == fromDate &&
            savedAnswer.sourceId == sourceChange &&
            savedAnswer.subLocationId == selectedSubLocationId
        ) :letestAnswer.find(
          (savedAnswer) =>
            savedAnswer.questionId == item?.questionId &&
            savedAnswer.toDate == toDate &&
            savedAnswer.fromDate == fromDate &&
            savedAnswer.sourceId == sourceChange
        );
        setAnswerIdReal(matchedAnswer?.id);
        setAnsId(matchedAnswer?.id);
      } else {
        matchedAnswer =  selectedSubLocationId ? letestAnswer.find(
          (savedAnswer) => savedAnswer.questionId == item?.questionId &&
          savedAnswer.subLocationId == selectedSubLocationId
        ) : letestAnswer.find(
          (savedAnswer) => savedAnswer.questionId == item?.questionId
        );
        setAnswerIdReal(matchedAnswer?.id);
        setAnsId(matchedAnswer?.id);
      }

      if (matchedAnswer) {
        setAnswerIdReal(matchedAnswer?.id);
        setAnsId(matchedAnswer?.id);
        setStatus(matchedAnswer?.status);
        try {
          // Parse the answer as a 2D array
          setCheck(true);
          setNote((prevState) => {
            return formatNote(matchedAnswer.note);
          });

          if (Array.isArray(matchedAnswer.proofDocument)) {
            // Check if the first element is an array (indicating a 2D array)
            if (Array.isArray(matchedAnswer.proofDocument[0])) {
              setProofDocument(matchedAnswer.proofDocument);
            } else {
              // It's a 1D array, so wrap it in another array to make it 2D
              setProofDocument([matchedAnswer.proofDocument]);
            }
          } else {
            // If matchedAnswer.proofDocument is not an array, set it to an empty 2D array
            setProofDocument([[]]);
          }

          if (Array.isArray(matchedAnswer.proofDocumentNote)) {
            // Check if the first element is an array (indicating a 2D array)
            if (Array.isArray(matchedAnswer.proofDocumentNote[0])) {
              setComment(matchedAnswer.proofDocumentNote);
            } else {
              // It's a 1D array, so wrap it in another array to make it 2D
              setComment([matchedAnswer.proofDocumentNote]);
            }
          } else {
            // If matchedAnswer.proofDocument is not an array, set it to an empty 2D array
            setComment([[]]);
          }
          const answerObject = JSON.parse(matchedAnswer.answer);

          // Ensure that the parsed answer is a 2D array
          if (Array.isArray(answerObject) && Array.isArray(answerObject[0])) {
            const standaloneOption =
              "Disclosures under this report made on a standalone basis (i.e. only for the entity).";
            const consolidatedOption =
              "On a consolidated basis (i.e. for the entity & all the entities which form a part of its consolidated financial statements, taken together)";
            answerObject[0].forEach((answer) => {
              if (
                answer === standaloneOption ||
                answer === consolidatedOption
              ) {
                setRadioValue(answer);
              }
            });
            setUpdatedRows(answerObject);

            setAnswer((prevAnswer) => ({
              ...prevAnswer,
              questionId: item?.questionId,
              moduleId: item?.moduleId,
              questionType: item?.questionType,
              fromDate: fromDate,
              toDate: toDate,
              questionTitle: item?.title,
              frequency: item?.frequency,
              note: formatNote(matchedAnswer.note),
              comment: matchedAnswer?.comment || [[]],
              proofDocument: matchedAnswer?.proofDocument || [[]],
              answer: JSON.stringify(answerObject),
            }));
            if (matchedAnswer.sourceId) {
              const sourceObject = meterList?.find(
                (source) => source.id == matchedAnswer.sourceId
              );
              if (sourceObject) {
                setSourceName(sourceObject?.location);
                // Set selectedSource to the sourceObject's name or location
                setSelectedSource(sourceObject?.id); // Assuming 'name' is the correct property
                // If 'location' is the correct property, use sourceObject.location instead
              }
            }
          } else {
            console.error(
              "Parsed answer is not a valid 2D array:",
              answerObject
            );
          }
        } catch (error) {
          console.error(
            "Error parsing matchedAnswer.answer:",
            error,
            matchedAnswer,
            item
          );
        }
      } else {
        setCheck(false);
        setAnswer((prevState) => ({
          ...prevState,
          questionId: item?.questionId,
          moduleId: item?.moduleId,
          questionType: item?.questionType,
          questionTitle: item?.title,
          fromDate: fromDate,
          toDate: toDate,

          frequency: item?.frequency,
          note: [[""]],
        }));
        setProofDocument([[]]);
        setNote([[]]);
        const ro =
          item?.details
            ?.filter((detail) => detail?.option_type === "row")
            .reverse() || [];
        const colum =
          item?.details
            ?.filter((detail) => detail?.option_type === "column")
            .reverse() || [];
        const numRows = ro.length;
        const numCols = colum.length;
        if (numRows > 0 && numCols > 0) {
          setUpdatedRows(initializeArray(numRows, numCols));
          setProofDocument(initializeArray(numRows, numCols));
          setComment(initializeArray(numRows, numCols));
          setNote(initializeArray(numRows, numCols));
        } else {
          setUpdatedRows(initialize2DArray(item.details));
          setProofDocument(initialize2DArray(item.details));
          setComment(initialize2DArray(item.details));
          setNote(initialize2DArray(item.details));
        }
      }
    } else {
      setCheck(false);
    }
  };

  const initializeTarget = () => {
    if (item && item?.questionId && apiData && apiData.length) {
      let matchedAnswer;
      if (item?.frequency == "CUSTOM") {
        matchedAnswer = apiData.find(
          (savedAnswer) =>
            savedAnswer.questionId == item?.questionId &&
            savedAnswer.toDate == toDate &&
            savedAnswer.fromDate == fromDate &&
            savedAnswer.sourceId == sourceChange
        );
        if (matchedAnswer) {
          setTarget(JSON.parse(matchedAnswer?.targetData));
        }
      } else {
        matchedAnswer = apiData.find(
          (savedAnswer) => savedAnswer.questionId == item?.questionId
        );

        if (matchedAnswer) {
          setTarget(JSON.parse(matchedAnswer?.targetData));
        }
      }
    }
  };

  useEffect(() => {
    setRows(
      item?.details
        ?.filter((detail) => detail?.option_type === "row")
        .reverse() || []
    );
  }, [item]);

  // Effect to initialize updatedRows based on rows and columns
  useEffect(() => {
    setEdit(true);
    if (item) {
      const rwo =
        item?.details
          ?.filter((detail) => detail?.option_type === "row")
          .reverse() || [];

      setRo(rwo);
      const colum =
        item?.details
          ?.filter((detail) => detail?.option_type === "column")
          .reverse() || [];
      setCo(colum);
      const numRows = rwo.length;
      const numCols = colum.length;
      if (numRows > 0 && numCols > 0) {
        setUpdatedRows(initializeArray(numRows, numCols));
        setProofDocument(initializeArray(numRows, numCols));
        setComment(initializeArray(numRows, numCols));
        setNote(initializeArray(numRows, numCols));
      } else {
        setUpdatedRows(initialize2DArray(item.details));
        setProofDocument(initialize2DArray(item.details));
        setComment(initialize2DArray(item.details));
        setNote(initializeArray(numRows, numCols));
      }
      if (letestAnswer?.length > 0 && Object.keys(letestAnswer[0]).length > 0) {
        initializeAnswer();
      }
    }
  }, [selectedSubLocationId,letestAnswer, toDate, fromDate, item, sourceChange]);

  useEffect(() => {
    initializeAnswer();
  }, [letestAnswer, toDate, fromDate, item, sourceChange]);

  const getReportingAnswer = async () => {
    const { isSuccess, data } = await apiCall(
      `${config.POSTLOGIN_API_URL_COMPANY}getReportingAnswer`,
      {},
      { financialYearId: financeObject, questionId: singleItem.questionId },
      "GET"
    );
    if (isSuccess) {
      setLetestAnswer(data?.answers);
    }
  };

  const getTargetAnswer = async () => {
    const { isSuccess, data } = await apiCall(
      `${config.POSTLOGIN_API_URL_COMPANY}getTargerAnswer`,
      {},
      { financialYearId: financeObject, questionId: singleItem.questionId },
      "GET"
    );
    if (isSuccess) {
console.log(data)
      // setTargetValue(data?.answers);
    }
  };

  useEffect(() => {
    if (singleItem && financeObject) {
      getReportingAnswer();
      // getTargetAnswer();
    }
  }, [singleItem]);

  const handleInputChange = (rowIndex, colId, value) => {
    const updatedRow = [...updatedRows];
    if (!updatedRow[rowIndex]) {
      updatedRow[rowIndex] = [];
    }
    updatedRow[rowIndex][colId] = value;
    setUpdatedRows(updatedRow);
    setAnswer((prevAnswer) => {
      return {
        ...prevAnswer,
        questionId: item?.questionId,
        moduleId: item?.moduleId,
        questionTitle: item?.title,

        questionType: item?.questionType,
        frequency: item?.frequency,
        answer: JSON.stringify(updatedRow),
      };
    });
  };

  const handleProofChange = async (files) => {
    const selectedFile = files.target.files[0];
    const timestamp = new Date().getTime(); // Generate a timestamp
    const fileName = `${timestamp}_${selectedFile.name}`;

    const formdata = new FormData();
    formdata.append("file", selectedFile);
    formdata.append("fileName", fileName);
    formdata.append("filePath", "yasil/");

    const requestOptions = {
      header: {
        "Content-Type": "multipart/form-data", // Set the Content-Type header
      },
      method: "POST",
      body: formdata,
      redirect: "follow",
    };

    try {
      const response = await fetch(
        `${
          config.AUTH_API_URL_COMPANY
        }uploadFile?current_role=${localStorage.getItem("role")}`,
        requestOptions
      );
      const result = await response.text();
      const url = JSON.parse(result);

      setProofDocument((prevState) => {
        const newProofDocument = [...prevState];

        if (!newProofDocument[currentPage]) {
          newProofDocument[currentPage] = [];
        }

        newProofDocument[currentPage] = [
          ...newProofDocument[currentPage],
          url.url,
        ];

        return newProofDocument;
      });

      setAnswer((prevState) => {
        // Clone the current proofDocument
        const newProofDocument = [...prevState.proofDocument];

        // If currentPage doesn't exist yet, initialize it as an empty array
        if (!newProofDocument[currentPage]) {
          newProofDocument[currentPage] = [];
        }

        // Update the specific page
        newProofDocument[currentPage] = [
          ...newProofDocument[currentPage],
          url.url,
        ];

        // Return the updated state
        return {
          ...prevState,
          questionId: item?.questionId,
          questionTitle: item?.title,

          moduleId: item?.moduleId,
          questionType: item?.questionType,
          frequency: item?.frequency,
          proofDocument: newProofDocument,
        };
      });

      setFile(`${timestamp}_${selectedFile.name}`);
      setCommentModal(true);
    } catch (error) {
      if (error) {
        swal({
          icon: "error",
          title: error,
          timer: 1000,
        });
      }
    }
  };

  const handleAccept = async () => {
    const { isSuccess, error, data } = await apiCall(
      `${config.POSTLOGIN_API_URL_COMPANY}validateAnswers`,
      {},
      {
        questionId: item?.questionId,
        answerId: answerIdReal,
        questionType: item.questionType,
        remark: remark,
        validation: "ACCEPTED",
        financialYearId: financeObject,
      },
      "POST"
    );

    if (isSuccess) {
      setRemark("");
      setAuditButton(false);
      getAuditListing();
    }

    if (error) {
      swal({
        icon: "error",
        title: data.message,
        timer: 1000,
      });
    }
  };

  const handleReject = async () => {
    if (remark) {
      const { isSuccess, error, data } = await apiCall(
        `${config.POSTLOGIN_API_URL_COMPANY}validateAnswers`,
        {},
        {
          questionId: item?.questionId,
          answerId: answerIdReal,
          questionType: item.questionType,
          remark: remark,
          validation: "REJECTED",
          financialYearId: financeObject,
        },
        "POST"
      );

      if (isSuccess) {
        setRemark("");
        getAuditListing();
      }

      if (error) {
        swal({
          icon: "error",
          title: data.message,
          timer: 1000,
        });
      }
    } else {
      swal({
        icon: "error",
        title: "Please enter remark",
        timer: 1000,
      });
    }
  };

  const handleCommentChange = (value) => {
    setComment((prevState) => {
      const newProofDocument = [...prevState];

      if (!newProofDocument[currentPage]) {
        newProofDocument[currentPage] = [];
      }

      newProofDocument[currentPage] = [...newProofDocument[currentPage], value];

      return newProofDocument;
    });

    setAnswer((prevState) => {
      // Clone the current proofDocumentNote, or initialize as an empty array if undefined
      const newProofDocument = [...(prevState.proofDocumentNote || [])];

      // If currentPage doesn't exist yet, initialize it as an empty array
      if (!newProofDocument[currentPage]) {
        newProofDocument[currentPage] = [];
      }

      // Update the specific page
      newProofDocument[currentPage] = [...newProofDocument[currentPage], value];

      // Return the updated state
      return {
        ...prevState,
        questionId: item?.questionId,
        moduleId: item?.moduleId,
        questionTitle: item?.title,
        questionType: item?.questionType,
        frequency: item?.frequency,
        proofDocumentNote: newProofDocument,
      };
    });
  };

  function checkValuesAgainstTarget(target, values, rows, columns) {
    let result = []; // To store details of rows and columns exceeding the target

    // Traverse the 2D arrays
    for (let i = 0; i < values.length; i++) {
      for (let j = 0; j < values[i].length; j++) {
        // Compare each value in 'values' with its corresponding value in 'target'
        if (target[i][j] > 1 && Number(values[i][j]) > Number(target[i][j])) {
          result.push({
            row: rows[i]?.option || `Row ${i + 1}`, // Map row index to row.option
            column: columns[j]?.option || `Column ${j + 1}`, // Map column index to column.option
            target: target[i][j],
            value: values[i][j],
            exceededBy: values[i][j] - target[i][j],
          });
        }
      }
    }

    return result;
  }

  const handleSubmit = async () => {
    const { isSuccess, data } = await apiCall(
      `${config.POSTLOGIN_API_URL_COMPANY}saveAnswerReportingQuestion`,
      {},
      {
        ...answer,
        financialYearId: financeObject,
        source: selectedSource,
        subLocationId:selectedSubLocationId,
        exceedMessage: null,
        fromDate: fromDate,
        toDate: toDate,
      },
      "POST"
    );
    if (isSuccess) {
      getReportingAnswer();
    }
  };

  const updateMatrixData = (rowIndex, colId, value) => {
    setMatrixData((prevMatrixData) => {
      const updatedMatrixData = prevMatrixData.map((row, index) => {
        if (index === rowIndex) {
          return {
            ...row,
            [colId]: value,
          };
        } else {
          return row;
        }
      });
      return updatedMatrixData;
    });
  };

  const handleNoteChange = (e) => {
    const newNote = e.target.value;
    setNote((prevNote) => {
      // Clone the current note array
      const updatedNote = [...prevNote];

      // Ensure currentIndex exists in the array
      if (!updatedNote[currentPage]) {
        updatedNote[currentPage] = [];
      }

      // Update the note at currentIndex
      updatedNote[currentPage] = newNote;

      return updatedNote;
    });

    setAnswer((prevAnswer) => ({
      ...prevAnswer,
      note:
        prevAnswer.note &&
        Array.isArray(prevAnswer.note) &&
        Array.isArray(prevAnswer.note[0])
          ? [
              ...prevAnswer.note.slice(0, currentPage),
              [newNote],
              ...prevAnswer.note.slice(currentPage + 1),
            ]
          : [[newNote]], // Convert to 2D array if it's not already
    }));
  };

  const handleNextPage = () => {
    if (
      item?.details.some(
        (item) => item.option_type == "row" && item.option == 1
      )
    ) {
      if (updatedRows?.length <= currentPage + 1) {
        const tmpupdatedRows = [...updatedRows];
        const tempdoc = [...proofDocument];
        const tempCom = [...comment];
        const tempNote = [...note];
        const ro =
          item?.details
            ?.filter((detail) => detail?.option_type === "row")
            .reverse() || [];
        const colum =
          item?.details
            ?.filter((detail) => detail?.option_type === "column")
            .reverse() || [];
        const numRows = ro.length;
        const numCols = colum.length;
        const initializeArrays = initializeArray(numRows, numCols);
        tmpupdatedRows.push(initializeArrays[0]);
        tempdoc.push(initializeArrays[0]);
        tempCom.push(initializeArrays[0]);
        tempNote.push(initializeArrays[0]);
        setNote(tempNote);
        setProofDocument(tempdoc);
        setComment(tempCom);
        setUpdatedRows(tmpupdatedRows);
      }
    }

    const currentRowData = [...rows];
    setMatrixData((prevMatrixData) => [...prevMatrixData, currentRowData]);
    setCurrentPage((prevPage) => prevPage + 1);
  };
  const getEmissionCalculation = async () => {
    const { isSuccess, data } = await apiCall(
      `${config.POSTLOGIN_API_URL_COMPANY}getEmissionCalculation`,
      {},
      {
        questionId: singleItem.questionId,
        unit: 'KG',
        value: answer?.answer,
      },
      "GET"
    );
    if (isSuccess) {
      setEmissionData(data?.data);
    }
  };
  useEffect(() => {
    if(singleItem?.questionId === 451 || singleItem?.questionId === 452)
    getEmissionCalculation();
  }, [answer]);
  const handlePlusPage = () => {
    if (
      item?.details.some(
        (item) => item.option_type == "row" && item.option == 1
      )
    ) {
      const temp = {
        option_type: "row",
        option: "1",
        rules: null,
      };
      setRows((prevRows) => [...prevRows, temp]);
      if (updatedRows?.length <= currentPage + 1) {
        const tempProofdocs = [...proofDocument];
        const tmpupdatedRows = [...updatedRows];
        const tempCom = [...comment];
        const tempNote = [...note];
        const ro =
          item?.details
            ?.filter((detail) => detail?.option_type === "row")
            .reverse() || [];
        const colum =
          item?.details
            ?.filter((detail) => detail?.option_type === "column")
            .reverse() || [];
        const numRows = ro.length;
        const numCols = colum.length;
        const initializeArrays = initializeArray(numRows, numCols);
        tempProofdocs.push([]);
        tmpupdatedRows.push(initializeArrays[0]);
        tempCom.push([]);
        tempNote.push([]);
        setProofDocument(tempProofdocs);
        setComment(tempCom);
        setNote(tempNote);
        setUpdatedRows(tmpupdatedRows);
      }
    }

    const currentRowData = [...rows];

    setMatrixData((prevMatrixData) => [...prevMatrixData, currentRowData]);
    setCurrentPage((prevPage) => prevPage + 1);
  };

  const handlePreviousPage = () => {
    setCurrentPage((prevPage) => prevPage - 1);
  };

  useEffect(() => {}, [updatedRows]);

  const handleEditClick = () => {
    setEdit(!edit);
  };

  return (
    <Form>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "flex-end",
          alignItems: "flex-end",
        }}
      >
        {check &&
          assignedToDetails?.assignedTo?.length > 0 &&
          assignedToDetails.assignedTo.some(
            (id) => parseInt(id, 10) === currentUserId
          ) && (
            <FaEdit
              style={{
                cursor: "pointer",
                marginLeft: "10px",
                height: "20px",
                width: "20px",
                color: edit ? "black" : "#BFD7E0",
              }}
              onClick={handleEditClick}
            />
          )}
      </div>
      {rows.length > 0 && (
        <RowComponent
          item={item}
          edit={edit}
          comment={comment[currentPage]}
          handleCommentChange={handleCommentChange}
          note={note[currentPage]}
          setIsModalOpen={setIsModalOpen}
          handleProofChange={handleProofChange}
          isModalOpen={isModalOpen}
          proofDocument={proofDocument[currentPage]}
          DetailModal={
            <DetailModal
              isModalOpen={isModalOpen}
              setIsModalOpen={setIsModalOpen}
              data={showData}
            />
          }
          menu={menu}
          heading={heading}
          assignedToDetails={assignedToDetails}
          currentUserId={currentUserId}
          answer={answer}
          setAnswer={setAnswer}
          rowIndex={currentPage}
          file={file}
          columns={columns}
          setProofDocument={setProofDocument}
          commentModal={commentModal}
          setCommentModal={setCommentModal}
          rowData={rows[currentPage]}
          updatedRows={updatedRows}
          handleNoteChange={handleNoteChange}
          handleInputChange={handleInputChange}
          updateMatrixData={updateMatrixData}
          radioValuee={radioValue}
          check={check}
          emission={emission}
        />
      )}

    
    

      {menu === "audit" && (
        <Col md={8}>
          <Form.Group controlId="formInput12">
            <Form.Label className="custom-label">Remark</Form.Label>
            <Form.Control
              style={{ backgroundColor: "#Dfebef" }}
              type="text"
              as="textarea"
              value={remark}
              onChange={handleRemarkChange}
            />
          </Form.Group>
        </Col>
      )}

      <div className="d-flex justify-content-between align-items-center mt-3">
        {assignedToDetails?.assignedTo?.length > 0 &&
          assignedToDetails.assignedTo.some(
            (id) => parseInt(id, 10) === currentUserId
          ) &&
          (menu === "audit" ? (
            <>
              {auditItem &&
                auditItem?.matchingAuditors.find(
                  (d) => d.answerId == answerIdReal
                )?.auditerId == currentUserId && (
                  <>
                    {auditItem?.matchingAuditors.find(
                      (d) => d.answerId == answerIdReal
                    )?.remark == null ||
                    auditItem?.matchingAuditors
                      .find((d) => d.answerId == answerIdReal)
                      ?.remark?.every((remark) => remark.id != currentUserId) ||
                    auditItem.matchingAuditors?.some(
                      (auditor) =>
                        Number(auditor?.auditerId) === Number(currentUserId)
                    ) ? (
                      <>
                        <Button
                          className="btn btn-secondary"
                          disabled={auditButton}
                          style={{
                            borderColor: "white",
                            backgroundColor: "#3F88A5",
                            padding: "1%",
                            paddingLeft: "1.5%",
                            paddingRight: "1.5%",
                            width: "15%",
                            minWidth: "100px",
                            maxWidth: "150px",
                          }}
                          onClick={handleAccept}
                        >
                          Accept
                        </Button>
                        <Button
                          className="btn btn-secondary"
                          style={{
                            borderColor: "white",
                            backgroundColor: "#3F88A5",
                            padding: "1%",
                            paddingLeft: "1.5%",
                            paddingRight: "1.5%",
                            width: "15%",
                            minWidth: "100px",
                            maxWidth: "150px",
                            marginLeft: "10px",
                          }}
                          disabled={auditButton}
                          onClick={handleReject}
                        >
                          Reject
                        </Button>
                      </>
                    ) : status === "ACCEPTED" || status === "REJECTED" ? (
                      <p>This answer has been audited.</p>
                    ) : null}
                  </>
                )}
            </>
          ) : (
            <Button
              className="btn btn-secondary"
              disabled={item?.frequency === "CUSTOM" && !fromDate}
              style={{
                borderColor: "white",
                backgroundColor: "#3F88A5",
                padding: "1%",
                paddingLeft: "1.5%",
                paddingRight: "1.5%",
                width: "15%",
                minWidth: "100px",
                maxWidth: "150px",
              }}
              onClick={handleSubmit}
            >
              Submit
            </Button>
          ))}

        {!(
          assignedToDetails?.assignedTo?.length > 0 &&
          assignedToDetails.assignedTo.some(
            (id) => parseInt(id, 10) === currentUserId
          )
        ) &&
          (menu === "audit" ? (
            <>
              <>
                {auditItem &&
                  auditItem?.matchingAuditors.find(
                    (d) => d.answerId == answerIdReal
                  )?.auditerId == currentUserId &&
                  answerIdReal && (
                    <>
                      {auditItem?.matchingAuditors.find(
                        (d) => d.answerId == answerIdReal
                      )?.remark == null ||
                      auditItem?.matchingAuditors
                        .find((d) => d.answerId == answerIdReal)
                        ?.remark?.every(
                          (remark) => remark.id != currentUserId
                        ) ||
                      auditItem.matchingAuditors?.some(
                        (auditor) =>
                          Number(auditor?.auditerId) === Number(currentUserId)
                      ) ? (
                        <>
                          <Button
                            className="btn btn-secondary"
                            disabled={auditButton}
                            style={{
                              borderColor: "white",
                              backgroundColor: "#3F88A5",
                              padding: "1%",
                              paddingLeft: "1.5%",
                              paddingRight: "1.5%",
                              width: "15%",
                              minWidth: "100px",
                              maxWidth: "150px",
                            }}
                            onClick={handleAccept}
                          >
                            Accept
                          </Button>
                          <Button
                            className="btn btn-secondary"
                            disabled={auditButton}
                            style={{
                              borderColor: "white",
                              backgroundColor: "#3F88A5",
                              padding: "1%",
                              paddingLeft: "1.5%",
                              paddingRight: "1.5%",
                              width: "15%",
                              minWidth: "100px",
                              maxWidth: "150px",
                              marginLeft: "10px",
                            }}
                            onClick={handleReject}
                          >
                            Reject
                          </Button>
                        </>
                      ) : status === "ACCEPTED" || status === "REJECTED" ? (
                        <p>This answer has been audited.</p>
                      ) : null}
                    </>
                  )}
              </>
            </>
          ) : (
            <></>
          ))}

        <div
          className="d-flex"
          style={{ justifyContent: "flex-end", gap: "5px", flex: 1 }}
        >
     

          {rows[0] &&
            rows[0]?.option === "1" &&
            assignedToDetails?.assignedTo?.length > 0 &&
            assignedToDetails.assignedTo.some(
              (id) => parseInt(id, 10) === currentUserId
            ) && (
              <Button
                className="btn btn-primary"
                style={{
                  borderColor: "white",
                  backgroundColor: "#3F88A5",
                  padding: "1%",
                  paddingLeft: "2%",
                  paddingRight: "2%",
                  width: "5%",
                  minWidth: "50px",
                  maxWidth: "150px",
                }}
                onClick={handlePlusPage}
                title="Add more"
              >
                +
              </Button>
            )}
        </div>
      </div>

      <div className="d-flex justify-content-center mt-3 gap-3">
        {/* {rows.length !== 1 && currentPage >= 1 && ( */}
        <div className="arrow-container">
          <Button
            // disabled={!(rows.length !== 1 && currentPage >= 1)}
            disabled={
              !(updatedRows?.length > rows?.length
                ? updatedRows.length !== 1 && currentPage >= 1
                : rows.length !== 1 && currentPage >= 1)
            }
            onClick={handlePreviousPage}
            style={{
              borderColor: "white",
              backgroundColor: "#3F88A5",
              padding: "1%",
              paddingLeft: "1.5%",
              paddingRight: "1.5%",
              width: "15%",
              minWidth: "100px",
              maxWidth: "150px",
            }}
          >
            Previous
          </Button>
        </div>
        {/* )} */}
        {/* {rows.length !== 1 && currentPage <= rows.length - 2 && ( */}
        <div className="arrow-container">
          <Button
            onClick={handleNextPage}
            disabled={
              !(updatedRows?.length > rows?.length
                ? updatedRows.length !== 1 &&
                  currentPage <= updatedRows.length - 2
                : rows?.length !== 1 && currentPage <= rows.length - 2)
            }
            style={{
              borderColor: "white",
              backgroundColor: "#3F88A5",
              padding: "1%",
              paddingLeft: "1.5%",
              paddingRight: "1.5%",
              width: "15%",
              minWidth: "100px",
              maxWidth: "150px",
            }}
          >
            Next
          </Button>
        </div>
        {/* )} */}
      </div>
      <Modal show={confirmTarget} onHide={cancelTarget} size="md" centered>
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
          <Form.Group controlId="formComment">
            <Form.Label>
              {exceedMessage && (
                <p
                  style={{
                    marginTop: "10px",
                    color: "red",
                    fontWeight: "bold",
                  }}
                >
                  {exceedMessage}
                </p>
              )}

              <div>Do you want to continue?</div>
            </Form.Label>
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={cancelTarget}>
            Cancel
          </Button>
          <Button variant="primary" onClick={handleSubmit}>
            Save
          </Button>
        </Modal.Footer>
      </Modal>
    </Form>
  );
};

export default TabularComponent;
