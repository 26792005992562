import React, { useState, useEffect, useRef } from "react";
import "./teamworkload.css";
import "../ProgressBySector/sectorprogress.css";
import no from "../../img/no.png";

// Correctly destructure the prop
const TeamWorkLoadMaker = ({ orgMakerOtatusData }) => {
  // Add container ref to measure actual width
  const chartContainerRef = useRef(null);
  const [containerWidth, setContainerWidth] = useState(0);
  
  // State for tooltip
  const [tooltip, setTooltip] = useState({
    visible: false,
    x: 0,
    y: 0,
    content: "",
    value: 0,
    percentage: 0
  });

  // Calculate the maximum value dynamically for scaling
  const calculateMaxValue = () => {
    if (!orgMakerOtatusData || orgMakerOtatusData.length === 0) return 300; // Default max value
    
    const maxFromData = Math.max(...orgMakerOtatusData.map(item => item.value || 0));
    // Round up to nearest 100 for clean y-axis values
    return Math.ceil(maxFromData / 100) * 100;
  };

  // Helper function to get the bar color based on type - using colors from orgMakerOtatusData
  const getBarColor = (type) => {
    const item = orgMakerOtatusData.find(item => item.name === type);
    return item ? item.color : "#C3D4DB"; // Default light blue/gray if not found
  };

  // Get reporting URL for the bar type
  const getReportingUrl = (type) => {
    // You can customize these URLs based on your reporting system structure
    const typeToUrlMapping = {
      "Approved": "/reports/approved",
      "Requires Revision": "/reports/revision",
      "Drafts": "/reports/drafts",
      "Submitted for Review": "/reports/pending"
    };
    
    return typeToUrlMapping[type] || "/reports";
  };

  // Handler for mouse enter (show tooltip)
  const handleMouseEnter = (event, type, value, percentage) => {
    const rect = event.currentTarget.getBoundingClientRect();
    
    setTooltip({
      visible: true,
      x: rect.left + rect.width / 2,
      y: rect.top - 10,
      content: type,
      value,
      percentage
    });
  };

  // Handler for mouse leave (hide tooltip)
  const handleMouseLeave = () => {
    setTooltip({ ...tooltip, visible: false });
  };

  // Handler for bar click (redirect to reporting URL)
  const handleBarClick = (type) => {
    const url = getReportingUrl(type);
    window.location.href = url;
  };

  // Update container width on mount and resize
  useEffect(() => {
    const updateWidth = () => {
      if (chartContainerRef.current) {
        setContainerWidth(chartContainerRef.current.clientWidth);
      }
    };

    // Initial measurement
    updateWidth();
    
    // Add resize listener
    window.addEventListener('resize', updateWidth);
    
    // Clean up
    return () => window.removeEventListener('resize', updateWidth);
  }, []);

  // Render the chart with grid lines and y-axis
  const renderBarChart = () => {
    // Fixed chart dimensions
    const chartHeight = 500;
    const leftMargin = 70;
    const bottomMargin = 80;
    const topMargin = 70;
    const barWidth = 50; // Changed from 80px to 50px to match the other component
    
    // Use actual measured container width for responsive layout
    // Fall back to 650px if measurement not available yet
    const chartWidth = containerWidth || 650;
    
    // Auto-adjustable gap calculation based on actual container width
    const totalBars = orgMakerOtatusData.length;
    const availableWidth = chartWidth - leftMargin - (barWidth * totalBars);
    const gapBetweenBars = Math.max(20, availableWidth / (totalBars + 1)); // Minimum 20px gap
    
    // Dynamic yAxisMax based on data
    const yAxisMax = calculateMaxValue();
    
    // Create y-axis ticks with dynamic intervals
    const numTicks = 5; // Desired number of ticks
    const tickStep = Math.ceil(yAxisMax / numTicks);
    const yAxisTicks = [];
    for (let i = 0; i <= yAxisMax; i += tickStep) {
      yAxisTicks.push(i);
    }
    
    // Calculate scale factor to normalize bar heights
    const scaleFactor = (chartHeight - topMargin - bottomMargin) / yAxisMax;

    return (
      <div 
        className="chart-container" 
        style={{ 
          position: 'relative',
          height: `${chartHeight}px`,
          width: '100%',
          margin: '0 auto',
        }}
        ref={chartContainerRef}
      >
        {/* Chart Title */}
        <div style={{
          position: 'absolute',
          top: '10px',
          width: '100%',
          textAlign: 'center',
          fontSize: '20px',
          fontWeight: 'bold',
        }}>
          Maker (Data Entry/Submission Role)
        </div>

        {/* Y-axis title */}
        <div style={{
          position: 'absolute',
          left: '10px',
          top: '50%',
          transform: 'rotate(-90deg) translateX(-50%)',
          transformOrigin: 'left center',
          fontSize: '14px',
          width: '120px',
          textAlign: 'center',
        }}>
          Number of Entries
        </div>

        {/* Y-axis grid lines and labels */}
        <div style={{
          position: 'absolute',
          left: leftMargin,
          top: topMargin,
          height: `${chartHeight - topMargin - bottomMargin}px`,
          width: `${chartWidth - leftMargin}px`,
        }}>
          {/* Grid lines */}
          {yAxisTicks.map((value) => (
            <div key={value} style={{
              position: 'absolute',
              left: '0',
              top: `${chartHeight - bottomMargin - (value * scaleFactor) - topMargin}px`,
              width: '100%',
              borderTop: '1px solid #ddd',
            }}>
              <span style={{
                position: 'absolute',
                left: '-45px',
                top: '-10px',
                fontSize: '14px',
                color: '#666',
                width: '40px',
                textAlign: 'right',
              }}>
                {value}
              </span>
            </div>
          ))}
        </div>

        {/* Bottom border (x-axis) */}
        <div style={{
          position: 'absolute',
          left: leftMargin,
          top: `${chartHeight - bottomMargin}px`,
          width: `${chartWidth - leftMargin}px`,
          borderTop: '1px solid #000',
        }}></div>

        {/* Bars */}
        <div style={{
          position: 'absolute',
          left: leftMargin,
          top: topMargin,
          height: `${chartHeight - topMargin - bottomMargin}px`,
          width: `${chartWidth - leftMargin}px`,
        }}>
          {orgMakerOtatusData.map((item, index) => {
            const value = item.value || 0;
            const percentage = item.percentage || 0;
            const barHeight = value * scaleFactor;
            
            // Calculate position with auto-adjustable gaps
            const leftPosition = gapBetweenBars * (index + 1) + (barWidth * index);
            
            return (
              <div 
                key={item.name} 
                style={{
                  position: 'absolute',
                  left: `${leftPosition}px`,
                  bottom: '0',
                  width: `${barWidth}px`,
                  height: `${Math.max(barHeight, 1)}px`, // Minimum height for visibility
                  backgroundColor: item.color,
                  transition: 'height 0.5s ease, left 0.3s ease', // Smooth transition for both height and position
                  cursor: 'pointer',
                }}
                onMouseEnter={(e) => handleMouseEnter(e, item.name, value, percentage)}
                onMouseLeave={handleMouseLeave}
                onClick={() => handleBarClick(item.name)}
              >
                {/* Value label on top of bar */}
                <div style={{
                  position: 'absolute',
                  top: '-25px',
                  width: '100%',
                  textAlign: 'center',
                  fontSize: '14px',
                  fontWeight: 'bold',
                }}>
                  {value}
                </div>
              </div>
            );
          })}
        </div>

        {/* X-axis labels - wrapped for longer labels */}
        <div style={{
          position: 'absolute',
          left: leftMargin,
          top: `${chartHeight - bottomMargin + 10}px`,
          width: `${chartWidth - leftMargin}px`,
        }}>
          {orgMakerOtatusData.map((item, index) => {
            // Split the name into words for potential wrapping
            const words = item.name.split(' ');
            const firstLine = words.length > 1 ? words.slice(0, -1).join(' ') : item.name;
            const secondLine = words.length > 1 ? words[words.length - 1] : '';
            
            // Calculate position with auto-adjustable gaps (same as bars)
            const labelPosition = gapBetweenBars * (index + 1) + (barWidth * index);
            
            return (
              <div key={item.name} style={{
                position: 'absolute',
                left: `${labelPosition}px`, 
                width: `${barWidth}px`,
                textAlign: 'center',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                transition: 'left 0.3s ease', // Smooth transition when spacing changes
              }}>
                <div style={{
                  fontSize: '13px',
                  fontWeight: '500',
                  lineHeight: '1.2',
                }}>
                  {firstLine}
                </div>
                {secondLine && (
                  <div style={{
                    fontSize: '13px',
                    fontWeight: '500',
                    lineHeight: '1.2',
                    marginTop: '2px',
                  }}>
                    {secondLine}
                  </div>
                )}
              </div>
            );
          })}
        </div>

        {/* Tooltip */}
        {tooltip.visible && (
          <div 
            style={{
              position: 'fixed',
              left: `${tooltip.x}px`,
              top: `${tooltip.y}px`,
              transform: 'translate(-50%, -100%)',
              backgroundColor: 'rgba(0, 0, 0, 0.8)',
              color: 'white',
              padding: '8px 12px',
              borderRadius: '4px',
              fontSize: '14px',
              pointerEvents: 'none',
              zIndex: 1000,
              boxShadow: '0 2px 5px rgba(0, 0, 0, 0.2)',
            }}
          >
            <div style={{ fontWeight: 'bold' }}>{tooltip.content}</div>
            <div>Count: {tooltip.value}</div>
            <div>Percentage: {tooltip.percentage}%</div>
            <div style={{ fontSize: '12px', marginTop: '4px', fontStyle: 'italic' }}>
              Click to view detailed report
            </div>
          </div>
        )}
      </div>
    );
  };

  return (
    <div
      style={{
        width: "100%",
        height: "100%",
        background: "white",
        borderRadius: "10px",
        padding: "10px",
        display: "flex",
        flexDirection: "column",
        justifyContent: "flex-start",
        alignItems: "center",
      }}
    >
      <div style={{ 
        width: '100%',
        height: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'flex-start',
      }}>
        {renderBarChart()}
      </div>
    </div>
  );
};

export default TeamWorkLoadMaker;