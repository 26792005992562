import React, { useState } from "react";
import EnergyConsumptionFourtyEight from "../Energy/Framework48/EnergyConsumptionFourtyEight";
import VerticalBarComponent from "../Energy/Framework48/VerticalBarComponent";
import VerticalBarWaterComponent from "../Water/FrameworkFourtyEight/VerticalBarWaterComponent";
import ShowTotalValue from "../Water/FrameworkFourtyEight/ShowTotalValue";
import VerticalWasteBarComponent from "../Waste/FrameworkFourtyEight/VerticalWasteBarComponent";
import EnergyConsumptionCard from "../Energy/FrameworkOne/TotalEnergyConsumption";
import EnergyConsumptionChart from "../Energy/FrameworkOne/EnergyConsumptionChart";
import CommonBarComponent from "../Energy/FrameworkOne/CommonBarComponent";
import BarComponentWater from "../Water/FrameworkOne/BarComponentWater";
import VerticalBarComponentWater from "../Water/FrameworkOne/VerticalBarComponentWater";
import TotalWasteGeneratedByVertical from "../Waste/FrameworkOne/TotalWasteGeneratedByVertical";


const AllLocAllTime = ({
  companyFramework,
  timePeriods,
  brief,
  totalConsumptionRenewable,
  totalConsumptionNonRenewable,
  renewableEnergy,
  nonRenewableEnergy,
  timePeriodValues,
  briefEnergy,
  briefWater,
  bioMedicalBrief,
  briefWaste,
  totalConsumption,
  totalConsumptionTwo,
  wasteDisposal,
  matchedDataWater,
  matchedWaterDis,
  matchedDataWaste
}) => {

  return (
    <div>
      {/* Bar View - Now always showing this view */}
      {companyFramework && companyFramework.includes(1) ? (
           <div className="d-flex flex-column flex-space-between">
           <div
             className="d-flex flex-row flex-space-between"
             style={{ marginBottom: "3%" }}
           >
             <div
               className="firsthalfprogressenergy"
               style={{
                 display: "flex",
                 flexDirection: "column",
                 justifyContent: "space-between",
                 width: "45%",
               }}
             >
               <div  style={{ height: "53vh" }} className="my-2">
               <EnergyConsumptionChart
                   totalConsumptionRenewable={totalConsumptionRenewable}
                   totalConsumptionNonRenewable={totalConsumptionNonRenewable}
                 />
               </div>

               <div  className="my-2 container">
               <TotalWasteGeneratedByVertical
                    title={"Total Waste Generated"}
                    timePeriods={timePeriods}
                    timePeriodValues={timePeriodValues}
                    matchedDataWaste={matchedDataWaste}
                  />
               </div>
             </div>
             <div
               className="secondhalfprogress"
               style={{
                 display: "flex",
                 flexDirection: "column",
                 justifyContent: "space-between",
                 width: "55%",
               }}
             >
               <div className="my-2 container">
               <VerticalBarComponentWater
                  matchedDataWater={matchedDataWater}
                  title={"Water Withdrawal"}
                />
               </div>
               <div  className="my-2 container">
               <TotalWasteGeneratedByVertical
                    title={"Total Waste Disposed"}
                    timePeriods={timePeriods}
                    timePeriodValues={timePeriodValues}
                    matchedDataWaste={wasteDisposal}
                  />
               </div>
             </div>
           </div>
         </div>
      ) : (
        <>
          <div className="d-flex flex-column flex-space-between">
            <div
              className="d-flex flex-row flex-space-between"
              style={{ marginBottom: "2%" }}
            >
              <div
                className="firsthalfprogressenergy"
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-between",
                  width: "50%",
                  marginTop: "10px",
                }}
              >
                <div style={{ height: "65%" }} className="my-2">
                  <EnergyConsumptionFourtyEight
                    timePeriodValues={timePeriodValues}
                    brief={briefEnergy}
                    timePeriods={timePeriods}
                    type="ALL"
                  />
                </div>

                <div style={{ height: "65%" }} className="my-2">
                  <ShowTotalValue brief={briefWater} type="ALL" />
                </div>
              </div>
              <div
                className="secondhalfprogress"
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-between",
                  width: "50%",
                  marginTop: "10px",
                }}
              >
                <div style={{ height: "65%" }} className="my-2">
                  <EnergyConsumptionFourtyEight
                    timePeriodValues={timePeriodValues}
                    brief={briefWaste}
                    timePeriods={timePeriods}
                    type="ALL"
                  />
                </div>
                <div style={{ height: "65%" }} className="my-2">
                  <EnergyConsumptionFourtyEight
                    timePeriodValues={timePeriodValues}
                    brief={briefEnergy}
                    timePeriods={timePeriods}
                    type="REW"
                  />
                </div>
              </div>
            </div>

            <div className="d-flex flex-row flex-space-between">
              <div
                className="firsthalfprogressenergy"
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-between",
                  width: "50%",
                  marginTop: "10px",
                }}
              >
                <div style={{ height: "100%" }} className="my-2 container">
                  <VerticalBarComponent
                    timePeriodValues={timePeriodValues}
                    brief={briefEnergy}
                    timePeriods={timePeriods}
                    type="FUEL"
                  />
                </div>

                <div style={{ height: "100%" }} className="my-2 container">
                  <VerticalBarWaterComponent brief={briefWater} type="FUEL" />
                </div>
              </div>
              
              <div
                className="secondhalfprogress"
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-between",
                  width: "50%",
                  marginTop: "10px",
                }}
              >
                <div style={{ height: "100%" }} className="my-2 container">
                  <VerticalBarComponent
                    timePeriodValues={timePeriodValues}
                    brief={briefEnergy}
                    timePeriods={timePeriods}
                    type="ELE"
                  />
                </div>
                <div style={{ height: "100%" }} className="my-2 container">
                  <VerticalWasteBarComponent
                    timePeriods={timePeriods}
                    timePeriodValues={timePeriodValues}
                    brief={briefWaste}
                    type="GEN"
                  />
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default AllLocAllTime;