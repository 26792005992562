import React, { useState, useEffect } from "react";
import { apiCall } from "../../_services/apiCall";
import config from "../../config/config.json";

const Triggers = () => {
  const [locations, setLocations] = useState([]);
  const [categories, setCategories] = useState([]);
  const [frameworkIds, setFrameworkIds] = useState([]);
  const [questions, setQuestions] = useState({});
  const [selectedFinancialYear, setSelectedFinancialYear] = useState("");
  const [financialYears, setFinancialYears] = useState([]);
  const [selectedLocation, setSelectedLocation] = useState("");
  const [selectedCategory, setSelectedCategory] = useState("");
  const [triggerValues, setTriggerValues] = useState({});
  const [triggerValue, setTriggerValue] = useState({});
  const [unitOptions, setUnitOptions] = useState([]);
  const [identifier, setIdentifier] = useState();
  const [timePeriodOptions, setTimePeriodOptions] = useState([]);
  const [fromDate, setFromDate] = useState();
  const [toDate, setToDate] = useState();
  const [monthValue, setMonthValue] = useState();

  const getFrequency = async () => {
    const { isSuccess, data } = await apiCall(
      `${config.POSTLOGIN_API_URL_COMPANY}getFrequency`,
      {},
      { financialYearId: selectedFinancialYear },
      "GET"
    );
    if (isSuccess) {
      setIdentifier(data.data);
    }
  };

  // Fetch Financial Years and set the latest one by default
  const getFinancialYears = async () => {
    try {
      const { isSuccess, data } = await apiCall(
        `${config.POSTLOGIN_API_URL_COMPANY}getFinancialYear`,
        {},
        {}
      );
      if (isSuccess && data?.data.length > 0) {
        setFinancialYears(data.data);
        setSelectedFinancialYear(data.data[data.data.length - 1]?.id); // Auto-select last value
      }
    } catch (error) {
      console.error("Error fetching financial years:", error);
    }
  };

  // Fetch Locations
  const getSource = async () => {
    try {
      const response = await apiCall(
        `${config.POSTLOGIN_API_URL_COMPANY}getSource`,
        {},
        {},
        "GET"
      );
      if (response.isSuccess) {
        setLocations(response.data.data);
      }
    } catch (error) {
      console.error("Error fetching locations:", error);
    }
  };

  // Fetch Categories
  const getUnitCategory = async () => {
    try {
      const { isSuccess, data } = await apiCall(
        `${config.POSTLOGIN_API_URL_COMPANY}getUnitCatagory`,
        {},
        { type: "ALL" },
        "GET"
      );

      if (isSuccess) {
        const filteredCategories = data.data.filter((item) => item.id !== 1); // Remove id === 1
        setCategories(filteredCategories);
      }
    } catch (error) {
      console.error("Error fetching categories:", error);
    }
  };

  // Fetch Framework Data
  const fetchFrameworkApi = async () => {
    try {
      const { isSuccess, data } = await apiCall(
        `${config.POSTLOGIN_API_URL_COMPANY}getFramework`,
        {},
        { type: "ALL" }
      );
      if (isSuccess) {
        setFrameworkIds(data?.data.map((item) => item.id));
        return data?.data.map((item) => item.id);
      }
    } catch (error) {
      console.error("Error fetching framework data:", error);
    }
  };



 // Get saved trigger values when financial year, location, or category changes
const getSavedTriggerValues = async (questionId,fromDate) => {
  if (!selectedFinancialYear || !selectedLocation) {
    return;
  }

  try {
    const { isSuccess, data } = await apiCall(
      `${config.POSTLOGIN_API_URL_COMPANY}getTriggerValues`,
      {},
      {
        financialYearId: selectedFinancialYear,
        sourceId: Number(selectedLocation),
        questionId:Number(questionId),
        fromDate:fromDate,
      },
      "GET"
    );

    if (isSuccess && data?.data) {
      const formattedTriggerValues = {};
      const formattedTriggerValue = {}; // For storing monthValue in triggerValue state

      data.data.forEach((item) => {
        const { 
          questionId, 
          minTargetData, 
          maxTargetData, 
          unit, 
          fromDate, 
          frequency, 
          rowId, 
          columnId 
        } = item;
        
        // Extract month value from the fromDate
        const monthValue = parseInt(fromDate.split("-")[1], 10);
        const compositeKey = `${questionId}-${monthValue}`;
        
        // Initialize question object if it doesn't exist
        if (!formattedTriggerValues[questionId]) {
          formattedTriggerValues[questionId] = {};
          formattedTriggerValue[questionId] = { monthValue };
        }
        
        // Initialize composite key object if it doesn't exist
        if (!formattedTriggerValues[questionId][compositeKey]) {
          formattedTriggerValues[questionId][compositeKey] = {
            monthValue,
            frequency: frequency || "",
            data: [],
            minTriggerArray: [],
            maxTriggerArray: []
          };
        }
        
        // Make sure arrays exist and have proper dimensions
        const ensureArrayDimensions = (array, rowIdx, colIdx) => {
          if (!array) array = [];
          while (array.length <= rowIdx) {
            array.push([]);
          }
          while (array[rowIdx].length <= colIdx) {
            array[rowIdx].push("");
          }
          return array;
        };
        
        // Ensure data arrays have proper dimensions
        const dataArray = ensureArrayDimensions(
          formattedTriggerValues[questionId][compositeKey].data,
          rowId,
          columnId
        );
        
        const minArray = ensureArrayDimensions(
          formattedTriggerValues[questionId][compositeKey].minTriggerArray,
          rowId,
          columnId
        );
        
        const maxArray = ensureArrayDimensions(
          formattedTriggerValues[questionId][compositeKey].maxTriggerArray,
          rowId,
          columnId
        );
        
        // Set the values in the arrays
        dataArray[rowId][columnId] = unit || "";
        minArray[rowId][columnId] = minTargetData || "";
        maxArray[rowId][columnId] = maxTargetData || "";
        
        // Update the arrays in the state object
        formattedTriggerValues[questionId][compositeKey].data = dataArray;
        formattedTriggerValues[questionId][compositeKey].minTriggerArray = minArray;
        formattedTriggerValues[questionId][compositeKey].maxTriggerArray = maxArray;
      });

      // Update both state variables
      console.log(formattedTriggerValues,formattedTriggerValue,"sadasdew")
      setTriggerValues(formattedTriggerValues);
      setTriggerValue(formattedTriggerValue);
      
      console.log("Loaded saved trigger values:", formattedTriggerValues);
    }
  } catch (error) {
    console.error("Error fetching saved trigger values:", error);
  }
};
  const getReportingQuestions = async () => {
    try {
      if (!selectedFinancialYear) return;
      const frameworkIds = await fetchFrameworkApi();
      if (!frameworkIds?.length) return;

      if (frameworkIds.includes(48)) {
        const response = await apiCall(
          `${config.POSTLOGIN_API_URL_COMPANY}getReportingQuestion`,
          {},
          { financialYearId: selectedFinancialYear, frameworkIds },
          "GET"
        );

        if (response.isSuccess) {
          const data = response.data;
          const groupedByModuleName = data.data
            .filter((item) => item.categoryId != 1) // Remove categoryId 1
            .reduce((acc, item) => {
              if (!acc[item.categoryId]) {
                acc[item.categoryId] = [];
              }
              acc[item.categoryId].push(item);
              return acc;
            }, {});

          setQuestions(groupedByModuleName);
        }
      } else {
        const response = await apiCall(
          `${config.POSTLOGIN_API_URL_COMPANY}getSetTargetQuestion`,
          {},
          {},
          "GET"
        );
        if (response.isSuccess) {
          const dataa = response.data.data;
          setCategories(dataa);
          setQuestions(dataa);
        }
      }
    } catch (error) {
      console.error("Error fetching reporting questions:", error);
    }
  };

  // Handle category selection and set units accordingly
  const handleCategoryChange = (categoryId) => {
    setSelectedCategory(categoryId);
    const selectedCategory = categories.find(
      (cat) => cat.id === Number(categoryId)
    );
    if (selectedCategory && selectedCategory.units) {
      setUnitOptions(selectedCategory.units);
    }
  };

  

  const months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];

  const start = JSON.parse(localStorage.getItem("currentUser")).starting_month;

  const calculateDateRange = (type, period, startingMonth, year) => {
    const startMonth = ((startingMonth - 1 + (period - 1) * type) % 12) + 1;
    const startYear =
      year + Math.floor((startingMonth - 1 + (period - 1) * type) / 12);
    const endMonth = ((startMonth - 1 + type) % 12) + 1;
    const endYear = startYear + Math.floor((startMonth - 1 + type) / 12);

    const formatDate = (month, year) =>
      `${year}-${month < 10 ? `0${month}` : month}`;

    return {
      fromDate: formatDate(startMonth, startYear),
      toDate: formatDate(endMonth, endYear),
    };
  };

  useEffect(() => {
    if (identifier) {
      let options = [];
      if (identifier === "MONTHLY") {
        // const options =
        //   start === 1
        //     ? months
        //     : [...months.slice(start - 1), ...months.slice(0, start - 1)];

        // // Map options to { label, value } format
        // const formattedOptions = options.map((month, index) => ({
        //   label: month,
        //   value: ((start + index - 1) % 12) + 1, // Ensures values cycle correctly from 1 to 12
        // }));

        const options =
        start === 1
          ? months
          : [...months.slice(start - 1), ...months.slice(0, start - 1)];

      // Map options to { label, value } format
      const formattedOptions = options.map((month, index) => ({
        label: month,
        value: ((start + index - 1) % 12) + 1, // Ensures values cycle correctly from 1 to 12
      }));

      setTimePeriodOptions(formattedOptions);
        

      } else if (identifier === "QUARTERLY") {
        for (let i = start - 1; i < start + 11; i += 3) {
          const quarterStartIndex = i % 12;
          const quarterEndIndex = (i + 3) % 12;
          const quarter = `${months[quarterStartIndex]} - ${
            months[(quarterEndIndex - 1 + 12) % 12]
          }`;
          options.push({ label: quarter, value: options.length + 1 });
        }
        setTimePeriodOptions(options);
      } else if (identifier === "HALF_YEARLY") {
        for (let i = start - 1; i < start + 11; i += 6) {
          const halfStartIndex = i % 12;
          const halfEndIndex = (i + 6) % 12;
          const half = `${months[halfStartIndex]} - ${
            months[(halfEndIndex - 1 + 12) % 12]
          }`;
          options.push({ label: half, value: options.length + 1 });
          setTimePeriodOptions(options);
        }
      } else if (identifier === "YEARLY") {
        const yearlyStartIndex = start - 1;
        options = [
          {
            label: `${months[yearlyStartIndex]} - ${
              months[(yearlyStartIndex - 1 + 12) % 12]
            }`,
            value: 1,
          },
        ];
        setTimePeriodOptions(options);
      }
    }
  }, [identifier, start]);

 

  const handlePeriodChange = (questionId, field, value) => {
    setTriggerValues({})
    const years = financialYears.find(
      (item) => item.id === selectedFinancialYear
    ).financial_year_value;
  
    const year = parseInt(years.split("-")[0]);
  
    let earliestFromDate = null;
    let latestToDate = null;
  
    let dateRange;
  
    if (identifier === "HALF_YEARLY") {
      const sixMonthLater = (start + 6) % 12; // Wrap around December if necessary
      const halfYear = sixMonthLater === (value + 1) % 12 ? 2 : 1;
      dateRange = calculateDateRange(6, halfYear, start, year);
    } else if (identifier === "QUARTERLY") {
      const quarter = Math.floor(value / 3) + 1;
      dateRange = calculateDateRange(3, quarter, start, year);
    } else if (identifier === "MONTHLY") {
      const startIndex = start - 1;
      const firstMonthIndex =
        (value - startIndex + months.length) % months.length;
      dateRange = calculateDateRange(1, firstMonthIndex, start, year);
    } else if (identifier === "YEARLY") {
      dateRange = calculateDateRange(12, 1, start, year);
    }
    
    if (dateRange) {
      if (
        !earliestFromDate ||
        new Date(dateRange.fromDate) < new Date(earliestFromDate)
      ) {
        earliestFromDate = dateRange.fromDate;
      }
      if (
        !latestToDate ||
        new Date(dateRange.toDate) > new Date(latestToDate)
      ) {
        latestToDate = dateRange.toDate;
      }
    }
  
    setFromDate(earliestFromDate);
    setToDate(latestToDate);
    
    getSavedTriggerValues(questionId,earliestFromDate);
    console.log(earliestFromDate,value)
    setMonthValue(value)

  };

  const handleTabularChangeChange = (questionId, rowId, columnId, key, value) => {
    const currentMonthValue =
      triggerValue[questionId]?.monthValue || (fromDate ? parseInt(fromDate.split("-")[1], 10) : null);
  
    if (!currentMonthValue) {
      console.error("No month value selected. Please select a frequency first.");
      return;
    }
  
    const compositeKey = `${questionId}-${currentMonthValue}`;
  
    setTriggerValues((prev) => {
      const questionValues = prev[questionId] || {};
      const periodValues = questionValues[compositeKey] || { 
        monthValue: currentMonthValue, 
        frequency: prev[questionId]?.[compositeKey]?.frequency || "",
        data: [], 
        minTriggerArray: [], 
        maxTriggerArray: [] 
      };
  
      // Handle frequency update separately as it's not part of the arrays
      if (key === "frequency") {
        return {
          ...prev,
          [questionId]: {
            ...questionValues,
            [compositeKey]: {
              ...periodValues,
              frequency: value,
            },
          },
        };
      }
  
      // Get number of rows and columns from question details
      const questionDetails = questions[selectedCategory]?.find(q => q.questionId === questionId)?.details ?? [];
      const rowCount = questionDetails.filter(detail => detail.option_type === "row").length;
      const colCount = questionDetails.filter(detail => detail.option_type === "column").length;
  
      // Initialize `data` as a proper 2D array with default values
      let newData = periodValues.data?.length
        ? [...periodValues.data.map(row => [...row])] // Deep copy for 2D array
        : Array.from({ length: rowCount }, () => Array(colCount).fill(""));
  
      // Initialize `minTriggerArray` and `maxTriggerArray` as 2D arrays
      let updatedMinTriggerArray = periodValues.minTriggerArray?.length
        ? [...periodValues.minTriggerArray.map(row => [...row])]
        : Array.from({ length: rowCount }, () => Array(colCount).fill(""));
  
      let updatedMaxTriggerArray = periodValues.maxTriggerArray?.length
        ? [...periodValues.maxTriggerArray.map(row => [...row])]
        : Array.from({ length: rowCount }, () => Array(colCount).fill(""));
  
      // Ensure arrays have correct dimensions
      while (newData.length <= rowId) {
        newData.push(Array(colCount).fill(""));
      }
      while (newData[rowId].length <= columnId) {
        newData[rowId].push("");
      }
  
      while (updatedMinTriggerArray.length <= rowId) {
        updatedMinTriggerArray.push(Array(colCount).fill(""));
      }
      while (updatedMinTriggerArray[rowId].length <= columnId) {
        updatedMinTriggerArray[rowId].push("");
      }
  
      while (updatedMaxTriggerArray.length <= rowId) {
        updatedMaxTriggerArray.push(Array(colCount).fill(""));
      }
      while (updatedMaxTriggerArray[rowId].length <= columnId) {
        updatedMaxTriggerArray[rowId].push("");
      }
  
      // Update the appropriate array based on key
      if (key === "unit") {
        newData[rowId][columnId] = value;
      } else if (key === "minTriggerValue") {
        updatedMinTriggerArray[rowId][columnId] = value;
      } else if (key === "maxTriggerValue") {
        updatedMaxTriggerArray[rowId][columnId] = value;
      }
  
      return {
        ...prev,
        [questionId]: {
          ...questionValues,
          [compositeKey]: {
            ...periodValues,
            data: newData,
            minTriggerArray: updatedMinTriggerArray,
            maxTriggerArray: updatedMaxTriggerArray,
          },
        },
      };
    });
  };
  
  // Updated Save Data function to handle tabular data structure correctly
  const saveTriggerData = async (question, questionId, rowId, colId) => {
    // Get current selected month value from triggerValue
    const currentMonthValue = triggerValue[questionId]?.monthValue || 
      (fromDate ? parseInt(fromDate.split("-")[1], 10) : null);
    
    if (!currentMonthValue) {
      alert("No month value selected. Please select a frequency first.");
      return;
    }
    
    // Construct the composite key
    const compositeKey = `${questionId}-${currentMonthValue}`;
    
    // Get cell values from the triggerValues structure
    const cellData = triggerValues[questionId]?.[compositeKey];
    
    if (!cellData) {
      alert("No data found for this cell. Please fill in the values first.");
      return;
    }
    
    const unit = cellData.data?.[rowId]?.[colId] || "";
    const minTriggerValue = cellData.minTriggerArray?.[rowId]?.[colId] || "";
    const maxTriggerValue = cellData.maxTriggerArray?.[rowId]?.[colId] || "";
    const frequency = cellData.frequency || "";
    
    if (
      !selectedFinancialYear ||
      !selectedLocation ||
      !minTriggerValue ||
      !maxTriggerValue ||
      !unit ||
      !fromDate
    ) {
      alert("Please fill all required fields (Financial Year, Location, Frequency, Unit, Min and Max Trigger Values)");
      return;
    }
  
    try {
      // Get the row and column details for the specific cell
      const rowDetail = question.details
        .slice()
        .reverse()
        .filter((detail) => detail.option_type === "row")[rowId];
      
      const columnDetail = question.details
        .slice()
        .reverse()
        .filter((detail) => detail.option_type === "column")[colId];
      
      const cellLabel = `${rowDetail.option} - ${columnDetail.option}`;
      
      const { isSuccess, data } = await apiCall(
        `${config.POSTLOGIN_API_URL_COMPANY}saveTriggerData`,
        {},
        {
          questionId,
          questionTitle: question.title || "ABC",
          financialYearId: selectedFinancialYear,
          sourceId: Number(selectedLocation),
          fromDate: fromDate || undefined,
          toDate: toDate || undefined,
          unit: unit,
          // frequency: frequency,
          minTrigger: minTriggerValue,
          maxTrigger: maxTriggerValue,
          questionType: "tabular_question",
          rowId: rowId,
          columnId: colId,
          cellLabel: cellLabel
        },
        "POST"
      );
      
      if (isSuccess) {
        // alert(`Successfully saved trigger values for ${cellLabel}`);
        getSavedTriggerValues(questionId,fromDate);
      }
    } catch (error) {
      console.error("Error saving trigger data:", error);
      alert("Error saving data. Please try again.");
    }
  };

  useEffect(() => {
    getFinancialYears();
    getSource();
    getUnitCategory();
  }, []);

  useEffect(() => {
    if (selectedFinancialYear) {
      getFrequency();
      getReportingQuestions();

      // Reset trigger values when financial year changes
      setTriggerValues({});
    }
  }, [selectedFinancialYear]);

  // Reset trigger values when location changes
  useEffect(() => {
    if (selectedLocation) {
      setTriggerValues({});
    }
  }, [selectedLocation]);

  return (
    <div
      style={{
        background: "white",
        borderRadius: "15px",
        width: "100%",
        padding: "2rem",    
        overflow: "auto",
      }}
    >
      <div
        className="d-flex justify-content-between buttoncont"
        style={{
          marginBottom: "25px",
          width: "71vw",
          overflow: "auto",
          gap: "10px",
        }}
      >
        {/* Financial Year Dropdown */}
        <select
          value={selectedFinancialYear}
          onChange={(e) => setSelectedFinancialYear(e.target.value)}
          className="form-select"
        >
          <option value="">Select Financial Year</option>
          {financialYears.map((year, index) => (
            <option key={index} value={year.id}>
              {year.financial_year_value}
            </option>
          ))}
        </select>

        {/* Location Dropdown */}
        <select
          value={selectedLocation}
          onChange={(e) => setSelectedLocation(e.target.value)}
          className="form-select"
        >
          <option value="">Select Location</option>
          {locations.map((location, index) => (
            <option key={index} value={location.id}>
              {location?.unitCode ||
                `${location?.location?.area || ""}, ${
                  location?.location?.city || ""
                }, ${location?.location?.state || ""}, ${
                  location?.location?.country || ""
                }`.trim()}
            </option>
          ))}
        </select>

        {/* Category Dropdown */}
        <select
          value={selectedCategory}
          onChange={(e) => handleCategoryChange(e.target.value)}
          className="form-select"
        >
          <option value="">Select Category</option>
          {categories.map((category, index) => (
            <option key={index} value={category.id}>
              {category?.title}
            </option>
          ))}
        </select>
      </div>

      {frameworkIds && frameworkIds.length > 0 && frameworkIds.includes(48)
        ? selectedCategory &&
          questions[selectedCategory] && (
            <div className="questions-container">
              <table className="table">
                <thead>
                  <tr>
                    <th>Question Title</th>
                    <th>Frequency</th>
                    <th>Unit</th>
                    <th>Min Trigger Value</th>
                    <th>Max Trigger Value</th>
                  </tr>
                </thead>
                <tbody>
                  {questions[selectedCategory].map((question) => 
                    question.questionType === "tabular_question" ? (
                      <React.Fragment key={question?.questionId}>
  <tr>
    <td colSpan="5">
      <p>
        <strong>{question.title}</strong>
      </p>
    </td>
  </tr>
  {question.details
    .slice()
    .reverse()
    .filter((detail) => detail.option_type === "row")
    .map((row, rowIndex) =>
      question.details
        .slice()
        .reverse()
        .filter((detail) => detail.option_type === "column")
        .map((column, colIndex) => {
          // Get the current monthValue from triggerValue
          const currentMonthValue = 
            triggerValue[question?.questionId]?.monthValue || 
            (fromDate ? parseInt(fromDate.split("-")[1], 10) : null);
          
          // Create the composite key
          const compositeKey = currentMonthValue ? 
            `${question?.questionId}-${currentMonthValue}` : null;
            
          // Get the values for this cell from triggerValues based on the nested structure
          const cellValues = compositeKey ? {
            frequency: triggerValues[question?.questionId]?.[compositeKey]?.frequency || "",
            unit: triggerValues[question?.questionId]?.[compositeKey]?.data?.[rowIndex]?.[colIndex] || "",
            minTriggerValue: triggerValues[question?.questionId]?.[compositeKey]?.minTriggerArray?.[rowIndex]?.[colIndex] || "",
            maxTriggerValue: triggerValues[question?.questionId]?.[compositeKey]?.maxTriggerArray?.[rowIndex]?.[colIndex] || ""
          } : {};

          return (
            <tr key={`${rowIndex}-${colIndex}`}>
              <td>{`${row.option} - ${column.option}`}</td>
              <td>
                <select
                  className="form-select"
                  value={monthValue||''}
                  onChange={(e) => {
                    handlePeriodChange(question?.questionId, "frequency", e.target.value);
                    handleTabularChangeChange(question?.questionId, rowIndex, colIndex, "frequency", e.target.value);
                  }}
                >
                  <option value="">Select Frequency</option>
                  {timePeriodOptions.map((option, index) => (
                    <option key={index} value={option.value}>
                      {option.label}
                    </option>
                  ))}
                </select>
              </td>
              <td>
                <select
                  className="form-select"
                  value={cellValues.unit}
                  onChange={(e) =>
                    handleTabularChangeChange(
                      question?.questionId,
                      rowIndex,
                      colIndex,
                      "unit",
                      e.target.value
                    )
                  }
                >
                  <option value="">Select Unit</option>
                  {unitOptions.map((option, index) => (
                    <option key={index} value={option}>
                      {option}
                    </option>
                  ))}
                </select>
              </td>
              <td>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Enter min trigger value"
                  value={cellValues.minTriggerValue}
                  onChange={(e) =>
                    handleTabularChangeChange(
                      question?.questionId,
                      rowIndex,
                      colIndex,
                      "minTriggerValue",
                      e.target.value
                    )
                  }
                />
              </td>
              <td>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Enter max trigger value"
                  value={cellValues.maxTriggerValue}
                  onChange={(e) =>
                    handleTabularChangeChange(
                      question?.questionId,
                      rowIndex,
                      colIndex,
                      "maxTriggerValue",
                      e.target.value
                    )
                  }
                />
              </td>
              <td>
                <button
                  className="btn btn-primary"
                  onClick={() => saveTriggerData(question, question?.questionId, rowIndex, colIndex)}
                >
                  Save
                </button>
              </td>
            </tr>
          );
        })
    )}
</React.Fragment>
                    ) :  <></>
                
                  )}
                </tbody>
              </table>
            </div>
          )
        :<></>}
    </div>
  );
};

export default Triggers;
