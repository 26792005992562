import React from "react";
import EnergyConsumptionFourtyEight from "./Framework48/EnergyConsumptionFourtyEight";
import EnergyConsumptionChart from "./FrameworkOne/EnergyConsumptionChart";
import VerticalEnergyBarComonent from "./FrameworkOne/VerticalEnergyBarComonent";
import VerticalBarComponent from "./Framework48/VerticalBarComponent";

const SingleLocSingleTime = ({
  companyFramework,
  timePeriods,
  brief,
  totalConsumptionRenewable,
  totalConsumptionNonRenewable,
  renewableEnergy,
  nonRenewableEnergy,
  timePeriodValues,
  locationOption,
  scope1,
  scope2
}) => {
  return (
    <div>
      {/* Only Bar View Content */}
      {companyFramework.includes(1) ? (
        <div className="d-flex flex-column flex-space-between">
          <div
            className="d-flex flex-row flex-space-between"
            style={{marginBottom: "3%" }}
          >
            <div
              className="firsthalfprogressenergy"
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
                width: "50%",
              }}
            >
              <div style={{ height: "55vh", marginBottom:"20px"}}>
                <EnergyConsumptionChart
                  totalConsumptionRenewable={totalConsumptionRenewable}
                  totalConsumptionNonRenewable={totalConsumptionNonRenewable}
                />
              </div>
              <div style={{ height: "55vh"}} className="container">
                <VerticalEnergyBarComonent
                  renewableEnergy={renewableEnergy}
                  nonRenewableEnergy={nonRenewableEnergy}
                  com={"non"}
                />
              </div>
            </div>
            <div
              className="secondhalfprogress"
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
                width: "50%",
              }}
            >
              <div style={{ height: "55vh"}} className="container">
                <VerticalEnergyBarComonent
                  renewableEnergy={renewableEnergy}
                  nonRenewableEnergy={nonRenewableEnergy}
                  com={"ren"}
                />
              </div>
            </div>
          </div>
        </div>
      ) : (
        <>
          <div className="d-flex flex-column flex-space-between">
            <div
              className="d-flex flex-row flex-space-between"
              style={{ marginBottom: "2%" }}
            >
              <div
                className="firsthalfprogressenergy"
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-between",
                  width: "50%",
                  marginTop: "10px",
                }}
              >
             

                <div  className="my-2">
                  <EnergyConsumptionFourtyEight
                    timePeriodValues={timePeriodValues}
                    brief={scope1}
                    timePeriods={timePeriods}
                    type="FUEL"
                  />
                </div>
              </div>
              <div
                className="secondhalfprogress"
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-between",
                  width: "50%",
                  marginTop: "10px",
                }}
              >
                <div  className="my-2">
                  <EnergyConsumptionFourtyEight
                    timePeriodValues={timePeriodValues}
                    brief={scope2}
                    timePeriods={timePeriods}
                    type="ELE"
                  />
                </div>
                {/* <div  className="my-2">
                  <EnergyConsumptionFourtyEight
                    timePeriodValues={timePeriodValues}
                    brief={brief}
                    timePeriods={timePeriods}
                    type="REW"
                  />
                </div> */}
              </div>
            </div>

            <div className="d-flex flex-row flex-space-between">
              <div
                className="firsthalfprogressenergy"
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-between",
                  width: "50%",
                  marginTop: "10px",
                }}
              >
                <div style={{ height: "100%" }} className="my-2 container">
                  <VerticalBarComponent
                    timePeriodValues={timePeriodValues}
                    brief={scope1}
                    timePeriods={timePeriods}
                    type="FUEL"
                  />
                </div>
              </div>
              <div
                className="secondhalfprogress"
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-between",
                  width: "50%",
                  marginTop: "10px",
                }}
              >
                <div style={{ height: "100%" }} className="my-2 container">
                  <VerticalBarComponent
                    timePeriodValues={timePeriodValues}
                    brief={scope2}
                    timePeriods={timePeriods}
                    type="ELE"
                  />
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default SingleLocSingleTime;
