import React, { useEffect, useRef, useState } from "react";
import config from "../../../src/config/config.json";
import { apiCall } from "../../_services/apiCall";
import defaulted from "../../img/Defaulted.svg";
import due from "../../img/Due.svg";
import done from "../../img/shape.svg";
import updated from "../../img/updated.svg";
import TopComponent from "../DashboardComponents/TopComponent";
import RecentActivity from "../RecentActivity/recentactivity";
import DataProcessing from "../TeamWorkLoad/DataProcessing";
import KPIreporting from "../TeamWorkLoad/KPIreporting";
import OrganizationDataStatusChart from "../TeamWorkLoad/OrganizationDataStatusChart";
import TeamWorkLoad from "../TeamWorkLoad/TeamWorkLoad";
import TeamWorkLoadChecker from "../TeamWorkLoad/TeamWorkLoadChecker";
import TeamWorkLoadMaker from "../TeamWorkLoad/TeamWorkLoadMaker";
import TopicWisedata from "../TeamWorkLoad/TopicWisedata";
import "./progress.css";

const Progress = ({
  fromDate,
  toDate,
  framework,
  timePeriods,
  financialYearId,
  locationOption,
  todaysActivities,
}) => {
  const [lastWeekAcitivities, setLastWeekAcitivities] = useState();
  const [teamWorksloadData, setTeamWorksloadData] = useState([]);
  const [loading, setLoading] = useState(false);
  const isMounted = useRef(true);
  const [assignmentStatus, setAssignmentStatus] = useState();
  const [makerVsChecker, setMakerVsChecker] = useState();
  const [orgCheckerOtatusData, setOrgCheckerOtatusData] = useState();
  const [orgMakerOtatusData, setOrgMakerOtatusData] = useState();
  const [topicWiseMapping, setTopicWiseMapping] = useState();
  const icons = {
    Completed: done,
    "In Progress": updated,
    Overdue: due,
    Upcoming: defaulted,
  };

  const lastWeekActivity = async () => {
    const { isSuccess, data } = await apiCall(
      `${config.POSTLOGIN_API_URL_COMPANY}lastWeekActivity`,
      {},
      {
        fromDate: fromDate,
        toDate: toDate,
        financialYearId: financialYearId,
      },
      "GET"
    );
    if (isSuccess && isMounted.current) {
      setLastWeekAcitivities(data?.data);
    }
  };

  useEffect(() => {
    lastWeekActivity();
  }, []);

  const teamWorkloadProgess = async () => {
    if (fromDate && toDate && financialYearId) {
      const frameworkIds = framework.map((item) => item.value);
      const locationIdsIds =
        locationOption && locationOption.map((item) => item.id);
      const periods = Object.values(timePeriods);
      const { isSuccess, data } = await apiCall(
        `${config.POSTLOGIN_API_URL_COMPANY}teamWorkloadProgess`,
        {},
        {
          fromDate: fromDate,
          toDate: toDate,
          financialYearId,
          frameworkIds,
          locationIdsIds,
          periods,
        },
        "GET"
      );

      if (isSuccess && isMounted.current) {
        setTeamWorksloadData(data?.data?.teamWorkloadResults);
      }
    }
  };

  const prevDependenciesRef = useRef();
  const areDependenciesEqual = (prev, curr) => {
    return JSON.stringify(prev) === JSON.stringify(curr);
  };

  useEffect(() => {
    const currentDependencies = [
      framework,
      locationOption,
      timePeriods,
      financialYearId,
      fromDate,
    ];
    if (
      (!prevDependenciesRef.current ||
        !areDependenciesEqual(
          prevDependenciesRef.current,
          currentDependencies
        )) &&
      framework?.length &&
      locationOption?.length &&
      financialYearId
    ) {
      teamWorkloadProgess();
      prevDependenciesRef.current = currentDependencies;
    }
  }, [framework, locationOption, timePeriods, financialYearId, fromDate]);

  const getUserProgressData = async () => {
    const frameworkIds = framework.map((item) => item.value);
    if (fromDate && toDate && financialYearId && frameworkIds.length != 0) {
      setLoading(true);
      const locationIdsIds =
        locationOption && locationOption.map((item) => item.id);
      const periods = Object.values(timePeriods);
      const { isSuccess, data } = await apiCall(
        `${config.POSTLOGIN_API_URL_COMPANY}getUserProgressData`,
        {},
        {
          fromDate: fromDate,
          toDate: toDate,
          financialYearId,
          frameworkIds,
          locationIdsIds,
          periods,
        },
        "GET"
      );
      setLoading(false);
      if (isSuccess && isMounted.current) {
        setAssignmentStatus(data?.data?.assignmentStatus);
        setMakerVsChecker(data?.data?.makerVsChecker);
        setOrgCheckerOtatusData(data?.data?.orgCheckerOtatusData);
        setOrgMakerOtatusData(data?.data?.orgMakerOtatusData);
        setTopicWiseMapping(data?.data?.topicWiseMapping);
      }
    }
  };

  useEffect(() => {
    if (fromDate && toDate && financialYearId) {
      getUserProgressData();
    }
  }, [fromDate, toDate, financialYearId]);

  return (
    <div className="progress-container">
      {loading ? (
        <div>Loading...</div>
      ) : (
        <>
          <div className="topcompo">
            {lastWeekAcitivities && (
              <TopComponent
                lastWeekAcitivities={lastWeekAcitivities}
                icons={icons}
              />
            )}
          </div>

          <div
            className="d-flex flex-row flex-space-between secondarycont"
          >
            {orgCheckerOtatusData && (
              <div className="nothing" style={{ height: "100%", width: "50%" }}>
                <TeamWorkLoadChecker
                  orgCheckerOtatusData={orgCheckerOtatusData}
                />
              </div>
            )}
            {orgMakerOtatusData && (
              <div
                className="nothing"
                style={{ height: "100%", width: "50%", marginLeft: "2%" }}
              >
                <TeamWorkLoadMaker orgMakerOtatusData={orgMakerOtatusData} />
              </div>
            )}
          </div>
          <div
            className="d-flex flex-row flex-space-between secondarycont"
            style={{ height: "60vh" }}
          >
            {teamWorksloadData && (
              <div className="nothing" style={{ height: "100%", width: "50%" }}>
                <TeamWorkLoad
                  user="audit"
                  teamWorkloadData={teamWorksloadData}
                />
              </div>
            )}
            {teamWorksloadData && (
              <div
                className="nothing"
                style={{ height: "100%", width: "50%", marginLeft: "2%" }}
              >
                <DataProcessing
                  user="user"
                  teamWorkloadData={teamWorksloadData}
                />
              </div>
            )}
          </div>
          <div
            className="d-flex flex-row flex-space-between secondarycont"
          >
            {makerVsChecker && (
              <div
                className="nothing"
                style={{ height: "100%", width: "100%" }}
              >
                <KPIreporting makerVsChecker={makerVsChecker} />
              </div>
            )}
          </div>

          <div
  className="d-flex flex-row flex-space-between secondarycont"
  style={{ height: "507px" }} // Fixed height for the parent container
>
  {topicWiseMapping && (
    <div className="nothing" style={{ height: "100%", width: "49%",borderRadius: "10px", }}>
      <TopicWisedata topicWiseMapping={topicWiseMapping} />
    </div>
  )}
  {topicWiseMapping && (
    <div
      className="nothing"
      style={{ height: "100%", width: "50%", marginLeft: "2%" }}
    >
      {assignmentStatus && (
        <OrganizationDataStatusChart
          data={assignmentStatus}
          heading="Assigned vs Unassigned questions Status Distribution"
          reportingUrl="/reports/questions-status"
        />
      )}
    </div>
  )}
</div>

          <div
            className="d-flex flex-row flex-space-between secondarycont"
            style={{ height: "40vh" }}
          >
            <div className="nothing" style={{ width: "50%" }}>
              <RecentActivity
                fromDate={fromDate}
                toDate={toDate}
                financialYearId={financialYearId}
                todaysActivities={todaysActivities}
                heading="Data owner's activity log"
              />
            </div>

            <div className="nothing" style={{ width: "50%", marginLeft: "2%" }}>
              <RecentActivity
                fromDate={fromDate}
                toDate={toDate}
                financialYearId={financialYearId}
                todaysActivities={[]}
                heading="Reviewer's activity log"
              />
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default Progress;
