import React from "react";
import Sidebar from "../../sidebar/sidebar";
import Header from "../../header/header";
import { useLocation, useParams } from "react-router-dom";
import { useState, useEffect } from "react";
import { apiCall } from "../../../_services/apiCall";
import config from "../../../config/config.json";
import fuel from "../../../img/fuelslogo.svg";
import bio from "../../../img/environmentlogo.svg"
import re from "../../../img/regrigerantslogo.svg"
import ow from "../../../img/vehicleslogo.svg";
import fi from "../../../img/firelogo.svg";
import ScopeAccordion from "../Component/Accordion/ScopeAccordion";
// import { useReport } from "../sidebar/sidebar";

const ScopeTwo = (props) => {
  const [financeObjct, setFinanceObjct] = useState();
  const [currentUserId, setCurrentUserId] = useState();
  const [startingMonth, setStartingMonth] = useState();
  const [financialYear, setFinancialYear] = useState();
  const [activebtnTab, setactivebtnTab] = useState(0);
 const [sidebarExpanded, setSidebarExpanded] = useState(true);
  const getFinancialYear = async () => {
    const { isSuccess, data } = await apiCall(
      `${config.POSTLOGIN_API_URL_COMPANY}getFinancialYear`,
      {},
      {}
    );

    if (isSuccess) {
      setFinancialYear(data.data[data.data.length - 1].financial_year_value);
      setFinanceObjct(data.data[data.data.length - 1].id);
      return data.data[data.data.length - 1].id;
    }
  };

  useEffect(() => {
    setStartingMonth(
      JSON.parse(localStorage.getItem("currentUser")).starting_month
    );
    setCurrentUserId(JSON.parse(localStorage.getItem("currentUser")).id);
    getFinancialYear();
  }, []);


  const handleTabClick = (index) => {
    setactivebtnTab(index);
  };
  const handleSidebarToggle = (isOpen) => {
    setSidebarExpanded(isOpen);
  };
  return (
    <div
      className="d-flex flex-row mainclass"
      style={{ height: "100vh", overflow: "auto" }}
    >
    <div
        style={{
         flex: sidebarExpanded ? "0 0 21%" : "0 0 60px", position: "sticky", top: 0, zIndex: 999,transition: "flex 0.3s ease"
        }}
      >
        <Sidebar
          financeObjct={financeObjct}
          dataFromParent={props.location.pathname}
          onSidebarToggle={handleSidebarToggle} 
        />
      </div>

      {/* Main Content */}
      <div style={{flex: sidebarExpanded ? "1 1 79%" : "1 1 calc(100% - 60px)",
          transition: "flex 0.3s ease" , minHeight: "100vh", overflowY: "auto" }}>
        <div style={{ position: "sticky", top: 0, zIndex: 999 }}>
          <Header />
        </div>
        <div style={{ width: "100%", marginBottom: "5px", padding: "25px", paddingBottom: "0px", paddingLeft: "3%" }}>
          <div
            style={{ width: "100%", marginBottom: "5px" }}
            className="d-flex justify-content-between buttoncont"
          >

            <>
              <button
                className={`btn button ${activebtnTab === 0 ? " activebtn" : ""}`}
                onClick={() => handleTabClick(0)}
                style={{ borderRadius: "5px", width: "15%" }}
              >
                <img src={fuel} alt="Fuels Icon" style={{ marginRight: "8px", width: "20px" }} />
                Fuels
              </button>
              <button
                className={`btn button ${activebtnTab === 1 ? " activebtn" : ""}`}
                onClick={() => handleTabClick(1)}
                style={{ borderRadius: "5px", width: "15%" }}

              >
                <img src={bio} alt="Bio-Energy Icon" style={{ marginRight: "8px", width: "20px" }} />
                Bio-Energy
              </button>
              <button
                className={`btn button ${activebtnTab === 2 ? " activebtn" : ""}`}
                onClick={() => handleTabClick(2)}
                style={{ borderRadius: "5px", width: "15%" }}

              >
                <img src={re} alt="Refrigerants Icon" style={{ marginRight: "8px", width: "20px" }} />
                Refrigerants
              </button>
              <button
                className={`btn button ${activebtnTab === 3 ? " activebtn" : ""}`}
                onClick={() => handleTabClick(3)}
                style={{ borderRadius: "5px", width: "15%" }}

              >
                <img src={ow} alt="Owned Vehicles Icon" style={{ marginRight: "8px", width: "20px" }} />
                Owned Vehicles
              </button>
              <button
                className={`btn button ${activebtnTab === 4 ? " activebtn" : ""}`}
                onClick={() => handleTabClick(4)}
                style={{ borderRadius: "5px", width: "15%" }}

              >
                <img src={fi} alt="Fire Extinguisher Icon" style={{ marginRight: "8px", width: "20px" }} />
                Fire Extinguisher
              </button>
            </>


          </div>

        </div>

        <div className="main_wrapper" style={{ paddingTop: "0px" }}>

          <div
            className="w-100"
            style={{
              paddingRight: "2.5%",
              marginLeft: "2%",
            }}
          ></div>
          <div className="w-100 p-4 ">
            <ScopeAccordion />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ScopeTwo;
