import React, { Component } from "react";
import { authenticationService } from "../../_services/authentication";
import { history } from "../../_helpers/history";
import { Link, NavLink } from "react-router-dom";
import config from "../../config/config.json";
import { FiCpu, FiBell } from "react-icons/fi";
import "./header.css";
import { checkIsAuthenticated, transformRoleOrder } from "../../utils/UniversalFunction";
import { PermissionMenuContext } from "../../contextApi/permissionBasedMenuContext";
import swal from "sweetalert";
import { apiCall } from "../../_services/apiCall";
import { ROLE_ID_MAPPING, ROLE_ID_MAPPING_NUMBER_BASED } from "../../_constants/constants";
import NoNotification from "../../img/no-results.png";
import { Button, Modal } from "react-bootstrap";

const ProfilePics = "https://res.cloudinary.com/dmklsntsw/image/upload/v1658480882/dummyPic.75a04487_fqfqey.png";

export default class Header extends Component {
  static contextType = PermissionMenuContext;

  constructor(props) {
    super(props);
    this.state = {
      error: null,
      mode: null,
      isPopUpOpen: false,
      isLoaded: false,
      isDropdownOpen: false,
      usersData: {},
      notificationData: [],
      profilePic: null,
      firstName: "",
      lastName: "",
      checked: false,
      role: localStorage.getItem("role"),
      roles: transformRoleOrder(
        JSON.parse(localStorage.getItem("currentUser"))?.user_type_code
      ),
    };
  }

  async   componentDidMount() {
    this.checkAuthentication();
    
    // Add event listener for sidebar toggle keyboard shortcut (optional)
    document.addEventListener('keydown', this.handleKeyboardShortcuts);
    
    // Check if sidebar element exists to avoid errors
    if (!document.getElementById("sidebar-wrapper")) {
      console.warn("Sidebar wrapper element not found in the DOM");
    }
  }
  
  checkAuthentication = async () => {
    const isAuthenticated = await this.routeChecking();
    if (!isAuthenticated) {
      window.location.href = "/";
    }
  }
  
  componentWillUnmount() {
    // Clean up event listeners
    document.removeEventListener('keydown', this.handleKeyboardShortcuts);
  }
  
  // Optional: Add keyboard shortcut for sidebar toggle
  handleKeyboardShortcuts = (e) => {
    // Toggle sidebar with Alt+S
    if (e.altKey && e.key === 's') {
      e.preventDefault();
      this.sidebarOpen();
    }
  }

  goToPreviousPath = () => {
    window.history.back();
  };

  handleDropdownToggle = () => {
    this.getNotificationToUser();
    this.setState({
      isDropdownOpen: !this.state.isDropdownOpen,
    });
  };

  logout = () => {
    authenticationService.logout();
    history.push("/");
    localStorage.clear();
  };

  routeChecking = async () => {
    const data = await checkIsAuthenticated();
    if (data.status === false) {
      localStorage.clear();
      return false;
    } else {
      const currentUser = localStorage.getItem("role");
      if (currentUser === null) {
        window.location.href = "/";
      } else if (currentUser === "super_admin" || currentUser === "sub_admin") {
        window.location.href = "/";
      }
      return true;
    }
  };

  saveToContext = (payloadValue, typeValue) => {
    const { dispatch } = this.context;
    dispatch({
      type: typeValue,
      payload: payloadValue,
    });
  };

  getNotificationToUser = async () => {
    const { isSuccess, data } = await apiCall(
      `${config.POSTLOGIN_API_URL_COMPANY}getNotificationToUser`,
      {},
      {},
      "GET"
    );
    if (isSuccess) {
      this.setState({
        notificationData: data?.data,
      });
    }
  };

  getProfileData = async (role_id) => {
    const { isSuccess, data } = await apiCall(
      `${config.API_URL}getProfileData?userId=${localStorage.getItem(
        "user_temp_id"
      )}${role_id ? `&role_id=${role_id}` : ""}`,
      {}
    );
    
    if (isSuccess) {
      localStorage.setItem("user_temp_id", JSON.stringify(data?.user?.id));
      localStorage.setItem(
        "role",
        JSON.stringify(data?.currentRole).replaceAll('"', "").toUpperCase()
      );
      localStorage.setItem(
        "currentUser",
        JSON.stringify({
          ...data?.user,
          is_head: data?.is_head,
          hasValidPlan: data?.hasValidPlan,
        })
      );
      localStorage.setItem("menu", JSON.stringify(data?.menu));
      localStorage.setItem("subscriptionAuth", JSON.stringify(200));
      
      const menu = JSON.parse(localStorage.getItem("menu"));
      this.saveToContext(menu, "Add_Menu");
      this.saveToContext(localStorage.getItem("role"), "Add_Role");
      
      const beforeThis = data?.user?.profile_picture?.split(":")[0];
      this.setState({
        isLoaded: true,
        usersData: data.user,
        profilePic:
          data?.user?.profile_picture && beforeThis !== "https"
            ? ProfilePics
            : data?.user?.profile_picture,
        firstName: data?.user?.first_name,
        lastName: data?.user?.last_name,
        user_type: data?.user?.currentRole,
        roles: transformRoleOrder(data?.user?.user_type_code),
      });
    }
  };

  sidebarOpen = () => {
    const sidebar = document.getElementById("sidebar-wrapper");
    if (!sidebar) {
      console.error("Sidebar element not found");
      return;
    }
    
    const isActive = sidebar.classList.contains("active-sidebar");
    
    if (isActive) {
      // If sidebar is currently hidden (has active-sidebar class), show it
      sidebar.classList.remove("active-sidebar");
      sidebar.style.display = "block";
      sidebar.style.zIndex = "3";
      
      // Use requestAnimationFrame for smoother transitions
      requestAnimationFrame(() => {
        sidebar.style.marginLeft = "0rem";
        
        // Dispatch a window resize event to ensure any responsive elements adjust
        window.dispatchEvent(new Event('resize'));
      });
    } else {
      // If sidebar is currently visible, hide it
      sidebar.classList.add("active-sidebar");
      sidebar.style.marginLeft = "-15rem";
      
      setTimeout(() => {
        sidebar.style.display = "none";
        
        // Dispatch a window resize event to ensure any responsive elements adjust
        window.dispatchEvent(new Event('resize'));
      }, 200); // Match this timeout with your CSS transition duration
    }
    
    // Toggle body class if you need to adjust layout when sidebar is open/closed
    document.body.classList.toggle('sidebar-open');
  };

  SwitchUserRole = async () => {
    const possibleRoles = JSON.parse(
      localStorage.getItem("currentUser")
    ).role_id.split(",");
    
    const newRole =
      localStorage.getItem("role") === ROLE_ID_MAPPING_NUMBER_BASED[possibleRoles[0]]
        ? ROLE_ID_MAPPING_NUMBER_BASED[possibleRoles[1]]
        : ROLE_ID_MAPPING_NUMBER_BASED[possibleRoles[0]];
    
    this.setState({
      ...this.state,
      user_type: newRole,
      checked: !this.state.checked,
    });

    await this.getProfileData(ROLE_ID_MAPPING[newRole]);
    
    try {
      swal({
        icon: "success",
        title: "Success",
        text: "User role switched successfully.",
        timer: 2000,
      });

      window.location.href = config.baseURL + "/#/home";
    } catch (error) {
      console.error("Error switching user role:", error);
    }
  };

  handlePopUp = (mode) => {
    this.setState({
      isPopUpOpen: true,
      mode: mode,
    });
  };

  handleClose = () => {
    this.setState({
      isPopUpOpen: false,
    });
  };

  openChatbot = () => {
    window.open("https://riu-aichat.com/", "_blank");
  };

  getPageTitle = () => {
    let fullUrl = window.location.href.split("/");
    let uri = window.location.pathname.split("/");
    let path = uri[1];
    let title = "";

    // Pop the last segment and format it
    if (fullUrl[3] !== "sub_accounts") {
      title = fullUrl.pop().toUpperCase();
    } else {
      title = fullUrl[3];
    }
    
    title = title.replaceAll("_", " ");
    
    // Special case handling for specific paths
    if (title === "SUPPLIER FAST") title = "SUPPLIER";
    if (title === "SUSTAINABLE") title = "SUSTAINABLE DEVELOPMENT GOALS";
    if (fullUrl[3] === "checkout") title = "Success";
    if (path === "Leadership_Governance") title = "Leadership & Governance";
    if (path === "Cyber_Digital") title = "Cyber & Digital";
    if (path === "Business_Model_Innovation") title = "Business Model & Innovation";
    if (path === "suppliers_fast") title = "Suppliers";
    if (title === "HOME") title = "Dashboard";
    
    return title;
  };

  render() {
    const { isDropdownOpen, notificationData } = this.state;
    
    // Get user data from localStorage
    let profilePicture, firstName, lastName, position;
    if (localStorage.getItem("currentUser")) {
      const currentUser = JSON.parse(localStorage.getItem("currentUser"));
      profilePicture = currentUser.profile_picture;
      firstName = currentUser.first_name;
      lastName = currentUser.last_name;
      position = currentUser.position;
    }

    const pageTitle = this.getPageTitle();

    return (
      <div style={{ position: "sticky", top: "0", zIndex: 99, width: "100%", boxSizing: "border-box" }}>
        <div className="d-flex" id="wrapper" style={{ width: "100%" }}>
          <div id="page-content-wrapper" style={{ width: "100%" }}>
            <nav className="navbar navbar-expand-lg border-bottom navclassName background topbar_esg" style={{ width: "100%" }}>
              <div className="container-fluid">
                <div className="deahbord" id="sidebarToggle">
                  <NavLink
                    to="#"
                    className="sidebar_bar"
                    onClick={this.sidebarOpen}
                  >
                    <i className="fas fa-bars"></i>
                  </NavLink>
                  <h4 className="back_quninti back_quninti_2 m-0">
                    <a className="back_text">
                      <span className="step_icon">
                        <i
                          onClick={this.goToPreviousPath}
                          className="far fa-long-arrow-left"
                        ></i>
                      </span>
                      {pageTitle}
                    </a>
                  </h4>
                </div>
                <div
                  className="collapse navbar-collapse"
                  id="navbarSupportedContent"
                >
                  <ul className="navbar-nav align-items-center ms-auto gap-3">
                    <li className="nav-item dropdown text_down">
                      <a
                        className="nav-link home_drop"
                        href="#"
                        role="button"
                        data-bs-toggle="dropdown"
                        onClick={this.handleDropdownToggle}
                        aria-haspopup="true"
                        aria-expanded={isDropdownOpen ? "true" : "false"}
                      ></a>
                      <div className="elly">
                        <button
                          onClick={this.openChatbot}
                          style={{
                            backgroundColor: "#3f88a5",
                            color: "white",
                            border: "none",
                            padding: "8px 12px",
                            cursor: "pointer",
                            borderRadius: "5px",
                          }}
                        >
                          AI Chatbot
                        </button>
                      </div>
                      <div className="elly">
                        <NavLink to="/analytics">
                          <FiCpu className="ellly" />
                        </NavLink>
                      </div>
                      <div className="elly">
                        <NavLink to="#">
                          <FiBell className="ellly" />
                        </NavLink>
                      </div>

                      <div
                        className="dropdown-menu dropdown-menu-end dropdown_menu"
                        style={{ width: 500 }}
                      >
                        <div className="notification_section">
                          <div className="hstack border-bottom justify-content-between">
                            <h5>Notification</h5>
                            <div className="hstack gap-3">
                              <a onClick={() => this.handlePopUp("MARKREAD")}>
                                Mark all as read
                              </a>
                              <a onClick={() => this.handlePopUp("CLEARALL")}>
                                Clear all
                              </a>
                              <i className="fas fa-arrow-right"></i>
                            </div>
                          </div>
                          <div className="notification_body">
                            {notificationData && notificationData.length > 0 ? (
                              notificationData.map((item, key) => (
                                <div className="d-flex gap-4 border-bottom" key={key}>
                                  <i className="fas fa-user-tie"></i>
                                  <div className="my-2">
                                    <h4>RIU</h4>
                                    <Link
                                      to={{
                                        pathname: "/sector_questions",
                                        state: {
                                          questionIds: item?.questionIds,
                                        },
                                      }}
                                    >
                                      <p>{item?.massage}</p>
                                    </Link>
                                    <a href="#" className="p-0">
                                      Mark as read
                                    </a>
                                  </div>
                                </div>
                              ))
                            ) : (
                              <img
                                src={NoNotification}
                                alt="No Notification Here..."
                                className="w-100 h-100"
                              />
                            )}
                          </div>
                        </div>
                      </div>
                    </li>
                    <li className="nav-item dropdown text_down">
                      <div className="image_round">
                        <img
                          src={profilePicture}
                          alt="Profile"
                          className="image--coverq"
                        />
                      </div>

                      <a
                        className="home_drop"
                        id="navbarDropdown"
                        href="#"
                        role="button"
                        data-bs-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="false"
                      >
                        {firstName} {lastName}{" "}
                        <i
                          className="fa fa-caret-down elly"
                          aria-hidden="true"
                        ></i>
                      </a>

                      <div
                        className="dropdown-menu dropdown-menu-end dropdown_menu"
                        aria-labelledby="navbarDropdown"
                      >
                        <Link to="/settings" className="dropdown-item">
                          <i className="fa fa-user"></i>
                          <span>My Profile</span>
                        </Link>

                        <Link
                          onClick={this.logout}
                          to="/"
                          className="dropdown-item"
                        >
                          <i className="fa fa-sign-out"></i>
                          <span>Logout</span>
                        </Link>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </nav>
            
            {/* Notification Modal */}
            <Modal show={this.state.isPopUpOpen} onHide={this.handleClose}>
              <Modal.Header closeButton>
                <Modal.Title>Confirmation</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <h5>
                  Do you want to{" "}
                  {this.state.mode === "CLEARALL"
                    ? "clear all the"
                    : "mark as read all"}{" "}
                  notifications?
                </h5>
              </Modal.Body>
              <Modal.Footer>
                <Button variant="danger" onClick={this.handleClose}>
                  Cancel
                </Button>
                <Button variant="info" onClick={this.handleClose}>
                  Confirm
                </Button>
              </Modal.Footer>
            </Modal>
          </div>
        </div>
      </div>
    );
  }
}