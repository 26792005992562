import React from "react";
import img from "../../../img/no.png";

const DiversityBarComponent = ({
  title,
  dataOne,
}) => {
  if (!dataOne || dataOne.length === 0) {
    return (
      <div className="container">
        <img
          src={img}
          alt="No Data Available"
          style={{
            width: "150px",
            height: "125px",
            display: "block",
            margin: "0 auto",
          }}
        />
      </div>
    );
  }

  const categories = dataOne.reduce((acc, item) => {
    if (item.question_details) {
      let filteredOptions = item.question_details
        .filter((detail) => detail.option_type === "column1")
        .map((detail) => detail.option);

      if (filteredOptions.length === 0) {
        filteredOptions = item.question_details
          .filter((detail) => detail.option_type === "column")
          .map((detail) => detail.option);
      }

      return acc.concat(filteredOptions);
    }

    return acc;
  }, []);

  const uniqueCategories = [...new Set(categories)].reverse();

  const categoryValues = uniqueCategories.map((category, categoryIndex) => {
    const totalValue = dataOne.reduce((sum, item) => {
      if (item.question_details && item.answer && item.answer[1]) {
        const matchedDetail = item.question_details.find(
          (detail) => detail.option === category
        );
        const answerValue = item.answer[1][categoryIndex];
        if (matchedDetail && answerValue !== undefined) {
          return sum + (isNaN(Number(answerValue)) ? 0 : Number(answerValue));
        }
      }
      return sum;
    }, 0);

    return {
      category,
      totalValue,
    };
  });

  // Filter out categories with 0 or NaN values
  const validCategoryValues = categoryValues.filter(
    (item) => !isNaN(Number(item.totalValue)) && Number(item.totalValue) > 0
  );

  // Calculate total value (sum of all categories)
  const totalValue = validCategoryValues.reduce(
    (sum, item) => sum + Number(item.totalValue),
    0
  );

  // Create an ordered array with males, females, other, then total
  const orderedData = [];

  // Find male data
  const maleData = validCategoryValues.find(item => 
    item.category === "Number of Males" || 
    item.category === "Males"
  );
  if (maleData) orderedData.push(maleData);

  // Find female data
  const femaleData = validCategoryValues.find(item => 
    item.category === "Number of Females" || 
    item.category === "Females"
  );
  if (femaleData) orderedData.push(femaleData);

  // Find or create other data - if not found in data, use value of 0
  const otherData = validCategoryValues.find(item => 
    item.category === "Other" || 
    item.category === "Others" ||
    item.category === "Number of Others"
  );
  if (otherData) {
    orderedData.push(otherData);
  } else {
    orderedData.push({ category: "Other", totalValue: 0 });
  }

  // Add total as the fourth item
  orderedData.push({ category: "Total", totalValue: totalValue });

  const colors = {
    "Males": "#6fa8dc",
    "Number of Males": "#6fa8dc",
    "Females": "#ffa9d0",
    "Number of Females": "#ffa9d0",
    "Other": "#cccccc",
    "Others": "#cccccc",
    "Number of Others": "#cccccc",
    "Total": "#aaaaaa" // Darker gray for total
  };

  // Chart dimensions
  const chartHeight = 300;
  const barWidth = 80; // Increased from 60
  const barSpacing = 120; // Increased from 80
  const chartWidth = orderedData.length * barSpacing;

  // For vertical scaling - use total value as max for proper scaling
  const maxValue = Math.max(totalValue, ...validCategoryValues.map(item => Number(item.totalValue)));

  // Calculate a nice round scale (better for display)
  const getScaleIncrement = (value) => {
    const magnitude = Math.pow(10, Math.floor(Math.log10(value)));
    if (value / magnitude < 2) return magnitude / 2;
    if (value / magnitude < 5) return magnitude;
    return magnitude * 2;
  };

  const increment = getScaleIncrement(maxValue / 4);
  const roundedMaxValue = Math.ceil(maxValue / increment) * increment;
  const yAxisSteps = roundedMaxValue / increment;

  return (
    <div className="container" style={{ padding: "15px", boxSizing: "border-box" }}>
      <div className="diversity-bar-header" style={{ 
        fontSize: "16px", 
        fontWeight: "bold", 
        marginBottom: "-10px", 
        textAlign: "center" 
      }}>{title}</div>

      {validCategoryValues.length === 0 || totalValue === 0 ? (
        <img
          src={img}
          alt="No Data Available"
          style={{
            width: "150px",
            height: "125px",
            display: "block",
            margin: "0 auto",
          }}
        />
      ) : (
        <>
          <div style={{ 
            width: "100%", 
            display: "flex", 
            flexDirection: "column", 
            alignItems: "center" 
          }}>
            {/* Chart container with Y-axis */}
            <div style={{ 
              display: "flex", 
              position: "relative",
              height: `${chartHeight + 50}px`, // Extra space for labels
              width: `${chartWidth + 50}px`, // Extra space for y-axis
              marginLeft: "40px" // Space for Y-axis labels
            }}>
              {/* Y-axis lines and labels */}
              <div style={{
                position: "absolute",
                left: "0",
                bottom: "0",
                width: "100%",
                height: `${chartHeight}px`,
                borderLeft: "1px solid #ccc"
              }}>
                {Array.from({ length: yAxisSteps + 1 }).map((_, index) => {
                  const value = Math.round((yAxisSteps - index) * increment);
                  const positionY = index * (chartHeight / yAxisSteps);

                  return (
                    <React.Fragment key={index}>
                      {/* Y-axis value */}
                      <div style={{ 
                        position: "absolute", 
                        left: "-40px", 
                        top: `${positionY - 10}px`, 
                        fontSize: "12px",
                        textAlign: "right",
                        width: "35px"
                      }}>
                        {value}
                      </div>
                      
                      {/* Horizontal grid line */}
                      <div style={{ 
                        position: "absolute", 
                        left: "0",
                        top: `${positionY}px`, 
                        width: "100%", 
                        borderTop: index === yAxisSteps ? "1px solid #ccc" : "1px dashed #ccc"
                      }}></div>
                    </React.Fragment>
                  );
                })}
              </div>
              
              {/* Bars */}
              {orderedData.map((item, index) => {
                const value = Number(item.totalValue);
                const barHeight = Math.round(value * (chartHeight / roundedMaxValue));
                const isTotal = item.category === "Total";
                const shortNames = {
                  "Number of Males": "Males",
                  "Number of Females": "Females",
                  "Number of Others": "Other"
                };
                const displayName = shortNames[item.category] || item.category;
                const barColor = colors[item.category] || colors[displayName];

                return (
                  <div key={index} style={{ position: "relative" }}>
                    {/* Bar */}
                    <div 
                      style={{ 
                        position: "absolute",
                        bottom: "0", // This ensures the bar starts from the bottom (0 point)
                        left: `${index * barSpacing + 20}px`,
                        width: `${barWidth}px`,
                        height: `${barHeight}px`,
                        backgroundColor: barColor,
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "flex-start",
                        alignItems: "center",
                      }}
                    >
                      {/* Value on top of bar */}
                      <div style={{ 
                        fontSize: "12px", 
                        position: "absolute",
                        top: "-20px",
                        width: "100%",
                        textAlign: "center",
                        fontWeight: "bold"
                      }}>
                        {value}
                      </div>
                    </div>
                    
                    {/* X-axis labels */}
                    <div style={{ 
                      position: "absolute",
                      bottom: "-30px", 
                      left: `${index * barSpacing + 20}px`, 
                      width: `${barWidth}px`, 
                      textAlign: "center",
                      fontSize: "12px",
                      fontWeight: isTotal ? "bold" : "normal",
                    }}>
                      {displayName}
                    </div>
                  </div>
                );
              })}
            </div>
            
            <div style={{
              fontSize: "12px",
              textAlign: "center",
              marginTop: "40px"
            }}>
              (Number of Individuals)
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default DiversityBarComponent;