import React, { useEffect, useRef, useState } from "react";
import { apiCall } from "../../_services/apiCall";
import config from "../../config/config.json";
import TotalEmissionMultTimeMultTime from "./TotalEmissionMultTimeMultTime";
import RenewableAndNonRenewableEmissionMultiYear from "./RenewableAndNonRenewableEmissionMultiYear";
import SeparateEmissionMultTimeMultTime from "./SeparateEmissionMultTimeMultTime";
import FourtyEightTotalEnergy from "./Framework48/FourtyEightTotalEnergy";
import ProductWiseFourtyEight from "./Framework48/ProductWiseFourtyEight";

const MultipleYearMultipleTime = ({
  keyTab,
  locationOption,
  companyFramework,
  compareLastTimePeriods,
  compareTCurrentimePeriods,
  financialYear,
  scope1,
  scope2,
}) => {
  const [graphData, setGraphData] = useState(null);
  const isMounted = useRef(true);

  useEffect(() => {
    return () => {
      isMounted.current = false;
    };
  }, []);

  const getCompareEnergyData = async () => {
    if (financialYear && financialYear.length >= 2) {
      try {
        const { isSuccess, data } = await apiCall(
          `${config.POSTLOGIN_API_URL_COMPANY}getCompareEnergyData`,
          {},
          {
            financialYearIds: [
              financialYear[financialYear.length - 2].id,
              financialYear[financialYear.length - 1].id,
            ],
          },
          "GET"
        );

        if (isSuccess && isMounted.current) {
          setGraphData(data.data);
        }
      } catch (error) {
        console.error("Error fetching total training data:", error);
      }
    }
  };

  useEffect(() => {
    getCompareEnergyData();
  }, [financialYear]);

  const isCompareLastTimePeriodsValid =
    compareLastTimePeriods && Object.keys(compareLastTimePeriods).length > 0;
  const isCompareTCurrentimePeriodsValid =
    compareTCurrentimePeriods &&
    Object.keys(compareTCurrentimePeriods).length > 0;

  const shouldRenderGraphs =
    isCompareLastTimePeriodsValid && isCompareTCurrentimePeriodsValid;

  return companyFramework && companyFramework.includes(1) ? (
    <div className="d-flex flex-column flex-space-between">
      {/* First Row */}
      <div
        className="d-flex flex-row flex-space-between"
        style={{ height: "120vh", marginBottom: "3%" }}
      >
        <div
          className="firsthalfprogressenergy"
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            width: "50%",
          }}
        >
          {shouldRenderGraphs && (
            <div style={{ height: "48%" }}>
              <TotalEmissionMultTimeMultTime
                compareLastTimePeriods={compareLastTimePeriods}
                locationOption={locationOption}
                compareTCurrentimePeriods={compareTCurrentimePeriods}
                graphData={graphData}
                financialYear={financialYear}
              />
            </div>
          )}

          <div style={{ height: "48%" }}>
            <SeparateEmissionMultTimeMultTime
              compareLastTimePeriods={compareLastTimePeriods}
              locationOption={locationOption}
              compareTCurrentimePeriods={compareTCurrentimePeriods}
              graphData={graphData}
              financialYear={financialYear}
            />
          </div>
        </div>

        <div
          className="secondhalfprogress"
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            width: "50%",
          }}
        >
          <div style={{ height: "48%" }}>
            <RenewableAndNonRenewableEmissionMultiYear
              compareLastTimePeriods={compareLastTimePeriods}
              locationOption={locationOption}
              compareTCurrentimePeriods={compareTCurrentimePeriods}
              graphData={graphData}
              financialYear={financialYear}
            />
          </div>
          {/* <div style={{ height: "48%" }}>
            <ProductWiseYearStacked
              compareLastTimePeriods={compareLastTimePeriods}
              locationOption={locationOption}
              compareTCurrentimePeriods={compareTCurrentimePeriods}
              graphData={graphData}
              financialYear={financialYear}
            />
          </div> */}
        </div>
      </div>
    </div>
  ) : (
    <div className="d-flex flex-column flex-space-between">
    <div
      className="d-flex flex-row flex-space-between"
      style={{ height: "60vh", marginBottom: "3%" }}
    >
      <div
        className="firsthalfprogressenergy"
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          width: "50%",
        }}
      >
        <div style={{ height: "100%" }}>
          <FourtyEightTotalEnergy
            brief={scope1}
            locationOption={locationOption}
            timePeriods={Object.values(compareTCurrentimePeriods)}
            timePeriodValues={Object.values(compareTCurrentimePeriods)}
          />
        </div>

        <div style={{ height: "100%" }}>
          <FourtyEightTotalEnergy
            brief={scope2}
            locationOption={locationOption}
            timePeriods={Object.values(compareTCurrentimePeriods)}
            timePeriodValues={Object.values(compareTCurrentimePeriods)}
          />
        </div>

     
      </div>
      <div
        className="secondhalfprogress"
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          width: "50%",
        }}
      >
        <div style={{ height: "100%" }}>
          {/* <RenewableAndNonRenewable timePeriodValues={timePeriodValues} locationOption={locationOption}  timePeriods={timePeriods} renewableEnergy={renewableEnergy} nonRenewableEnergy={nonRenewableEnergy}/> */}
          <ProductWiseFourtyEight
            timePeriodValues={Object.values(compareTCurrentimePeriods)}
            locationOption={locationOption}
            brief={scope1}
            timePeriods={Object.values(compareTCurrentimePeriods)}
          
          />
        </div>
        <div style={{ height: "100%" }}>
          <ProductWiseFourtyEight
            timePeriodValues={Object.values(compareTCurrentimePeriods)}
            locationOption={locationOption}
            brief={scope2}
            timePeriods={Object.values(compareTCurrentimePeriods)}
          
          />
        </div>
      </div>
    </div>
    {/* <div
    className="d-flex flex-row flex-space-between"
    style={{ height: "22vh", marginBottom: "3%" }}
  ></div> */}
  </div>
  );
};

export default MultipleYearMultipleTime;
