import React, { useState, useEffect } from "react";
import "./tabs.css";
import Energy from "../Energy/energy";
import Progress from "../Progress/progress";
import Emission from "../Emission/emission";
import Water from "../Water/Water";
import Waste from "../Waste/waste";
import Diversity from "../Diversity/Diversity";
import Safety from "../Safety/Safety";
import Training from "../Training/Training";
import Environment from "../Environment/environment";
import TrainingOverview from "../TrainingOverview/trainingOverview";
import TraineeOverview from "../TraineeOverviev/TraineeOverview";
const TabsComponent = ({
  setCurrentTab,
  fromDate,
  keyTab,
  toDate,
  locationOption,
  timePeriods,
  financialYearId,
  graphData,
  frameworkValue,
  sectorQuestionAnswerDataForGraph,
  framework,
  todaysActivities,
  compareLastTimePeriods,
  compareTCurrentimePeriods,
  financialYear,
  energyData,
}) => {
  const [activebtnTab, setactivebtnTab] = useState(0);
  const [permissionList, setPermissionList] = useState([]);
  const currentUser = JSON.parse(localStorage.getItem("currentUser"));
  const [companyFramework, setCompanyFramework] = useState([]);

  useEffect(() => {
    const dashboardMenu = JSON.parse(localStorage.getItem("menu"));
    const dashboardObject = dashboardMenu.find(
      (item) => item.caption === "Dashboard"
    ).permissions;
    setPermissionList(dashboardObject);
    setactivebtnTab(currentUser.userType === "TRAINEE" ? 10 : 0);
  }, []);
  useEffect(() => {
    if (Array.isArray(frameworkValue) && frameworkValue.length) {
      const frameworkId = frameworkValue.map((value) => value.id);
      setCompanyFramework(frameworkId);
    }
  }, [frameworkValue]);
  const handleTabClick = (index) => {
    setactivebtnTab(index);
  };

  useEffect(() => {
    setCurrentTab(activebtnTab);
  }, [activebtnTab]);

  const renderUI = () => {
    switch (activebtnTab) {
      case 0:
        return (
          permissionList.some(
            (permission) =>
              permission.permissionCode === "STATUS_OVERVIEW" &&
              permission.checked
          ) && (
            <Progress
              framework={framework}
              timePeriods={timePeriods}
              fromDate={fromDate}
              toDate={toDate}
              financialYearId={financialYearId}
              locationOption={locationOption}
              todaysActivities={todaysActivities}
            />
          )
        );
      case 1:
        return (
          permissionList.some(
            (permission) =>
              permission.permissionCode === "QUESTION_TYPE_PROGRESS" &&
              permission.checked
          ) && (
            <Environment
              keyTab={keyTab}
              locationOption={locationOption}
              timePeriods={timePeriods}
              financialYearId={financialYearId}
              graphData={graphData}
              frameworkValue={frameworkValue}
              sectorQuestionAnswerDataForGraph={
                sectorQuestionAnswerDataForGraph
              }
            />
          )
        );
      case 2:
        return (
          permissionList.some(
            (permission) =>
              permission.permissionCode === "QUESTION_TYPE_PROGRESS" &&
              permission.checked
          ) && (
            <Energy
              keyTab={keyTab}
              locationOption={locationOption}
              timePeriods={timePeriods}
              financialYearId={financialYearId}
              graphData={graphData}
              frameworkValue={frameworkValue}
              compareLastTimePeriods={compareLastTimePeriods}
              compareTCurrentimePeriods={compareTCurrentimePeriods}
              financialYear={financialYear}
              energyData={energyData}
            />
          )
        );
      case 3:
        return (
          permissionList.some(
            (permission) =>
              permission.permissionCode === "QUESTION_TYPE_PROGRESS" &&
              permission.checked
          ) && (
            <Emission
              keyTab={keyTab}
              locationOption={locationOption}
              timePeriods={timePeriods}
              financialYearId={financialYearId}
              graphData={graphData}
              frameworkValue={frameworkValue}
              compareLastTimePeriods={compareLastTimePeriods}
              compareTCurrentimePeriods={compareTCurrentimePeriods}
              financialYear={financialYear}
              energyData={energyData}
            />
          )
        );
      case 4:
        return (
          permissionList.some(
            (permission) =>
              permission.permissionCode === "QUESTION_TYPE_PROGRESS" &&
              permission.checked
          ) && (
            <Water
              keyTab={keyTab}
              locationOption={locationOption}
              timePeriods={timePeriods}
              financialYearId={financialYearId}
              graphData={graphData}
              frameworkValue={frameworkValue}
              compareLastTimePeriods={compareLastTimePeriods}
              compareTCurrentimePeriods={compareTCurrentimePeriods}
              financialYear={financialYear}
            />
          )
        );
      case 5:
        return (
          permissionList.some(
            (permission) =>
              permission.permissionCode === "QUESTION_TYPE_PROGRESS" &&
              permission.checked
          ) && (
            <Waste
              keyTab={keyTab}
              locationOption={locationOption}
              timePeriods={timePeriods}
              financialYearId={financialYearId}
              graphData={graphData}
              frameworkValue={frameworkValue}
              compareLastTimePeriods={compareLastTimePeriods}
              compareTCurrentimePeriods={compareTCurrentimePeriods}
              financialYear={financialYear}
            />
          )
        );
      case 6:
        return (
          permissionList.some(
            (permission) =>
              permission.permissionCode === "QUESTION_TYPE_PROGRESS" &&
              permission.checked
          ) && (
            <Diversity
              keyTab={keyTab}
              locationOption={locationOption}
              timePeriods={timePeriods}
              financialYearId={financialYearId}
              graphData={graphData}
              frameworkValue={frameworkValue}
              compareLastTimePeriods={compareLastTimePeriods}
              compareTCurrentimePeriods={compareTCurrentimePeriods}
              financialYear={financialYear}
            />
          )
        );
      case 7:
        return (
          permissionList.some(
            (permission) =>
              permission.permissionCode === "QUESTION_TYPE_PROGRESS" &&
              permission.checked
          ) && (
            <Safety
              keyTab={keyTab}
              locationOption={locationOption}
              timePeriods={timePeriods}
              financialYearId={financialYearId}
              graphData={graphData}
              frameworkValue={frameworkValue}
              compareLastTimePeriods={compareLastTimePeriods}
              compareTCurrentimePeriods={compareTCurrentimePeriods}
              financialYear={financialYear}
            />
          )
        );
      case 8:
        return (
          permissionList.some(
            (permission) =>
              permission.permissionCode === "QUESTION_TYPE_PROGRESS" &&
              permission.checked
          ) && (
            <Training
              locationOption={locationOption}
              timePeriods={timePeriods}
              financialYearId={financialYearId}
              graphData={graphData}
              frameworkValue={frameworkValue}
            />
          )
        );
      case 9:
        return (
          permissionList.some(
            (permission) =>
              permission.permissionCode === "QUESTION_TYPE_PROGRESS" &&
              permission.checked
          ) && (
            <TrainingOverview
              locationOption={locationOption}
              timePeriods={timePeriods}
              financialYearId={financialYearId}
              graphData={graphData}
              frameworkValue={frameworkValue}
            />
          )
        );
      case 10:
        return (
          permissionList.some(
            (permission) =>
              permission.permissionCode === "QUESTION_TYPE_PROGRESS" &&
              permission.checked
          ) && (
            <TraineeOverview
              locationOption={locationOption}
              timePeriods={timePeriods}
              financialYearId={financialYearId}
              graphData={graphData}
              frameworkValue={frameworkValue}
            />
          )
        );

      default:
        return null;
    }
  };

  const containerStyle = {
    display: "flex",
    flexDirection: "column",
    height: "100%",
    overflow: "hidden",
  };

  const headerStyle = {
    position: "sticky",
  };

  const contentStyle = {
    overflowY: "auto",
    maxHeight: "calc(78vh - 120px)",
    padding: "10px",
    flexGrow: 1,
  };

  return (
    <>
      <div style={containerStyle}>
        <div style={headerStyle}>
          <div className="scroll-container w-100">
            <div
              className="d-flex justify-content-between buttoncont"
              style={{
                marginBottom: "25px",
                overflow: "auto",
                whiteSpace: "nowrap",
                WebkitOverflowScrolling: "touch",
                msOverflowStyle: "none",
                scrollbarWidth: "thin",
                scrollbarHeight: "1px",
              }}
            >
              {currentUser && currentUser.userType === "TRAINEE" ? (
                <></>
              ) : (
                <>
                  {" "}
                  <button
                    className={`btn button ${
                      activebtnTab === 0 ? " activebtn" : ""
                    }`}
                    onClick={() => handleTabClick(0)}
                    style={{ margin: "0 5px" }}
                  >
                    Progress
                  </button>
                  <button
                    className={`btn button ${
                      activebtnTab === 1 ? " activebtn" : ""
                    }`}
                    onClick={() => handleTabClick(1)}
                    style={{ margin: "0 5px" }}
                  >
                    Environment
                  </button>
                  <button
                    className={`btn button ${
                      activebtnTab === 2 ? " activebtn" : ""
                    }`}
                    onClick={() => handleTabClick(2)}
                    style={{ margin: "0 5px" }}
                  >
                    Energy
                  </button>
                  <button
                    className={`btn button ${
                      activebtnTab === 3 ? " activebtn" : ""
                    }`}
                    onClick={() => handleTabClick(3)}
                    style={{ margin: "0 5px" }}
                  >
                    Emission
                  </button>
                  <button
                    className={`btn button ${
                      activebtnTab === 4 ? " activebtn" : ""
                    }`}
                    onClick={() => handleTabClick(4)}
                    style={{ margin: "0 5px" }}
                  >
                    Water
                  </button>
                  <button
                    className={`btn button ${
                      activebtnTab === 5 ? " activebtn" : ""
                    }`}
                    onClick={() => handleTabClick(5)}
                    style={{ margin: "0 5px" }}
                  >
                    Waste
                  </button>
                  <button
                    className={`btn button ${
                      activebtnTab === 6 ? " activebtn" : ""
                    }`}
                    onClick={() => handleTabClick(6)}
                    style={{ margin: "0 5px" }}
                  >
                    Diversity
                  </button>
                  <button
                    className={`btn button ${
                      activebtnTab === 7 ? " activebtn" : ""
                    }`}
                    onClick={() => handleTabClick(7)}
                    style={{ margin: "0 5px" }}
                  >
                    Safety
                  </button>
                  {companyFramework && companyFramework.includes(1) && (
                    <button
                      className={`btn button ${
                        activebtnTab === 8 ? " activebtn" : ""
                      }`}
                      onClick={() => handleTabClick(8)}
                      style={{ margin: "0 5px" }}
                    >
                      Training
                    </button>
                  )}
                  <button
                    className={`btn button ${
                      activebtnTab === 9 ? " activebtn" : ""
                    }`}
                    onClick={() => handleTabClick(9)}
                    style={{ margin: "0 5px" }}
                  >
                    Trainer Overview
                  </button>
                </>
              )}
              <button
                className={`btn button ${
                  activebtnTab === 10 ? " activebtn" : ""
                }`}
                onClick={() => handleTabClick(10)}
                style={{ margin: "0 5px" }}
              >
                Trainee Overview
              </button>
            </div>
          </div>
          <div style={contentStyle}>{renderUI()}</div>
        </div>
      </div>
    </>
  );
};

export default TabsComponent;
