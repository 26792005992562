import React, { useEffect, useState } from "react";
import Chart from "react-apexcharts";

const WaterComparison = ({ brief }) => {
  const [selectedOption, setSelectedOption] = useState("location"); // Default to 'location' since your image shows that
  const [selectedLocation, setSelectedLocation] = useState("");
  const [selectedTime, setSelectedTime] = useState("Apr"); // Default to 'Apr' since your image shows that

  // Safely access properties with null checks
  const locations = brief && brief.time ? Object.keys(brief.time) : [];
  const time = brief && brief.location ? Object.keys(brief.location) : [];

  useEffect(() => {
    if (selectedOption === "time") {
      if (locations.length > 0) {
        setSelectedLocation(locations[0]);
      }
    } else {
      if (time.length > 0) {
        setSelectedTime(time[0]);
      }
    }
  }, [selectedOption, brief, locations, time]);

  // Check if brief and required properties exist
  if (!brief || !brief.time || !brief.location) {
    return <p>No data available.</p>;
  }

  const categories = [
    "Total Groundwater consumption* ( in KL)",
    "Total Tanker Water Consumption* (in KL)",
    "Total surface water consumption (this includes municipal supply water)* ( in KL)",
  ];

  let seriesData = [];
  let xAxisCategories = [];

  if (selectedOption === "time" && selectedLocation) {
    // When time is selected, and a location (l1, l2, etc.) is selected
    const selectedLocationData = brief.time[selectedLocation]; // Data for the selected location
    
    if (!selectedLocationData) {
      return <p>No data available for selected location.</p>;
    }

    // For time option, x-axis should show time periods
    xAxisCategories = Object.keys(brief.location);

    // Prepare series data for each category
    seriesData = categories.map((category) => {
      const dataValues = Array.isArray(selectedLocationData[category])
        ? selectedLocationData[category]
        : Array(xAxisCategories.length).fill(0);
        
      return {
        name: category,
        data: dataValues,
      };
    });
  } else if (selectedOption === "location" && selectedTime) {
    // When location is selected (and a time period is selected)
    const selectedTimeData = brief.location[selectedTime]; // Data for the selected time period
    
    if (!selectedTimeData) {
      return <p>No data available for selected time period.</p>;
    }

    // For location option, x-axis should show locations
    xAxisCategories = Object.keys(brief.time);

    // Ensure the data is properly aligned with locations
    seriesData = categories.map((category) => {
      if (!selectedTimeData[category]) {
        return {
          name: category,
          data: Array(xAxisCategories.length).fill(0),
        };
      }
      
      if (typeof selectedTimeData[category] === 'object' && !Array.isArray(selectedTimeData[category])) {
        const dataArray = xAxisCategories.map(loc => 
          selectedTimeData[category][loc] || 0
        );
        
        return {
          name: category,
          data: dataArray,
        };
      } else if (Array.isArray(selectedTimeData[category])) {
        const dataArray = [...selectedTimeData[category]];
        while (dataArray.length < xAxisCategories.length) {
          dataArray.push(0);
        }
        
        return {
          name: category,
          data: dataArray.slice(0, xAxisCategories.length),
        };
      } else {
        return {
          name: category,
          data: Array(xAxisCategories.length).fill(0),
        };
      }
    });
  }

  // Map colors to match your image
  const chartColors = ["#96D9D9", "#5630C6", "#8BAF9C"];
  
  // Map names to match your image legend
  const categoryDisplayNames = [
    "Total Groundwater consumption* ( in KL)",
    "Total Tanker Water Consumption* (in KL)",
    "Total surface water consumption (this includes municipal supply water)* ( in KL)",
  ];
  
  // Rename series to match display names
  seriesData = seriesData.map((series, index) => ({
    ...series,
    name: categoryDisplayNames[index]
  }));

  const chartOptions = {
    chart: {
      type: "bar",
      stacked: true,
      stackType: "normal",
      toolbar: {
        show: false
      }
    },
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: "30%",
        endingShape: "flat",
        dataLabels: {
          position: "center"
        }
      }
    },
    dataLabels: {
      enabled: true,
      style: {
        colors: ["#fff"],
        fontWeight: "bold"
      },
      formatter: function(val) {
        return val.toFixed(1);
      }
    },
    grid: {
      show: true,
      borderColor: "#e0e0e0",
      strokeDashArray: 5,
      position: 'back',
      xaxis: {
        lines: {
          show: true
        }
      },
      yaxis: {
        lines: {
          show: true
        }
      }
    },
    xaxis: {
      categories: xAxisCategories,
      position: "bottom", // This is key - move from 'top' to 'bottom'
      axisBorder: {
        show: true
      },
      axisTicks: {
        show: true
      },
      labels: {
        style: {
          fontWeight: "bold"
        }
      }
    },
    yaxis: {
      title: {
        text: "",
        style: {
          fontWeight: "bold"
        }
      },
      labels: {
        formatter: function(val) {
          return val.toFixed(0);
        }
      },
      min: 0 // Ensure chart starts at 0
    },
    fill: {
      opacity: 1
    },
    colors: chartColors,
    legend: {
      position: "bottom",
      horizontalAlign: "center",
      offsetY: 10,
      markers: {
        width: 12,
        height: 12,
        radius: 2
      },
      itemMargin: {
        horizontal: 10,
        vertical: 5
      }
    },
    tooltip: {
      enabled: true,
      shared: true,
      intersect: false
    }
  };

  return (
    <div className="container">
      <div style={{ marginBottom: "2%" }}>
        <label style={{ marginRight: '15px' }}>
          <input
            type="radio"
            value="time"
            checked={selectedOption === "time"}
            onChange={() => setSelectedOption("time")}
            style={{ marginRight: '5px' }}
          />
          Time
        </label>
        <label>
          <input
            type="radio"
            value="location"
            checked={selectedOption === "location"}
            onChange={() => setSelectedOption("location")}
            style={{ marginRight: '5px' }}
          />
          Location
        </label>
      </div>

      {selectedOption === "time" && (
        <div style={{ marginBottom: "2%" }}>
          {locations.map((location) => (
            <label key={location} style={{ marginRight: '15px' }}>
              <input
                type="radio"
                value={location}
                checked={selectedLocation === location}
                onChange={() => setSelectedLocation(location)}
                style={{ marginRight: '5px' }}
              />
              {location}
            </label>
          ))}
        </div>
      )}

      {selectedOption === "location" && (
        <div style={{ marginBottom: "2%" }}>
          {time.map((t) => (
            <label key={t} style={{ marginRight: '15px' }}>
              <input
                type="radio"
                value={t}
                checked={selectedTime === t}
                onChange={() => setSelectedTime(t)}
                style={{ marginRight: '5px' }}
              />
              {t}
            </label>
          ))}
        </div>
      )}

      {seriesData.length > 0 && xAxisCategories.length > 0 && (
        <div style={{ height: "400px" }}>
          <Chart
            options={chartOptions}
            series={seriesData}
            type="bar"
            height="100%"
          />
        </div>
      )}
    </div>
  );
};

export default WaterComparison;