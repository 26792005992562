import React, { useEffect, useRef, useState } from "react";
import { apiCall } from "../../_services/apiCall";
import config from "../../config/config.json";
import TotalWaterMultTimeMultTime from "./TotalWaterMultTimeMultTime";
import SeparateWaterMultTimeMultTime from "./SeparateWaterMultTimeMultTime";
import WaterHorizontalBar from "./FrameworkFourtyEight/WaterHorizontalBar";
// import RenewableAndNonRenewableMultiYear from "./RenewableAndNonRenewableMultiYear";
// import SeparateEnergyMultTimeMultTime from "./SeparateEnergyMultTimeMultTime";
// import TotalEnergyMultTimeMultTime from "./TotalEnergyMultTimeMultTime";

const MultipleYearMultipleTime = ({
  keyTab,
  locationOption,
  companyFramework,
  compareLastTimePeriods,
  compareTCurrentimePeriods,
  financialYear,
  timePeriodValues,
  timePeriods,
  brief,
}) => {
  const [graphData, setGraphData] = useState(null);
  const isMounted = useRef(true);

  useEffect(() => {
    return () => {
      isMounted.current = false;
    };
  }, []);

  const getCompareEnergyData = async () => {
    if (financialYear && financialYear.length >= 2) {
      try {
        const { isSuccess, data } = await apiCall(
          `${config.POSTLOGIN_API_URL_COMPANY}getCompareWaterData`,
          {},
          {
            financialYearIds: [
              financialYear[financialYear.length - 2].id,
              financialYear[financialYear.length - 1].id,
            ],
          },
          "GET"
        );

        if (isSuccess && isMounted.current) {
          setGraphData(data.data);
        }
      } catch (error) {
        console.error("Error fetching total training data:", error);
      }
    }
  };

  useEffect(() => {
    if (companyFramework && companyFramework.includes(1)) {
      getCompareEnergyData();
    }
  }, [financialYear]);

  const isCompareLastTimePeriodsValid =
    compareLastTimePeriods && Object.keys(compareLastTimePeriods).length > 0;
  const isCompareTCurrentimePeriodsValid =
    compareTCurrentimePeriods &&
    Object.keys(compareTCurrentimePeriods).length > 0;

  const shouldRenderGraphs =
    isCompareLastTimePeriodsValid && isCompareTCurrentimePeriodsValid;

  return companyFramework && companyFramework.includes(1) ? (
    <div className="d-flex flex-column flex-space-between">
      <div
        className="d-flex flex-row flex-space-between"
        style={{ height: "60vh", marginBottom: "3%" }}
      >
        <div
          className="firsthalfprogressenergy container"
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            width: "50%",
          }}
        >
          <TotalWaterMultTimeMultTime
            compareLastTimePeriods={compareLastTimePeriods}
            locationOption={locationOption}
            compareTCurrentimePeriods={compareTCurrentimePeriods}
            graphData={graphData}
            financialYear={financialYear}
          />
        </div>
        <div
          className="secondhalfprogress container"
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            width: "50%",
            marginLeft:"20px"
          }}
        >
          <SeparateWaterMultTimeMultTime
            compareLastTimePeriods={compareLastTimePeriods}
            locationOption={locationOption}
            compareTCurrentimePeriods={compareTCurrentimePeriods}
            graphData={graphData}
            financialYear={financialYear}
          />
        </div>
      </div>
    </div>
  ) : (
    // <div className="d-flex flex-column flex-space-between">
    //   {/* First Row */}
    //   <div
    //     className="d-flex flex-row flex-space-between"
    //     style={{ height: "60vh", marginBottom: "3%" }}
    //   >
    //     <div
    //       className="firsthalfprogressenergy"
    //       style={{
    //         display: "flex",
    //         flexDirection: "column",
    //         justifyContent: "space-between",
    //         width: "50%",
    //       }}
    //     >
    //       {shouldRenderGraphs && (
    //         <div style={{ height: "48%" }}>
    //           <TotalWaterMultTimeMultTime
    //             compareLastTimePeriods={compareLastTimePeriods}
    //             locationOption={locationOption}
    //             compareTCurrentimePeriods={compareTCurrentimePeriods}
    //             graphData={graphData}
    //             financialYear={financialYear}
    //           />
    //         </div>
    //       )}

    //       {/* <div style={{ height: "48%" }}>
    //         <SeparateWaterMultTimeMultTime
    //           compareLastTimePeriods={compareLastTimePeriods}
    //           locationOption={locationOption}
    //           compareTCurrentimePeriods={compareTCurrentimePeriods}
    //           graphData={graphData}
    //           financialYear={financialYear}
    //         />
    //       </div> */}
    //     </div>

    //     <div
    //       className="secondhalfprogress"
    //       style={{
    //         display: "flex",
    //         flexDirection: "column",
    //         justifyContent: "space-between",
    //         width: "50%",
    //       }}
    //     >
    //       <div style={{ height: "48%" }}>
    //       <SeparateWaterMultTimeMultTime
    //           compareLastTimePeriods={compareLastTimePeriods}
    //           locationOption={locationOption}
    //           compareTCurrentimePeriods={compareTCurrentimePeriods}
    //           graphData={graphData}
    //           financialYear={financialYear}
    //         />
    //       </div>
    //       {/* <div style={{ height: "48%" }}>
    //         <ProductWiseYearStacked
    //           compareLastTimePeriods={compareLastTimePeriods}
    //           locationOption={locationOption}
    //           compareTCurrentimePeriods={compareTCurrentimePeriods}
    //           graphData={graphData}
    //           financialYear={financialYear}
    //         />
    //       </div> */}
    //     </div>
    //   </div>
    // </div>
    <div className="d-flex flex-column flex-space-between">
      <div
        className="d-flex flex-row flex-space-between"
        style={{ marginBottom: "3%" }}
      >
        <div
          className="firsthalfprogressenergy"
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            width: "50%",
          }}
        >
          <div style={{ height: "100%" }}>
            <WaterHorizontalBar
              brief={brief}
              locationOption={locationOption}
              timePeriods={timePeriods}
              timePeriodValues={timePeriodValues}
            />
          </div>

          <div style={{ height: "0%" }}></div>
        </div>
      </div>
    </div>
  );
};

export default MultipleYearMultipleTime;
