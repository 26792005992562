// import React from "react";
// import BarComponentWater from "./FrameworkOne/BarComponentWater";
// import WaterBarFourtyEight from "./FrameworkFourtyEight/WaterBarFourtyEight";
// import TotalTreatedWater from "./FrameworkFourtyEight/TotalTreatedWater";
// import WaterRecyclePercentage from "./FrameworkFourtyEight/WaterRecyclePercentage";

// const WaterSingleLocSingleTime = ({
//   companyFramework,
//   brief,
//   matchedDataWater,
//   matchedWaterDis,
// }) => {
//   return companyFramework.includes(1) ? (
//     <div className="d-flex flex-column flex-space-between">
//       <div
//         className="d-flex flex-row flex-space-between"
//         style={{ height: "30vh", marginBottom: "3%" }}
//       >
//         <div
//           className="firsthalfprogressenergy"
//           style={{
//             display: "flex",
//             flexDirection: "column",
//             justifyContent: "space-between",
//             width: "50%",
//           }}
//         >
//           <div style={{ height: "100%" }}>
//             <BarComponentWater
//               matchedDataWater={matchedDataWater}
//               title={"Water Withdrawal"}
//             />
//           </div>
//         </div>
//         <div
//           className="secondhalfprogress"
//           style={{
//             display: "flex",
//             flexDirection: "column",
//             justifyContent: "space-between",
//             width: "50%",
//           }}
//         >
//           <div style={{ height: "100%" }}>
//             <BarComponentWater
//               matchedDataWater={matchedWaterDis}
//               title={"Water Discharged"}
//             />
//           </div>
//         </div>
//       </div>
//     </div>
//   ) : (
//     <>
//       <div className="d-flex flex-column flex-space-between">
//         <div
//           className="d-flex flex-row flex-space-between"
//           style={{ height: "52vh", marginBottom: "3%" }}
//         >
//           <div
//             className="firsthalfprogressenergy"
//             style={{
//               display: "flex",
//               flexDirection: "column",
//               justifyContent: "space-between",
//               width: "50%",
//             }}
//           >
//             <div style={{ height: "49%" }}>
//               <WaterBarFourtyEight brief={brief} />
//             </div>
//             <div style={{ height: "49%" }}>
//               <WaterRecyclePercentage brief={brief} />
//             </div>
//           </div>
//           <div
//             className="secondhalfprogress"
//             style={{
//               display: "flex",
//               flexDirection: "column",
//               justifyContent: "space-between",
//               width: "50%",
//             }}
//           >
//             <div style={{ height: "49%" }}>
//               <TotalTreatedWater brief={brief} />
//             </div>
//             <div style={{ height: "0%" }}></div>
//           </div>
//         </div>
//       </div>
//     </>
//   );
// };

// export default WaterSingleLocSingleTime;

import React from "react";
import VerticalBarComponentWater from "./FrameworkOne/VerticalBarComponentWater";
import VerticalBarWaterComponent from "./FrameworkFourtyEight/VerticalBarWaterComponent";
import ShowTotalValue from "./FrameworkFourtyEight/ShowTotalValue";

const WaterSingleLocSingleTime = ({
  companyFramework,
  brief,
  matchedDataWater,
  matchedWaterDis,
}) => {
  return (
    <div>
      {companyFramework.includes(1) ? (
        <div className="d-flex flex-column flex-space-between">
          <div
            className="d-flex flex-row flex-space-between"
            style={{ marginBottom: "3%" }}
          >
            <div
              className="firsthalfprogressenergy container"
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
                width: "50%",
              }}
            >
              <VerticalBarComponentWater
                matchedDataWater={matchedDataWater}
                title={"Water Withdrawal"}
              />
            </div>
            <div
              className="secondhalfprogress container"
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
                width: "50%",
                marginLeft:"20px"
              }}
            >
              <VerticalBarComponentWater
                matchedDataWater={matchedWaterDis}
                title={"Water Discharged"}
              />
            </div>
          </div>
        </div>
      ) : (
        <div className="d-flex flex-row flex-space-between" style={{ marginBottom: "2%" }}>
          {/* <div
            className="firsthalfprogressenergy"
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
              width: "50%",
              marginTop: "10px",
            }}
          >
            <ShowTotalValue brief={brief} type="ALL" />
          </div> */}
        
          <div
            className="firsthalfprogressenergy"
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
              width: "50%",
              marginTop: "10px",
            }}
          >
            <VerticalBarWaterComponent brief={brief} type="FUEL" />
          </div>
        </div>
      )}
    </div>
  );
};

export default WaterSingleLocSingleTime;
