import React, { useState, useEffect, useRef } from "react";
import "./teamworkload.css";
import "../ProgressBySector/sectorprogress.css";
import no from "../../img/no.png";

// Correctly destructure the prop
const TeamWorkLoadChecker = ({ orgCheckerOtatusData }) => {
  // Add container ref to measure actual width
  const chartContainerRef = useRef(null);
  const [containerWidth, setContainerWidth] = useState(0);
  
  // State for tooltip
  const [tooltip, setTooltip] = useState({
    visible: false,
    x: 0,
    y: 0,
    content: "",
    value: 0,
    percentage: 0
  });

  // Calculate the maximum value dynamically for scaling
  const calculateMaxValue = () => {
    if (!orgCheckerOtatusData || orgCheckerOtatusData.length === 0) return 100; // Default max value set to 100
    
    const maxFromData = Math.max(...orgCheckerOtatusData.map(item => item.value || 0));
    // Round up to nearest 20 for clean y-axis values
    return Math.ceil(maxFromData / 20) * 20;
  };

  // Helper function to get the bar color based on type
  const getBarColor = (type) => {
    switch(type) {
      case "pending": return "#4472C4"; // Blue for Pending Review
      case "approved": return "#4F8146"; // Green for Approved
      case "revision": return "#ED7D31"; // Orange for Sent for Revision
      case "rejected": return "#A5A5A5"; // Gray for Rejected
      default: return "#C3D4DB"; // Default light blue/gray
    }
  };

  // Get the label text for first line of multi-line labels
  const getBarLabelFirstLine = (type) => {
    switch (type) {
      case "pending": return "Pending";
      case "approved": return "Approved";
      case "revision": return "Sent";
      case "rejected": return "Rejected";
      default: return "";
    }
  };

  // Get the label text for second line of multi-line labels
  const getBarLabelSecondLine = (type) => {
    switch (type) {
      case "pending": return "Review";
      case "approved": return "";
      case "revision": return "for";
      default: return "";
    }
  };

  // Get the label text for third line of multi-line labels
  const getBarLabelThirdLine = (type) => {
    switch (type) {
      case "revision": return "Revision";
      default: return "";
    }
  };

  // Handler for mouse enter (show tooltip)
  const handleMouseEnter = (event, type, value, percentage) => {
    const rect = event.currentTarget.getBoundingClientRect();
    const fullLabel = `${getBarLabelFirstLine(type)} ${getBarLabelSecondLine(type)}`.trim();
    
    setTooltip({
      visible: true,
      x: rect.left + rect.width / 2,
      y: rect.top - 10,
      content: fullLabel,
      value,
      percentage
    });
  };

  // Handler for mouse leave (hide tooltip)
  const handleMouseLeave = () => {
    setTooltip({ ...tooltip, visible: false });
  };

  // Update container width on mount and resize
  useEffect(() => {
    const updateWidth = () => {
      if (chartContainerRef.current) {
        setContainerWidth(chartContainerRef.current.clientWidth);
      }
    };

    // Initial measurement
    updateWidth();
    
    // Add resize listener
    window.addEventListener('resize', updateWidth);
    
    // Clean up
    return () => window.removeEventListener('resize', updateWidth);
  }, []);

  // Render the chart with grid lines and y-axis
  const renderBarChart = () => {
    // Fixed chart dimensions
    const chartHeight = 500;
    const leftMargin = 70;
    const bottomMargin = 80;
    const topMargin = 70;
    const barWidth = 50;
    const barTypes = ["pending", "approved", "revision", "rejected"];
    
    // Use actual measured container width for responsive layout
    // Fall back to 680px if measurement not available yet
    const chartWidth = containerWidth || 680;
    
    // Auto-adjustable gap calculation based on actual container width
    const totalBars = barTypes.length;
    const availableWidth = chartWidth - leftMargin - (barWidth * totalBars);
    const gapBetweenBars = Math.max(20, availableWidth / (totalBars + 1)); // Minimum 20px gap
    
    // Dynamic yAxisMax based on data
    const yAxisMax = calculateMaxValue();
    
    // Create y-axis ticks with 20-unit intervals for cleaner appearance
    const yAxisTicks = [];
    for (let i = 0; i <= yAxisMax; i += 20) {
      yAxisTicks.push(i);
    }
    
    // Calculate scale factor to normalize bar heights
    const scaleFactor = (chartHeight - topMargin - bottomMargin) / yAxisMax;

    return (
      <div 
        className="chart-container" 
        style={{ 
          position: 'relative',
          height: `${chartHeight}px`,
          width: '100%',
          margin: '0 auto',
        }}
        ref={chartContainerRef}
      >
        {/* Chart Title */}
        <div style={{
          position: 'absolute',
          top: '10px',
          width: '100%',
          textAlign: 'center',
          fontSize: '20px',
          fontWeight: 'bold',
        }}>
          Checker (Review & Approval Role)
        </div>

        {/* Y-axis title */}
        <div style={{
          position: 'absolute',
          left: '10px',
          top: '50%',
          transform: 'rotate(-90deg) translateX(-50%)',
          transformOrigin: 'left center',
          fontSize: '14px',
          width: '120px',
          textAlign: 'center',
        }}>
          Number of Entries
        </div>

        {/* Y-axis grid lines and labels */}
        <div style={{
          position: 'absolute',
          left: leftMargin,
          top: topMargin,
          height: `${chartHeight - topMargin - bottomMargin}px`,
          width: `${chartWidth - leftMargin}px`,
        }}>
          {/* Grid lines */}
          {yAxisTicks.map((value) => (
            <div key={value} style={{
              position: 'absolute',
              left: '0',
              top: `${chartHeight - bottomMargin - (value * scaleFactor) - topMargin}px`,
              width: '100%',
              borderTop: '1px solid #ddd',
            }}>
              <span style={{
                position: 'absolute',
                left: '-45px',
                top: '-10px',
                fontSize: '14px',
                color: '#666',
                width: '40px',
                textAlign: 'right',
              }}>
                {value}
              </span>
            </div>
          ))}
        </div>

        {/* Bottom border (x-axis) */}
        <div style={{
          position: 'absolute',
          left: leftMargin,
          top: `${chartHeight - bottomMargin}px`,
          width: `${chartWidth - leftMargin}px`,
          borderTop: '1px solid #000',
        }}></div>

        {/* Bars */}
        <div style={{
          position: 'absolute',
          left: leftMargin,
          top: topMargin,
          height: `${chartHeight - topMargin - bottomMargin}px`,
          width: `${chartWidth - leftMargin}px`,
        }}>
          {barTypes.map((type, index) => {
            // Find actual data or use defaults
            let value = 0;
            let percentage = 0;
            
            if (type === "pending") {
              value = 9;
            } else if (type === "approved") {
              value = 99;
            } else if (type === "revision" || type === "rejected") {
              value = 0;
            }
            
            const barHeight = value * scaleFactor;
            // Calculate position with auto-adjustable gaps
            const leftPosition = gapBetweenBars * (index + 1) + (barWidth * index);
            
            return (
              <div 
                key={type} 
                style={{
                  position: 'absolute',
                  left: `${leftPosition}px`,
                  bottom: '0',
                  width: `${barWidth}px`,
                  height: `${Math.max(barHeight, 1)}px`, // Minimum height for visibility
                  backgroundColor: getBarColor(type),
                  cursor: 'pointer',
                  transition: 'left 0.3s ease', // Smooth transition when spacing changes
                }}
                onMouseEnter={(e) => handleMouseEnter(e, type, value, percentage)}
                onMouseLeave={handleMouseLeave}
              >
                {/* Value label on top of bar */}
                <div style={{
                  position: 'absolute',
                  top: '-25px',
                  width: '100%',
                  textAlign: 'center',
                  fontSize: '14px',
                  fontWeight: 'bold',
                }}>
                  {value}
                </div>
              </div>
            );
          })}
        </div>

        {/* X-axis labels - using multi-line labels */}
        <div style={{
          position: 'absolute',
          left: leftMargin,
          top: `${chartHeight - bottomMargin + 10}px`,
          width: `${chartWidth - leftMargin}px`,
        }}>
          {barTypes.map((type, index) => {
            // Calculate position with auto-adjustable gaps (same as bars)
            const labelPosition = gapBetweenBars * (index + 1) + (barWidth * index);
            
            return (
              <div key={type} style={{
                position: 'absolute',
                left: `${labelPosition}px`, 
                width: `${barWidth}px`,
                textAlign: 'center',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                transition: 'left 0.3s ease', // Smooth transition when spacing changes
              }}>
                <div style={{
                  fontSize: '14px',
                  fontWeight: '500',
                  lineHeight: '1.2',
                }}>
                  {getBarLabelFirstLine(type)}
                </div>
                {getBarLabelSecondLine(type) && (
                  <div style={{
                    fontSize: '14px',
                    fontWeight: '500',
                    lineHeight: '1.2',
                    marginTop: '2px',
                  }}>
                    {getBarLabelSecondLine(type)}
                  </div>
                )}
                {getBarLabelThirdLine(type) && (
                  <div style={{
                    fontSize: '14px',
                    fontWeight: '500',
                    lineHeight: '1.2',
                    marginTop: '2px',
                  }}>
                    {getBarLabelThirdLine(type)}
                  </div>
                )}
              </div>
            );
          })}
        </div>

        {/* Tooltip */}
        {tooltip.visible && (
          <div 
            style={{
              position: 'fixed',
              left: `${tooltip.x}px`,
              top: `${tooltip.y}px`,
              transform: 'translate(-50%, -100%)',
              backgroundColor: 'rgba(0, 0, 0, 0.8)',
              color: 'white',
              padding: '8px 12px',
              borderRadius: '4px',
              fontSize: '14px',
              pointerEvents: 'none',
              zIndex: 1000,
              boxShadow: '0 2px 5px rgba(0, 0, 0, 0.2)',
            }}
          >
            <div style={{ fontWeight: 'bold' }}>{tooltip.content}</div>
            <div>Value: {tooltip.value}</div>
            <div>Percentage: {tooltip.percentage}%</div>
          </div>
        )}
      </div>
    );
  };

  return (
    <div
      style={{
        width: "100%",
        height: "100%",
        background: "white",
        borderRadius: "10px",
        padding: "10px",
        display: "flex",
        flexDirection: "column",
        justifyContent: "flex-start",
        alignItems: "center",
      }}
    >
      <div style={{ 
        width: '100%',
        height: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'flex-start',
      }}>
        {renderBarChart()}
      </div>
    </div>
  );
};

export default TeamWorkLoadChecker;