import React, { useEffect, useState, useRef } from "react";
import { Button, Form, FormControl, InputGroup } from "react-bootstrap";
import { FaSearch } from "react-icons/fa";
import { useLocation } from "react-router-dom";
import { apiCall } from "../../../_services/apiCall";
import config from "../../../config/config.json";
import AssignQuestionModal from "./AssignQuestionModal";
import ReassignQuestionModal from "./ReassignQuestionModal";
import "./twobutton.css";
import ExcelUploader from "./ExcelUploader";

const TwoButtonComponent = ({
  onFilteredData,
  handleAssignedDetails,
  questionIds,
  financeObjct,
  menu,
  managementListValue,
  moduleName,
  moduleData,
  setSelectedFinancialYearId,
  setSelectedFinancialYearValue,
}) => {
  const data = "bottom";
  const [showAssignModal, setShowAssignModal] = useState(false);
  const [showReassignModal, setShowReassignModal] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [filteredData, setFilteredData] = useState(moduleData);
  const location = useLocation();
  const [financialYear, setFinancialYear] = useState([]);
  const [lastFinancialYear, setLastFinancialYear] = useState(null);

  const handleSearchChange = (event) => {
    const value = event.target.value;
    setSearchTerm(value);
    filterData(value);
  };

  useEffect(() => {
    setSearchTerm("");
  }, [location]);

  const isMounted = useRef(true);

  useEffect(() => {
    return () => {
      isMounted.current = false;
    };
  }, []);



  const getFinancialYear = async () => {
    const { isSuccess, data } = await apiCall(
      `${config.POSTLOGIN_API_URL_COMPANY}getFinancialYear`,
      {},
      {}
    );
    if (isSuccess && isMounted.current) {
      const fy = data.data[data.data.length - 1].financial_year_value;
      setSelectedFinancialYearValue(fy);
      setSelectedFinancialYearId(data.data[data.data.length - 1].id);
      setLastFinancialYear(data.data[data.data.length - 1].id);
      setFinancialYear(data.data.reverse());
    }
  };

  useEffect(() => {
    if (menu != "audit") getFinancialYear();
  }, []);
  const filterData = (term) => {
    if (!term) {
      setFilteredData(moduleData);
      onFilteredData(moduleData); // Passing the entire data if no search term
      return;
    }

    const filtered = moduleData.filter((item) =>
      item.title.toLowerCase().includes(term.toLowerCase())
    );
    setFilteredData(filtered);
    onFilteredData(filtered); // Passing the filtered data
  };

  useEffect(() => {
    if (menu !== "audit") {
      filterData(searchTerm);
    }
  }, [moduleData]);

  const handleAssignClick = () => setShowAssignModal(true);
  const handleAssignClose = () => setShowAssignModal(false);
  const handleReassignClose = () => setShowReassignModal(false);
  return (
    <div
      className="d-flex justify-content-start w-100 bg-white"
      style={{
        paddingLeft: "3%",
        // height: "14vh",
        padding: "2%",
        borderRadius: "10px",
      }}
    >
      {menu !== "audit" && (
        <div className="conta">
          <div className="w-100" style={{ paddingTop: "2%" }}>
            <Button
              variant="primary"
              className=" mr-2 cbn"
              style={{
                backgroundColor: "#3F88A5",
                // marginBottom: "10%",
                color: "white",
                paddingRight: "10%",
                paddingLeft: "10%",
              }}
              onClick={handleAssignClick}
            >
              Assign
            </Button>
          </div>
        </div>
      )}

      {menu === "audit" ? (
        <></>
      ) : (
        // <div style={{ display: "flex", alignItems: "center", gap: "20px" }}>
        <div>
          <Form.Label className="m-0 p-0">Financial Year</Form.Label>
          <Form.Select
            aria-label="Default select example"
            style={{ width: "10vw" }}
            onChange={(e) => {
              const selectedOption = financialYear.find(
                (yeardata) => yeardata.id === parseInt(e.target.value)
              );
              if (selectedOption) {
                setSelectedFinancialYearId(selectedOption.id);
                setSelectedFinancialYearValue(selectedOption.financial_year_value);
              }
            }}
          >
            {financialYear.map((yeardata) => (
              <option key={yeardata.id} value={yeardata.id}>
                {yeardata.financial_year_value}
              </option>
            ))}
          </Form.Select>
        </div>
        
       
   
      
      )}
     
      <div
        className="search-bar-wrapper"
        style={{
          marginLeft: menu === "audit" ? "0%" : "11%",
          marginRight: menu === "audit" ? "0%" : "3%",

          width: menu === "audit" ? "80%" : "50%",
        }}
      >
        <InputGroup className="search-bar h-100">
          <InputGroup.Text id="basic-addon1">
            <FaSearch />
          </InputGroup.Text>
          <FormControl
            placeholder="Search Questions"
            aria-label="Search"
            value={searchTerm}
            onChange={handleSearchChange}
            aria-describedby="basic-addon1"
          />
        </InputGroup>
      </div>

      <AssignQuestionModal
        handleAssignedDetails={handleAssignedDetails}
        questionIds={questionIds}
        financeObjct={financeObjct}
        managementListValue={managementListValue}
        data={data}
        moduleName={moduleName}
        moduleData={moduleData}
        showAssignModal={showAssignModal}
        handleAssignClose={handleAssignClose}
      />
      <ReassignQuestionModal
        handleAssignedDetails={handleAssignedDetails}
        moduleData={moduleData}
        questionIds={questionIds}
        financeObjct={financeObjct}
        managementListValue={managementListValue}
        moduleName={moduleName}
        showReassignModal={showReassignModal}
        handleReassignClose={handleReassignClose}
      />
       <div>
       {menu === "audit" ? (
        <></>
      ) :      <ExcelUploader />
}

      </div>
      {/* {menu === "audit" && (
        <div className="conta">
          <div className="w-100" style={{ paddingTop: "2%" }}>
            <Button
              variant="primary"
              className=" mr-2 cbn"
              style={{
                backgroundColor: "#3F88A5",
                // marginBottom: "10%",
                color: "white",
                paddingRight: "10%",
                paddingLeft: "10%",
              }}
              onClick={acceptAllQuestion}
            >
              Accept All
            </Button>
          </div>
        </div>
      )} */}
    </div>
  );
};

export default TwoButtonComponent;
