import React, { useState, useMemo, useEffect } from "react";
import Select, { components } from "react-select";
import no from "../../../img/no.png";

const TotalWasteGeneratedByVertical = ({
  title,
  com,
  matchedDataWaste,
}) => {
  const colors = [  "#E6594D", // Petrol (Red)
    "#3F822B", // CNG (Green)
    "#1212F1", // LPG (Blue)
    "#EEC27F", // PNG (Orange)
    "#A14D49", // Briquette (Brown)
    "#791E80"  // Total (Purple)
    ]; // Define colors for each category
  const totalBarColor = "#791E80"; // Color for the total bar

  const [selectedProducts, setSelectedProducts] = useState([]);
  const [aggregatedData, setAggregatedData] = useState({});
  const [totalSum, setTotalSum] = useState(0);
  const [maxValue, setMaxValue] = useState(0);

  const recoverySeries = useMemo(() => {
    if (matchedDataWaste) {
      if (com === "non") {
        return (
          matchedDataWaste[0]?.question_details
            .filter(
              (detail) =>
                detail.option_type === "column1" ||
                detail.option_type === "column"
            )
            .map((detail) => detail.option)
            .reverse()
        );
      } else {
        return (
          matchedDataWaste[0]?.question_details
            .filter(
              (detail) =>
                detail.option_type === "column1" ||
                detail.option_type === "column"
            )
            .map((detail) => detail.option)
            .reverse()
        );
      }
    }
  }, [matchedDataWaste]);

  const productOptions = useMemo(() => {
    if (recoverySeries) {
      return recoverySeries.map((product, index) => ({
        value: product,
        label: product,
        color: colors[index % colors.length], // Assign a color to each product
      }));
    }
  }, [recoverySeries]);

  // Set selectedProducts to the last 5 options
  useEffect(() => {
    if (productOptions) {
      if (productOptions.length > 0) {
        const lastFiveProducts = productOptions.slice(-5); // Slice the last 5 items
        setSelectedProducts(lastFiveProducts);
      }
    }
  }, [productOptions]);

  const CustomClearIndicator = () => null;

  useEffect(() => {
    if (selectedProducts.length > 0) {
      const aggregated = selectedProducts.reduce((acc, product) => {
        acc[product.value] = 0; // Initialize each product value to 0
        return acc;
      }, {});

      const dataSource = com === "non" ? matchedDataWaste : matchedDataWaste;

      dataSource.forEach((item) => {
        const answers =
          item.answer && Array.isArray(item.answer) ? item.answer : [];

        answers[0].forEach((answerArray, index) => {
          const recoveryType = recoverySeries[index];
          const value = answerArray; // Value corresponds to recoverySeries value

          // Apply conditions to check and parse value
          const numericValue =
            value === "NA" ||
            value === "No" ||
            value === "Yes" ||
            value === "" ||
            value === "KWH" ||
            !value
              ? 0
              : parseFloat(value);

          // Only aggregate if the recoveryType is in selectedProducts
          if (
            selectedProducts.some((product) => product.label === recoveryType)
          ) {
            aggregated[recoveryType] += numericValue;
          }
        });
      });

      const sum = Object.values(aggregated).reduce(
        (total, value) => total + value,
        0
      );

      const adjustAndRoundTotalSum = (totalSum) => {
        // Define the thresholds or rounding steps
        const thresholds = [
          10, 25, 50, 100, 200, 500, 1000, 2000, 5000, 10000, 20000,
        ];

        // Handle values less than 1
        if (totalSum < 1) {
          if (totalSum < 0.1) {
            return Math.ceil(totalSum * 100) / 100; // Round to nearest 0.01
          } else {
            return Math.ceil(totalSum * 2) / 2; // Round to nearest 0.5
          }
        }

        // For values greater than or equal to 1, round based on the defined thresholds
        for (let i = thresholds.length - 1; i >= 0; i--) {
          if (totalSum > thresholds[i]) {
            return Math.ceil(totalSum / thresholds[i]) * thresholds[i]; // Round up to the next threshold
          }
        }

        // If no threshold is applicable, return the value as is
        return totalSum;
      };

      const adjustedTotal = adjustAndRoundTotalSum(sum);
      
      // Find the maximum value for proper scaling
      const maxItemValue = Math.max(...Object.values(aggregated), 0);
      const chartMaxValue = Math.max(adjustedTotal, maxItemValue);
      
      setAggregatedData(aggregated);
      setTotalSum(adjustedTotal);
      setMaxValue(chartMaxValue);
    }
  }, [selectedProducts, com, matchedDataWaste, recoverySeries]);

  const formatValue = (value) => {
    if (value >= 1e6) {
      return `${(value / 1e6).toFixed(1)}M`; // Format millions
    } else if (value >= 1e3) {
      return `${(value / 1e3).toFixed(1)}K`; // Format thousands
    } else {
      return value.toFixed(1); // Format normal numbers with 1 decimal
    }
  };

  const handleProductChange = (selectedOptions) => {
    if (selectedOptions.length > 5) {
      alert("You can only select up to 5 products.");
      return;
    }

    if (selectedOptions.length < 1) {
      alert("You have to select at least 1 option");
      return;
    }
    setSelectedProducts(selectedOptions || []);
  };

  const CustomOption = (props) => {
    const { isSelected, data } = props;

    return (
      <components.Option {...props}>
        <div style={{ display: "flex", alignItems: "center" }}>
          {/* Square Box */}
          <div
            style={{
              width: "20px",
              height: "20px",
              border: "2px solid #3f88a5",
              borderRadius: "2px",
              backgroundColor: isSelected ? "transparent" : "transparent",
              marginRight: "10px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            {/* Tick mark when selected */}
            {isSelected && (
              <span style={{ color: "white", fontSize: "14px" }}>✔</span>
            )}
          </div>
          {/* Option Label */}
          <span style={{ fontSize: "14px", fontWeight: 300 }}>
            {data.label}
          </span>
        </div>
      </components.Option>
    );
  };

  const CustomMultiValue = () => null;

  const CustomControl = (props) => {
    const { selectProps } = props;
    const { value, placeholder } = selectProps;

    return (
      <components.Control {...props}>
        {/* Placeholder or selected value */}
        {(!value || value.length === 0) && (
          <div
            style={{
              color: "#3f88a5",
              fontWeight: 600,
              fontSize: "13px",
              position: "absolute",
              left: "5px",
              pointerEvents: "none",
            }}
          >
            {/* {placeholder} */}
          </div>
        )}
        {/* Display only the first selected product */}
        {value && value.length > 0 && (
          <div style={{ color: "#3f88a5", marginLeft: "5px" }}>
            {value[0].label}
          </div>
        )}

        {/* Ensure you still render the child components like the dropdown indicator and input */}
        {props.children}
      </components.Control>
    );
  };

  if (!matchedDataWaste || matchedDataWaste.length === 0) {
    return (
      <div className="container">
        <img
          src={no}
          alt="No Data Available"
          style={{
            width: "150px",
            height: "125px",
            display: "block",
            margin: "0 auto",
          }}
        />
      </div>
    );
  }

  return (
    <div className="renewable-bar-container" style={{ height: "100%" }}>
      <div
        className="renewable-bar-header"
        style={{
          height: "10%",
          width: "100%",
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <div style={{ width: "40%" }}>{title}</div>
        <div style={{ width: "50%" }}>
          <Select
            options={productOptions}
            onChange={handleProductChange}
            isMulti
            value={productOptions.filter((option) =>
              selectedProducts.some(
                (selectedProduct) => selectedProduct.value === option.value
              )
            )}
            placeholder={`Select products`}
            hideSelectedOptions={false}
            className=""
            components={{
              Option: CustomOption,
              Control: CustomControl,
              MultiValue: CustomMultiValue,
              ClearIndicator: CustomClearIndicator,
            }}
            closeMenuOnSelect={false}
            styles={{
              control: (base) => ({
                ...base,
                border: "2px solid #3f88a5",
                borderRadius: "10px",
              }),
              menu: (base) => ({
                ...base,
                zIndex: 100,
                border: "2px solid #3f88a5",
                borderRadius: "10px",
              }),
              dropdownIndicator: (base) => ({
                ...base,
                color: "#3f88a5",
                padding: "0 10px",
                fontSize: "20px",
                minHeight: "20px",
                minWidth: "20px",
              }),
              placeholder: (base) => ({
                ...base,
                position: "absolute",
                top: "50%",
                transform: "translateY(-50%)",
                pointerEvents: "none",
              }),
              multiValue: (base) => ({
                ...base,
                background: "transparent",
                border: "2px solid #3f88a5",
                borderRadius: "10px",
                marginTop: "0.5em",
              }),
              option: (provided, state) => ({
                ...provided,
                backgroundColor: state.isSelected
                  ? "transparent"
                  : state.isFocused
                  ? "white"
                  : "white",
                color: state.isSelected ? "black" : "black",
                cursor: "pointer",
              }),
            }}
          />
        </div>
      </div>

      {selectedProducts && selectedProducts.length > 0 && (
        <>
          {/* Vertical Chart Container */}
          <div style={{ 
            position: "relative", 
            height: "300px", 
            marginTop: "20px",
            marginBottom: "60px"
          }}>
            {/* Y-axis */}
            <div style={{ 
              position: "absolute",
              left: 0,
              top: 0,
              bottom: 0,
              width: "50px",
              borderRight: "1px solid #ddd"
            }}>
              {[0, 0.2, 0.4, 0.6, 0.8, 1].map((ratio, i) => (
                <div key={i} style={{
                  display: "flex",
                  justifyContent: "flex-end",
                  paddingRight: "8px",
                  fontSize: "11px",
                  fontWeight: 600,
                  position: "absolute",
                  bottom: `${ratio * 100}%`,
                  transform: "translateY(50%)",
                  right: 0,
                  left: 0
                }}>
                  {formatValue(maxValue * ratio)}
                </div>
              ))}
            </div>

            {/* Grid lines */}
            <div style={{ 
              position: "absolute",
              left: "50px",
              right: 0,
              top: 0,
              bottom: 0
            }}>
              {[0, 0.2, 0.4, 0.6, 0.8, 1].map((ratio, i) => (
                <div key={i} style={{
                  width: "100%",
                  borderTop: "1px dashed #ddd",
                  height: "1px",
                  position: "absolute",
                  bottom: `${ratio * 100}%`,
                  left: 0
                }}></div>
              ))}
            </div>

            {/* Bars container */}
            <div style={{ 
              position: "absolute",
              left: "50px",
              right: 0,
              top: 0,
              bottom: 0,
              paddingBottom: "0px",
              borderBottom: "1px solid #ddd"
            }}>
              <div style={{ 
                display: "flex",
                height: "100%",
                width: "100%",
                alignItems: "flex-end",
                justifyContent: "space-around"
              }}>
                {/* Individual bars */}
                {selectedProducts
                  .map((product) => ({
                    recoveryType: product.value,
                    value: aggregatedData[product.value] || 0,
                    color: product.color,
                  }))
                  .map(({ recoveryType, value, color }, index) => {
                    const heightPercent = maxValue > 0 ? (value / maxValue) * 100 : 0;
                    
                    const displayRecoveryType =
                      recoveryType === "Energy Consumption through other sources"
                        ? "Other Source"
                        : recoveryType === "Construction and demolition"
                        ? "C&D"
                        : recoveryType === "Other disposal operations"
                        ? "Other"
                        : recoveryType;
                    
                    return (
                      <div key={index} style={{ 
                        display: "flex", 
                        flexDirection: "column",
                        alignItems: "center",
                        width: `${80 / (selectedProducts.length + 1)}%`,
                        height: "100%",
                        position: "relative"
                      }}>
                        {/* Bar */}
                        <div style={{ 
                          position: "absolute",
                          bottom: 0,
                          width: "70%",
                          height: `${heightPercent}%`,
                          backgroundColor: color,
                          minHeight: value > 0 ? "2px" : "0px",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "flex-start",
                          paddingTop: "5px"
                        }}>
                          {/* Value label inside/above bar */}
                          {value > 0 && (
                            <span style={{
                              position: heightPercent < 15 ? "absolute" : "static",
                              top: heightPercent < 15 ? "-20px" : "auto",
                              color: heightPercent < 15 ? "#000" : "#fff",
                              fontSize: "11px",
                              fontWeight: "bold",
                              whiteSpace: "nowrap"
                            }}>
                              {formatValue(value)}
                            </span>
                          )}
                        </div>
                        
                        {/* X-axis label */}
                        <div style={{ 
                          position: "absolute",
                          bottom: "-40px",
                          textAlign: "center",
                          fontSize: "11px",
                          width: "100%",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          whiteSpace: "nowrap"
                        }}>
                          {displayRecoveryType}
                        </div>
                      </div>
                    );
                  })}
                
                {/* Total bar */}
                <div style={{ 
                  display: "flex", 
                  flexDirection: "column",
                  alignItems: "center",
                  width: `${80 / (selectedProducts.length + 1)}%`,
                  height: "100%",
                  position: "relative"
                }}>
                  <div style={{ 
                    position: "absolute",
                    bottom: 0,
                    width: "70%",
                    height: `${maxValue > 0 ? (totalSum / maxValue) * 100 : 0}%`,
                    backgroundColor: totalBarColor,
                    minHeight: totalSum > 0 ? "2px" : "0px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "flex-start",
                    paddingTop: "5px"
                  }}>
                    {totalSum > 0 && (
                      <span style={{
                        position: (totalSum / maxValue) * 100 < 15 ? "absolute" : "static",
                        top: (totalSum / maxValue) * 100 < 15 ? "-20px" : "auto",
                        color: (totalSum / maxValue) * 100 < 15 ? "#000" : "#fff",
                        fontSize: "11px",
                        fontWeight: "bold",
                        whiteSpace: "nowrap"
                      }}>
                        {formatValue(totalSum)}
                      </span>
                    )}
                  </div>
                  
                  {/* X-axis label */}
                  <div style={{ 
                    position: "absolute",
                    bottom: "-40px",
                    textAlign: "center",
                    fontSize: "11px",
                    fontWeight: "bold",
                    width: "100%"
                  }}>
                    Total
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div
            className="unit"
            style={{
              width: "100%",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              height: "5%",
              marginTop: "20px"
            }}
          >
            <div
              style={{
                fontSize: "12px",
                fontWeight: 400,
                height: "100%",
                padding: "10px",
              }}
            >
              (in MT)
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default TotalWasteGeneratedByVertical;